import { BoardStateChange } from 'vortex';
import { Position } from 'vortex';
import { AffectAura } from '../animations/affect-aura';
import { Announcement } from '../animations/announcement';
import { TriggerAura } from '../animations/trigger-aura';
import { ANNOUNCEMENT_DURATION, BOARD_RECT, DEFAULT_INCREMENTAL_DELAY, DEFAULT_STATE_CHANGE_DURATION } from '../constants/graphics-constants';
import { toArray } from '../utils';

export class PlayerStateChange {
    constructor(player) {
        this._player = player;
        this._boardStateChange = new BoardStateChange(player.board);
        this._isEmpty = true;

        this.waitDuration = 0;
        this.source = null;
        this.playedCard = null;
        this.playedCardTargets = [];
        this.recycledCard = null;
        this.stashedCards = [];
        this.selectedCommonCard = null;
        this.drawnCardCount = 0;
        this.drawnCards = [];
        this.givenCards = [];
        this.discardedCards = [];
        this.replacedCards = new Map();
        this.previewUpdated = false;
        this.goldChange = 0;
        this.showGoldEffect = false;
        this.summonedMinions = [];
        this.leftSummon = false;
        this.killedMinions = [];
        this.newCommonCards = null;
        this.addedCommonCards = [];
        this.sacrificedMinions = [];
        this.createdEffects = [];
        this.consumedEffects = [];
        this.damagesDealt = 0;
        this.healthRestored = 0;
        this.minionDamages = new Map();
        this.minionPowerChanges = new Map();
        this.minionPowerSets = new Map();
        this.minionCallbacks = new Map();
        this.newMinionOrder = null;
        this.nextGameStep = null;
        this.combatStart = false;
        this.combatEnd = false;
        this.turnStart = false;
        this.deckShuffled = true;
        this.newActiveCharacter = null;
        this.recruitedCharacters = [];
        this.destroyedCharacters = [];
        this.removedCharacters = [];
        this.newIncome = null;
        this.delay = 0;
        this.callbacks = [];
        
        this.canceled = false;
        this.isPlayerAction = false;
        this.isCombat = false;
        this._changeDuration = DEFAULT_STATE_CHANGE_DURATION;
        this._incrementalDelay = DEFAULT_INCREMENTAL_DELAY;
        this._next = null;
    }

    get player() {
        return this._player;
    }

    _markNotEmpty() {
        this._isEmpty = false;
        return this;
    }

    // getChain() {
    //     let result = [];
    //     let current = this;

    //     while (current) {
    //         result.push(current);
    //         current = current._next;
    //     }

    //     return result;
    // }

    cancel() {
        this.canceled = true;

        return this;
    }

    wait(duration) {
        this.waitDuration = duration;

        return this._markNotEmpty();
    }

    setDuration(duration) {
        this._changeDuration = duration;

        return this;
    }

    makeInstant() {
        this._changeDuration = 0;
        this._incrementalDelay = 0;

        return this;
    }

    setDelay(delay) {
        this.delay = delay;

        return this._markNotEmpty();
    }

    do(callback) {
        this.callbacks.push(callback);
        
        return this._markNotEmpty();
    }

    markAsPlayerAction() {
        this.isPlayerAction = true;

        return this;
    }

    markAsCombat() {
        this.isCombat = true;

        return this;
    }

    setActiveCharacter(character) {
        this.newActiveCharacter = character;

        return this._markNotEmpty();
    }

    recruitCharacter(character) {
        this.recruitedCharacters.push(character);

        return this._markNotEmpty();
    }

    destroyCharacter(character) {
        this.destroyedCharacters.push(character);

        return this._markNotEmpty();
    }

    removeCharacter(character) {
        this.removedCharacters.push(character);

        return this._markNotEmpty();
    }

    setSource(source) {
        this.source = source;

        return this._markNotEmpty();
    }

    updatePreview() {
        this.previewUpdated = true;

        return this._markNotEmpty();
    }

    addPanel(panel, rect) {
        this._boardStateChange.spawn(panel, this._player, Position.absolute(rect));

        return this._markNotEmpty();
    }

    spawn(entity, container, index = null) {
        for (let e of toArray(entity)) {
            this._boardStateChange.spawn(e, this._player);

            if (container) {
                this._boardStateChange.move(e, container, index)
            }
        }

        return this._markNotEmpty();
    }

    playCard(card, targets) {
        this.playedCard = card;
        this.playedCardTargets = targets;

        return this._markNotEmpty();
    }

    recycleCard(card, selectedCard) {
        this.recycledCard = card;
        this.selectedCommonCard = selectedCard;

        return this._markNotEmpty();
    }

    stashCard(card) {
        for (let c of toArray(card)) {
            this.stashedCards.push(c);
        }

        return this._markNotEmpty();
    }

    giveCard(card, character = null) {
        for (let c of toArray(card)) {
            this.givenCards.push([c, character]);
        }

        return this._markNotEmpty();
    }

    discard(card) {
        for (let c of toArray(card)) {
            this.discardedCards.push(c);
        }

        return this._markNotEmpty();
    }

    replaceCard(oldCard, newCard) {
        this.replacedCards.set(oldCard, newCard);

        return this._markNotEmpty();
    }

    draw(count = 1) {
        this.drawnCardCount += count;
        this.previewUpdated = true;

        return this._markNotEmpty();
    }

    summon(minion) {
        for (let m of toArray(minion)) {
            this.summonedMinions.push(m);
        }

        return this._markNotEmpty();
    }

    summonOnLeft(value) {
        this.leftSummon = value;

        return this;
    }

    sacrifice(minion) {
        for (let m of toArray(minion)) {
            this.sacrificedMinions.push(m);
        }
        
        return this._markNotEmpty();
    }

    createEffect(effect) {
        for (let e of toArray(effect)) {
            this.createdEffects.push(e);
        }

        return this._markNotEmpty();
    }

    consumeEffect(effect) {
        for (let e of toArray(effect)) {
            this.consumedEffects.push(e);
        }

        return this._markNotEmpty();
    }

    increasePower(minion, value) {
        for (let m of toArray(minion)) {
            this.minionPowerChanges.processValue(m, 0, modifier => modifier + value);
        }

        return this._markNotEmpty();
    }

    decreasePower(minion, value) {
        for (let m of toArray(minion)) {
            this.minionPowerChanges.processValue(m, 0, modifier => modifier - value);
        }

        return this._markNotEmpty();
    }

    setPower(minion, value) {
        for (let m of toArray(minion)) {
            this.minionPowerSets.set(m, value);
        }

        return this._markNotEmpty();
    }

    affectMinion(minion, callback, text) {
        for (let m of toArray(minion)) {
            this.minionCallbacks.set(m, { callback, text });
        }

        return this._markNotEmpty();
    }

    setMinionsOrder(minions) {
        this.newMinionOrder = minions;

        return this._markNotEmpty();
    }

    // shuffleDeck() {
    //     this.deckShuffled = true;
    //     this.previewUpdated = true;

    //     return this._markNotEmpty();
    // }

    setCommonCards(cards) {
        this.newCommonCards = cards;

        return this._markNotEmpty();
    }

    addCommonCards(cards) {
        for (let card of toArray(cards)) {
            this.addedCommonCards.push(card);
        }

        return this._markNotEmpty();
    }

    setGameStep(gameStep) {
        this.nextGameStep = gameStep;

        return this._markNotEmpty();
    }

    restoreHealth(amount) {
        this.healthRestored += amount;

        return this._markNotEmpty();
    }

    dealDamages(damages) {
        this.damagesDealt += damages;

        return this._markNotEmpty();
    }

    dealMinionDamages(minion, damages) {
        this.minionDamages.processValue(minion, 0, value => value + damages);

        return this._markNotEmpty();
    }

    giveGold(amount, showGoldEffect = false) {
        this.goldChange += amount;
        this.showGoldEffect = showGoldEffect;

        return this._markNotEmpty();
    }

    takeGold(amount, showGoldEffect = false) {
        this.goldChange -= amount;
        this.showGoldEffect = showGoldEffect;

        return this._markNotEmpty();
    }

    _shouldTrigger() {
        return !this._isEmpty && !this._player.isFinished();
    }

    resolve() {
        let change = this._boardStateChange;

        if (this.waitDuration > 0) {
            change.then(this.waitDuration);
        }

        if (this.nextGameStep) {
            let someDeaths = false;

            for (let entity of this._player.board.entities) {
                if (entity.destroyed) {
                    someDeaths = true;
                    change.despawn(entity);
                }
            }

            if (someDeaths) {
                change.then();
            }
        }

        if (this.delay) {
            change.then(this.delay)
        }

        if (this.nextGameStep === 'combat') {
            this.combatStart = true;
        } else if (this.nextGameStep === 'end-combat') {
            this.combatEnd = true;
        }

        if (this.source && !this.source.destroyed) {
            change
                .addAnimation(TriggerAura, this.source)
                .then()
        }

        if (this.nextGameStep === 'player-actions') {
            this._player.hasFinishedTurn = false;
            this.turnStart = true;

            if (!this._player.isOpponent) {
                this._player.history.pushTurn();
            }
        }

        if (this.nextGameStep) {
            if (!this._player.isOpponent) {
                let text = null;
                let kind = 'neutral';
                let delay = 0.15;
                let afterDelay = 0.3;

                if (this.nextGameStep === 'player-actions') {
                    text = 'Your turn';
                } else if (this.nextGameStep === 'opponent-actions') {
                    text = 'Opponent turn';
                    delay = 0.8;
                } else if (this.nextGameStep === 'combat') {
                    text = 'Combat';
                    delay = 0.8;
                } else if (this.nextGameStep === 'victory') {
                    text = 'Victory!';
                    kind = 'positive';
                } else if (this.nextGameStep === 'defeat') {
                    text = 'Defeat.';
                    kind = 'negative';
                } else if (this.nextGameStep === 'draw') {
                    text = 'Draw!';
                }

                if (text) {
                    let animation = new Announcement({ text, kind });

                    change
                        .then(delay)
                        .do(() => this._player.gameStep = this.nextGameStep)
                        .addAnimation(animation)
                        .then(afterDelay)
                } else {
                    this._player.gameStep = this.nextGameStep;
                }
            } else {
                this._player.gameStep = this.nextGameStep;
            }
        }

        if (this.removedCharacters.length) {
            for (let character of this.removedCharacters) {
                change
                    .scale(character, 1, 0)
                    .despawn(character)
                    .despawn(character.hand);

                for (let card of character.hand.cards()) {
                    change.despawn(card);
                }

                if (this._player.activeCharacter === character) {
                    this._player.activeCharacter = null;
                }
            }
            
            change
                .setDuration(this._changeDuration)
                .then()
        }

        if (this.newActiveCharacter) {
            this._player.availableCharacters.remove(this.newActiveCharacter);

            change
                .move(this.newActiveCharacter, this._player.activeCharacterZone)
                .do(() => this._player.activeCharacter = this.newActiveCharacter);

            if (this._player.activeCharacter) {
                change
                    .move(this._player.activeCharacter, this._player.backline)
            }

            change
                .setDuration(this._changeDuration)
                .then()
                .move(this.newActiveCharacter.hand, this._player.handRect)
                .move(this.newActiveCharacter.upgradeList, this._player.upgradeListRect)

            if (this._player.activeCharacter) {
                change
                    .move(this._player.activeCharacter.hand, null)
                    .move(this._player.activeCharacter.upgradeList, null)
            }
        }

        if (this.recruitedCharacters.length > 0) {
            for (let character of this.recruitedCharacters) {
                if (this._player.availableCharacters.remove(character)) {
                    this._player.recruitedCharacterCount += 1;
                }

                change
                    .move(character, this._player.backline)
                    .scale(character, 0, 1);
                
                for (let card of character.cards) {
                    let copy = card.makePlainCopy();

                    change
                        .spawn(copy, this._player)
                        .move(copy, character.hand)
                }
                
            }

            change.setDuration(this._changeDuration);
        }

        if (this.destroyedCharacters.length > 0) {
            for (let character of this.destroyedCharacters) {
                change
                    .addAnimation(new AffectAura(AffectAura.NEGATIVE, `DEATH`), character)
                    .do(() => character.health = 0)
            }

            change.setDuration(this._changeDuration);
        }

        if (this.newCommonCards) {
            let cards = this._player.recyclingCenter.cards();

            if (cards.length) {
                for (let card of cards) {
                    change
                        .scale(card, 1, 0)
                        .despawn(card)
                }
            }


            for (let card of this.newCommonCards)  {
                change
                    .do(() => card.hidden = false)
                    .spawn(card, this._player, null)
                    .move(card, this._player.recyclingCenter)
                    .scale(card, 0, 1)
            }

            change.setDuration(this._changeDuration);
        }

        if (this.addedCommonCards.length) {
            for (let card of this.addedCommonCards)  {
                change
                    .do(() => card.hidden = false)
                    .spawn(card, this._player, null)
                    .move(card, this._player.recyclingCenter)
                    .scale(card, 0, 1)
            }

            change
                .setDuration(this._changeDuration)
                .setDelay(this.addedCommonCards, 0, this._incrementalDelay)
        }

        if (this.recycledCard && this.selectedCommonCard) {
            this._player.history.pushAction(this._player.isOpponent, this.recycledCard, 'recycle');

            let newCard = this.selectedCommonCard.makePlainCopy();
            let recycledCardIndex = this.recycledCard.getPosition().indexInContainer
            let selectedCardIndex = this.selectedCommonCard.getPosition().indexInContainer;

            if (!this._player.isOpponent) {
                change
                    .spawn(newCard, this._player)
                    .despawn(this.recycledCard)
                    .move(newCard, null)
                    .then()
                    .move(newCard, this._player.activeCharacter.hand, recycledCardIndex)
                    .scale(newCard, 0, 1)
                    .setDuration(this._changeDuration)
            } else {
                change
                    .then(0.5)
                    .spawn(newCard, this._player)
                    .do(() => this.recycledCard.forceDisplay = true)
                    .do(() => newCard.forceDisplay = true)
                    .then()
                    .move(this.recycledCard, this._player.recyclingCenter, selectedCardIndex)
                    .scale(this.recycledCard, 1, 0)
                    .despawn(this.recycledCard)
                    .scale(newCard, 0, 1)
                    .move(newCard, this._player.activeCharacter.hand, recycledCardIndex)
                    .setDuration(this._changeDuration)
            }
        } else {
            this.recycledCard = null;
            this.selectedCommonCard = null;
        }

        for (let [replacedCard, newCard] of this.replacedCards.entries()) {
            let container = replacedCard.getContainer();
            let index = replacedCard.getPosition()?.getIndexInContainer;

            change
                .despawn(replacedCard)
                .then()
                .spawn(newCard, this._player)
                .move(newCard, container, index)
                .scale(newCard, 0, 1)
                .setDuration(this._changeDuration)

        }

        if (this.playedCard && this.playedCard.canBePlayed() && this.playedCard.areTargetsValid(this.playedCardTargets)) {
            this._player.history.pushAction(this._player.isOpponent, this.playedCard, 'play');

            if (this._player.isOpponent) {
                change
                    .then(0.5)
                    .do(() => this.playedCard.hidden = false)
                    .addAnimation(TriggerAura, this.playedCard)
                    .then(0.1)
            }

            change
                .do(() => this.playedCard.destroyed = true)
                .move(this.playedCard, null)
                .then()
        } else {
            this.playedCard = null;
        }

        if (this.discardedCards.length > 0) {
            for (let card of this.discardedCards) {
                change
                    .scale(card, 1, 0)
                    .despawn(card)
            }

            change
                .setDuration(this._changeDuration)
                .setDelay(this.discardedCards, 0, this._incrementalDelay)
                .then();
        }

        if (this.goldChange !== 0) {
            if (this.showGoldEffect) {
                let str = this.goldChange > 0 ? `+${this.goldChange}` : this.goldChange.toString();

                change.addAnimation(new AffectAura(AffectAura.POSITIVE, `${str} gold`), this._player.goldCounter)
            }

            change
                .do(() => this._player.gold += this.goldChange)
                .then();
        }

        if (this.newIncome) {
            change.do(() => this._player.income = this.newIncome);
        }

        if (this.consumedEffects.length > 0) {
            for (let effect of this.consumedEffects) {
                change
                    .scale(effect, 1, 0)
                    .despawn(effect)
            }

            change
                .defragment(this._player.effectList)
                .setDuration(this._changeDuration)
                .then()
        }

        if (this.newMinionOrder) {
            for (let i = 0; i < this.newMinionOrder.length; ++i) {
                change.move(this.newMinionOrder[i], this._player.battlefield, i);
            }

            change
                .defragment(this._player.battlefield)
                .setDuration(this._changeDuration)
                .then()
        }

        if (this.sacrificedMinions.length > 0) {
            for (let minion of this.sacrificedMinions) {
                change
                    .scale(minion, 1, 0)
                    .move(minion, null)
            }

            change
                .do(() => this.sacrificedMinions.forEach(minion => minion.destroyed = true))
                .defragment(this._player.battlefield)
                .setDuration(this._changeDuration)
                .then()
        }

        if (this.givenCards.length) {
            for (let [card, character] of this.givenCards) {
                character = character || this._player.activeCharacter;

                change
                    .spawn(card, this._player)
                    .scale(card, 0, 1)
                    .move(card, character.hand)
                    .defragment(character.hand)
            }

            change
                .setDuration(this._changeDuration)
                .setDelay(this.givenCards.map(array => array[0]), 0, this._incrementalDelay)
                .then()
        }

        if (this.summonedMinions.length) {
            let index = (this.leftSummon || this._player.leftSummonThisTurn) ? -1 : null;

            for (let minion of this.summonedMinions) {
                change
                    .spawn(minion, this._player, null)
                    .move(minion, this._player.battlefield, index)
                    .scale(minion, 0, 1)
            }

            change
                .defragment(this._player.battlefield)
                .setDuration(this._changeDuration)
                .setDelay(this.summonedMinions, 0, this._incrementalDelay)
        }

        if (this.createdEffects.length) {
            for (let effect of this.createdEffects) {
                let effectList = effect.isUpgrade ? this._player.activeCharacter.upgradeList : this._player.effectList;

                change
                    .spawn(effect, this._player, null)
                    .move(effect, effectList)
                    .scale(effect, 0, 1)
                    .defragment(effectList)
            }

            change
                .setDuration(this._changeDuration)
                .setDelay(this.createdEffects, 0, this._incrementalDelay)
        }

        for (let [minion, power] of this.minionPowerSets.entries()) {
            if (minion.destroyed) {
                continue;
            }

            change
                .addAnimation(new AffectAura(AffectAura.POSITIVE, `=${power} power`), minion)
                .do(() => minion.power = power)
        }

        for (let [minion, powerChange] of this.minionPowerChanges.entries()) {
            if (minion.destroyed || powerChange === 0) {
                continue;
            }

            let kind = powerChange < 0 ? AffectAura.NEGATIVE : AffectAura.POSITIVE;
            let text = powerChange < 0 ? `${powerChange} power` : `+${powerChange} power`;

            if (minion.power + powerChange <= 0) {
                this.killedMinions.push(minion);
            }

            change
                .addAnimation(new AffectAura(kind, text), minion)
                .do(() => minion.power += powerChange)
        }

        for (let [minion, { callback, text }] of this.minionCallbacks.entries()) {
            if (minion.destroyed) {
                continue;
            }

            change
                .addAnimation(new AffectAura(AffectAura.POSITIVE, text), minion)
                .do(() => callback(minion))
        }

        if (this.minionDamages.size) {
            for (let [minion, damages] of this.minionDamages.entries()) {
                change
                    .addAnimation(new AffectAura(AffectAura.NEGATIVE), minion)
                    .do(() => minion.power -= damages)
                
                if (minion.power <= damages) {
                    this.killedMinions.push(minion);
                }
            }
        }

        if (this.minionPowerSets.size || this.minionPowerChanges.size || this.minionDamages.size) {
            change.setDuration(this._changeDuration);
        }

        if (this.killedMinions.length > 0) {
            change.then();

            for (let minion of this.killedMinions) {
                change
                    .scale(minion, 1, 0)
                    .move(minion, null)
            }

            change
                .do(() => this.killedMinions.forEach(minion => minion.destroyed = true))
                .setDuration(this._changeDuration)
                .updateOpponent(!this.isCombat)
                .then()
                .defragment(this._player.battlefield)
                .setDuration(this._changeDuration)
                .updateOpponent(!this.isCombat)
        }

        if (this.damagesDealt > 0) {
            change
                .then()
                .addAnimation(new AffectAura(AffectAura.NEGATIVE, `-${this.damagesDealt}`), this._player.health)
                .do(() => this._player.activeCharacter.health -= this.damagesDealt)
        }

        if (this.healthRestored > 0) {
            change
                .then()
                .addAnimation(new AffectAura(AffectAura.POSITIVE, `+${this.healthRestored}`), this._player.health)
                .do(() => this._player.activeCharacter.health += this.healthRestored)
        }

        for (let callback of this.callbacks) {
            change.do(callback);
        }
    }

    onPreTrigger() {
        if (!this._shouldTrigger()) {
            return;
        }

        let result = [];

        for (let entity of this._player.board.entities) {
            if (entity.onBeforeStateChange && entity.canInteractWithStateChange(this)) {
                let output = new PlayerStateChange(this._player);

                entity.onBeforeStateChange(this, output);

                if (!output._isEmpty) {
                    result.push(output);
                }

                if (this.canceled) {
                    break;
                }
            }
        }

        return result;
    }

    onStart() {
        if (!this._shouldTrigger()) {
            return;
        }

        this.resolve();
    }

    onEnd() {
        if (!this._shouldTrigger()) {
            return;
        }

        return this._boardStateChange;
    }

    onPostTrigger() {
        if (!this._shouldTrigger()) {
            return;
        }

        let result = [
            ...this._player.board.emitEvent('onStateChange', this, entity => entity.canInteractWithStateChange(this), () => new PlayerStateChange(this._player)),
        ].filter(output => !output._isEmpty);

        if (this._next) {
            result.push(this._next);
        }

        result.push(new PostStateChangeEvent(this));

        return result;
    }

    then() {
        this._next = new PlayerStateChange(this._player);

        return this._next;
    }
}

class PostStateChangeEvent {
    constructor(playerStateChange) {
        this.playerStateChange = playerStateChange;
    }

    onEnd() {
        return this.playerStateChange._player.board.emitEvent(
            'onAfterStateChange',
            this.playerStateChange,
            entity => entity.canInteractWithStateChange(this.playerStateChange),
            () => new PlayerStateChange(this.playerStateChange._player)
        ).filter(output => !output._isEmpty);
    }
}
globalThis.ALL_FUNCTIONS.push(PlayerStateChange);
globalThis.ALL_FUNCTIONS.push(PostStateChangeEvent);