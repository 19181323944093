import { DisplaySize } from '../../utils/display-size'

export class EntityLayoutInfo {
    constructor({ position = null, scale = 1, itemCount = 1, items = [] } = {}) {
        this.position = position;
        this.scale = scale;
        this.itemCount = itemCount;
        this.items = items;
    }

    clone() {
        return new EntityLayoutInfo({ position: this.position?.clone(), scale: this.scale, itemCount: this.itemCount, items: this.items.slice() });
    }

    getRect(isPrev, mirrorPoint) {
        if (!this.position || this.position.isEmpty()) {
            return null;
        }

        if (this.position.rect) {
            let result = this.position.rect;
            // let mirrorPoint = this.getMirrorPoint();

            if (mirrorPoint) {
                result = result.mirror(mirrorPoint.x ?? result.x, mirrorPoint.y ?? result.y);
            }

            return result;
        }

        let container = this.position.container;
        let containerInfo = isPrev ? container.prevLayoutInfo : container.layoutInfo;
        let mirrorItemCount = container.mirrorContainer?.[isPrev ? 'prevLayoutInfo' : 'layoutInfo'].itemCount || 0;
        let itemCount = Math.max(containerInfo.itemCount, mirrorItemCount);
        let containerRect = containerInfo.getRect(isPrev);

        if (!containerRect) {
            return null;
        }

        let containerMirrorPoint = container.getMirrorPoint();
        let rowCount = container.rowCount;
        containerRect = containerRect.mirror(containerMirrorPoint?.x ?? containerRect.x, containerMirrorPoint?.y ?? containerRect.y);
        let headerSize = DisplaySize.resolve(container.headerSize, containerRect);
        containerRect = containerRect.stripFromSides(headerSize, 0, 0, 0);
        let outerMargin = DisplaySize.resolve(container.outerMargin, containerRect);
        let innerMargin = DisplaySize.resolve(container.innerMargin, containerRect);
        let itemCountPerRow = container.itemCountPerRow;
        let itemCountPerColumn = container.itemCountPerColumn;
        let itemAspectRatio = container.itemAspectRatio;
        let minItemCountPerRow = container.minItemCountPerRow;
        let minItemCountPerColumn = container.minItemCountPerColumn;
        let horizontalAlign = container.horizontalAlign;
        let verticalAlign = container.verticalAlign;

        // if (container.constructor.name === 'EffectList') {
        //     console.log(horizontalAlign)
        // }

        let { cells } = containerRect.computeGridLayout({
            itemCount,
            outerMargin,
            innerMargin,
            itemAspectRatio,
            itemCountPerRow,
            itemCountPerColumn,
            minItemCountPerRow,
            minItemCountPerColumn,
            horizontalAlign,
            verticalAlign
        });

        return cells[this.position.indexInContainer];
    }
}
globalThis.ALL_FUNCTIONS.push(EntityLayoutInfo);