import { CARD_TOOLTIP_HEIGHT, CARD_TOOLTIP_IMAGE_ASPECT_RATIO, CARD_TOOLTIP_WIDTH } from './constants/graphics-constants';

export class CardTooltip {
    constructor({ title, image, description, topRightBadge = null, bottomList = [] }) {
        this.title = title;
        this.image = image;
        this.description = description;
        this.topRightBadge = topRightBadge;
        this.imageAspectRatio = CARD_TOOLTIP_IMAGE_ASPECT_RATIO;
        this.topRightBadgeTextSize = '80%';
        this.topRightBadgeSize = '15%w';
        this.width = CARD_TOOLTIP_WIDTH;
        this.height = CARD_TOOLTIP_HEIGHT;
        this.descriptionTextSize = '10%';
        this.bottomList = bottomList;
        this.bottomListScale = 0.7;
    }
}
globalThis.ALL_FUNCTIONS.push(CardTooltip);