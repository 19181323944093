import { Board, BoardStateChange } from 'vortex';
import { AffectAura } from '../animations/affect-aura';
import { DEFAULT_STATE_CHANGE_DURATION, DELAY_BEFORE_COMBAT } from '../constants/graphics-constants';
import { PlayerStateChange } from './player-state-change';

export class Combat {
    constructor(game) {
        let players = game.getPlayers();

        this.game = game;
        this.p1 = players[0];
        this.p2 = players[1];
    }

    onEnd() {
        let board = new Board([...this.p1.board.entities, ...this.p2.board.entities]);
        let change = new BoardStateChange(board);
        let p1Minions = this.p1.battlefield.minions();
        let p2Minions = this.p2.battlefield.minions();
        let pairCount = Math.max(p1Minions.length, p2Minions.length);
        let result = {
            minionDamages: new Map(),
            minionDeaths: [],
            playerDamages: new Map(),
        };

        this.game.setCommonBoard(board);

        for (let i = 0; i < pairCount; ++i) {
            let first = p1Minions[i];
            let second = p2Minions[i];

            this._triggerPairCombat(first, second, result);
            this._triggerPairCombat(second, first, result);
        }

        change.then(DELAY_BEFORE_COMBAT);

        for (let [minion, damages] of result.minionDamages.entries()) {
            change
                .addAnimation(new AffectAura(AffectAura.NEGATIVE), minion)
                .do(() => minion.power -= damages)
        }

        if (result.minionDeaths.length > 0) {
            change.then();

            for (let minion of result.minionDeaths) {
                change
                    .scale(minion, 1, 0)
                    .move(minion, null)
            }

            change
                .do(() => result.minionDeaths.forEach(minion => minion.destroyed = true))
                .setDuration(DEFAULT_STATE_CHANGE_DURATION)
                .then()
                .defragment(this.p1.battlefield)
                .defragment(this.p2.battlefield)
                .setDuration(DEFAULT_STATE_CHANGE_DURATION)
        }

        for (let [player, damages] of result.playerDamages.entries()) {
            change
                .then(0.4)
                .addAnimation(new AffectAura(AffectAura.NEGATIVE, `-${damages}`), player.activeCharacter)
                .do(() => player.activeCharacter.health -= damages)
        }

        let events = [];

        events.push(change);

        for (let player of [this.p1, this.p2]) {
            let deadMinions = result.minionDeaths.filter(minion => minion.owner === player);

            if (deadMinions.length > 0) {
                let playerStateChange = new PlayerStateChange(player);
                playerStateChange.killedMinions = deadMinions;
                playerStateChange._markNotEmpty();

                events.push(playerStateChange);
            }
        }

        return events;
    }

    _triggerPairCombat(attacker, defender, result) {
        if (!attacker || attacker.bypassCombat || defender?.bypassCombat) {
            return;
        }

        if (defender) {
            let damages = Math.ceil(attacker.power * attacker.minionDamageModifier * defender.receivedDamageModifier);

            if (attacker.poisonous) {
                damages = defender.power;
            } else if (defender.immuneToWeakerMinions && attacker.power < defender.power) {
                damages = 0;
            } else if (defender.fixedPowerDivider) {
                damages = Math.ceil(defender.power / defender.fixedPowerDivider);
            }

            if (!defender.indestructible && damages > 0) {
                result.minionDamages.set(defender, damages);

                if (damages >= defender.power) {
                    result.minionDeaths.push(defender);
                }
            }
        } else {
            let damages = Math.ceil(attacker.power * attacker.playerDamageModifier);

            if (damages > 0) {
                result.playerDamages.processValue(attacker.owner.opponent, 0, value => value + damages);
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Combat);