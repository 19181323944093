import { CARD_COUNT_PER_TURN } from '../constants/game-constants';
import { HOVER_OVERLAY_COLOR } from '../constants/graphics-constants';
import { Panel } from './panel';

const GAME_STEP_TO_TEXT = {
    'player-actions': 'End turn',
    'waiting-for-opponent': 'Waiting for opponent',
    'opponent-actions': 'Opponent turn',
    'combat': 'Combat',
    'end-combat': 'Combat',
    'victory': "Victory!",
    'defeat': "Defeat.",
    'draw': "Draw!",
};

const DISABLED_COLOR = '#555555';

export class EndTurnButton extends Panel {
    hideIfOpponent = true

    render({ view }) {
        let text = GAME_STEP_TO_TEXT[this.owner.gameStep] || '';
        let textSize = '40%';

        if (this.owner.gameStep == 'waiting-for-opponent') {
            textSize = '30%';
        }

        view
            .text(text)
            .textSize(textSize)
            .textColor('black')
            .borderColor('black')
            .backgroundColor('white')
            .borderWidth(2)
            .borderRadius('50%')
            .disabledTextColor(DISABLED_COLOR)
            .disabledBorderColor(DISABLED_COLOR)
            .hoverCursor('pointer')
            .hoverBorderScale(1.5)
            .hoverOverlayColor(HOVER_OVERLAY_COLOR)
            .setDisabled(this.owner.gameStep !== 'player-actions')
    }

    getTooltip() {
        if (this.owner.gameStep !== 'player-actions') {
            return;
        }

        return {
            width: 425,
            description: [
                `You draw ${CARD_COUNT_PER_TURN} cards per turn.`,
                `Play a card by dragging it on the battlefield.`,
                `You can also recycle or stash cards by dragging them on the corresponding area.`,
                `Your turn ends when you have no more card in your hand.`,
                `Players play their turn at the same time.`,
                `When both players have played their turn, the combat step starts.`,
                `During combat, each minion deal its power as damages to the minion in front of them.`,
                `Minions that don't have a minion in front of them deal their power as damages to the opponent player.`,
                `The game ends when one of the player's health reaches 0.`,
            ]
        };
    }

    onClick({ client }) {
        client.sendGameInput('endTurn');
    }
}
globalThis.ALL_FUNCTIONS.push(EndTurnButton);