import { Animation } from 'vortex';
import { AFFECT_AURA_ALPHA_START, AFFECT_AURA_DURATION, AFFECT_AURA_EASING, AFFECT_AURA_END_TEXT_OFFSET, AFFECT_AURA_START_TEXT_OFFSET, AFFECT_AURA_TEXT_SIZE } from '../constants/graphics-constants';

export class AffectAura extends Animation {
    duration = AFFECT_AURA_DURATION
    easing = AFFECT_AURA_EASING
    kind = AffectAura.NEUTRAL
    text = null

    constructor(kind = AffectAura.NEUTRAL, text = null) {
        super();

        this.kind = kind;
        this.text = text;
    }

    static NEUTRAL = 0
    static POSITIVE = 1
    static VERY_POSITIVE = 2
    static WEAKEN = 3
    static NEGATIVE = 4

    render({ view, client }) {
        let color = KIND_TO_COLOR[this.kind] || 'black';
        let rect = client.getView(this.target).rect;

        // if (!rect) {
        //     return;
        // }

        view
            .setRect(rect)
            .overlayColor(color)
            .overlayAlpha((1 - this.t * this.t * this.t) * AFFECT_AURA_ALPHA_START)

        if (this.text) {
            let textOffsetY = Math.mix(AFFECT_AURA_START_TEXT_OFFSET, AFFECT_AURA_END_TEXT_OFFSET, this.t);
            let textSize = AFFECT_AURA_TEXT_SIZE;
            let textRect = rect.getTopNeighbor(textSize, textOffsetY);
            let floatingText = ({view}) => {
                view
                    .text(this.text)
                    .textColor(color)
                    .textBold(true)
                    .setPointerBehavior('none');
            }

            view.renderChild(floatingText, textRect);
        }
    }
}

const KIND_TO_COLOR = {
    [AffectAura.NEUTRAL]: 'darkorchid',
    [AffectAura.POSITIVE]: 'forestgreen',
    [AffectAura.VERY_POSITIVE]: 'gold',
    [AffectAura.WEAKEN]: 'purple',
    [AffectAura.NEGATIVE]: 'firebrick',
}
globalThis.ALL_FUNCTIONS.push(AffectAura);