import { Panel } from './panel';

export class Battlefield extends Panel {
    outerMargin = 5
    innerMargin = 5

    minions() {
        return this.layoutInfo.items;
    }

    render({ view }) {
        // view.backgroundColor('white');
    }
}
globalThis.ALL_FUNCTIONS.push(Battlefield);