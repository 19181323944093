import { Animation } from 'vortex';
import { BOARD_RECT } from '../constants/graphics-constants';

const SCALE_UP_DURATION = 0.1;
const WAIT_DURATION = 1.4;
const TOTAL_DURATION = SCALE_UP_DURATION + WAIT_DURATION;

const KIND_TO_COLORS = {
    neutral: ['sienna', 'bisque'],
    positive: ['blue', 'paleturquoise'],
    negative: ['red', 'lightcoral'],
};

export class Announcement extends Animation {
    constructor({ text, kind = 'neutral' }) {
        super();

        this.text = text;
        this.kind = kind;
        this.duration = TOTAL_DURATION;
    }

    duration = TOTAL_DURATION
    text = null

    render({ view, client }) {
        let tScale = 1 - Math.clamp((SCALE_UP_DURATION - this.elapsed) / SCALE_UP_DURATION, 0, 1);
        let scale = Math.mix(0.75, 1, tScale);
        let rect = BOARD_RECT.setSize(500, 120).scale(scale);
        let [textColor, backgroundColor] = KIND_TO_COLORS[this.kind] || ['black', 'white'];

        view
            .setRect(rect)
            .text(this.text)
            .textSize('50%')
            .textColor(textColor)
            .backgroundColor(backgroundColor)
            .borderColor(textColor)
            .borderWidth('2%')
            .borderRadius('5%')
    }
}
globalThis.ALL_FUNCTIONS.push(Announcement);