export class KeyboardManager {
    constructor() {
        this._window = window;
        this._layout = null;
    }

    async init() {
        if (this._window) {
            this._layout = await this._window.navigator?.keyboard?.getLayoutMap();
        }

        return this;
    }

    getKeyValue(key) {
        return this._layout.get(key) || null;
    }
}
globalThis.ALL_FUNCTIONS.push(KeyboardManager);