import { EventChain } from 'vortex';
import { TIME_SPEED_UP_RATIO } from './constants/game-constants';
import { AfterCombat } from './events/after-combat';
import { Combat } from './events/combat';
import { ProcessEndOfCombat } from './events/process-end-of-combat';
import { GameInputManager } from './game-input-manager';
import { HistoryPanel } from './history/history-panel';
import { Player } from './player';

const GAME_FLOW = {
    isGameFinished(game) {
        return game.isFinished();
    },
    sync(game, syncEvent) {
        game.emitEventDelayed(syncEvent);
    },
    steps: {
        turn: {
            onStart(game) {
                game.withBothPlayers(player => player.emitStateChange().setGameStep('player-actions'));
            },
            onEnd(game) {
                game.triggerCombat();
                game.emitEvent(new AfterCombat(game));
            },
            isPlayerActive(game, userId) {
                return game.withPlayer(userId, player => !player.hasFinishedTurn);
            },
            api: {
                playCard(game, userId, { cardId, targetIds }) {
                    game.withPlayer(userId, player => player.emitPlayerInputDelayed().playCard(cardId, targetIds));
                },
                recycleCard(game, userId, { cardId, targetIds }) {
                    game.withPlayer(userId, player => player.emitPlayerInputDelayed().recycleCard(cardId, targetIds));
                },
                endTurn(game, userId) {
                    game.withPlayer(userId, player => player.emitPlayerInputDelayed().endTurn());
                },
                forfeit(game, userId) {
                    game.withPlayer(userId, player => player.emitPlayerInputDelayed().forfeit());
                }
            }
        },
        endOfTurn: {
            isPlayerActive(game, userId) {
                return game.withPlayer(userId, player => player.mustChooseCharacter);
            },
            api: {
                selectCharacter(game, userId, { characterId }) {
                    game.withPlayer(userId, player => player.emitPlayerInputDelayed().selectCharacter(characterId));
                }
            }
        }
    }
};

export class Game {
    constructor(players) {
        this._players = new Map();
        this._isServer = null;
        this._eventChain = new EventChain();
        this._gameInputManager = new GameInputManager(players, this, GAME_FLOW);
        this._history = new HistoryPanel();
        this._commonBoard = null;
        this._paused = false;

        for (let { userId, name, gear, rng } of players) {
            this._players.set(userId, new Player({ userId, name, gear, rng, eventChain: this._eventChain, history: this._history }));
        }

        for (let player of this._players.values()) {
            player.setOpponent(this._getOpponent(player));
        }
    }

    isFinished() {
        return this.getPlayers().some(player => player.hasLost);
    }

    triggerCombat() {
        this.withBothPlayers(player => player.emitStateChange().setGameStep('combat'));
        this.emitEvent(new Combat(this));
        this.withBothPlayers(player => player.emitStateChange().setGameStep('end-combat'));
    }

    wait(duration) {
        this._eventChain.wait(duration);
        return this;
    }

    emitEvent(event) {
        this._eventChain.add(event);
        return this;
    }

    emitEventDelayed(event) {
        this._eventChain.append(event);
    }

    setCommonBoard(board) {
        this._commonBoard = board;
    }

    getPlayerByUserId(id) {
        return this._players.get(id);
    }

    getPlayers() {
        return Array.from(this._players.values()).sort((p1, p2) => +p1.isOpponent - p2.isOpponent);
    }

    init({ server, client }) {
        this._isServer = !!server;

        for (let player of this._players.values()) {
            player.init({ client });
        }

        this._gameInputManager.start();
        this._eventChain.trigger(Infinity);
    }

    update({ client = null, server = null, elapsed }) {
        if (this._paused) {
            return;
        }

        if (server) {
            elapsed = Infinity;
        }

        elapsed *= TIME_SPEED_UP_RATIO;

        this._eventChain.trigger(elapsed);

        if (this._eventChain.isEmpty()) {
            this._gameInputManager.update(server);
        }

        if (client) {
            this._clientUpdate(client);
        }
    }

    _clientUpdate(client) {
        let player = this.getPlayers().find(player => !player.isOpponent);

        if (player.mustChooseCharacter && !player.characterSelected && !player.cardSelectionOverlay.shown) {
            player.characterSelected = true;
            player.cardSelectionOverlay.show({
                cards: player.backline.characters(),
                itemKindName: 'character',
                columnSize: 1,
                allowCancel: false,
                onComplete: (characters) => {
                    client.sendGameInput('selectCharacter', { characterId: characters[0].id });
                },
            })
        }
    }

    _getOpponent(player) {
        return Array.from(this._players.values()).find(p => p !== player);
    }

    render({ view }) {
        view.backgroundColor('blanchedalmond');

        let players = Array.from(this._players.values());
        let p1 = players.find(player => !player.isOpponent);
        let p2 = players.find(player => player.isOpponent);

        let entities = p2.getEntities().concat(p1.getEntities()).sort((e1, e2) => e1.getOrderIndex() - e2.getOrderIndex());
        let animations = p2.getAnimations().concat(p1.getAnimations()).concat(this._commonBoard?.animations || []);

        for (let entity of entities) {
            if (entity.owner.isOpponent && entity.hideIfOpponent) {
                continue;
            }

            let rect = entity.getRect();

            if (!rect) {
                continue;
            }

            view.renderChild(entity, rect);

            if (entity.header) {
                let headerRect = entity.getHeaderRect();

                view.renderChild(entity.header, headerRect);
            }
        }

        for (let animation of animations) {
            view.renderChild(animation);
        }

        if (this._history.shown) {
            view.renderChild(this._history);
        }

        // view.renderChild(p1.summonLocationIndicator);
    }
    
    withPlayer(userId, callback) {
        let player = this.getPlayerByUserId(userId);

        return callback(player);
    }

    withBothPlayers(callback) {
        for (let player of this.getPlayers()) {
            callback(player);
        }
    }

    getCompleteInputHistory(userId) {
        return this._gameInputManager.getCompleteInputHistory(userId);
    }

    onPlayerInput(input) {
        return this._gameInputManager.processInput(input);
    }

    onPlayerExit(userId) {
        
    }

    onKeyDown({ client, code, shiftKey }) {
        if (code === 'Escape') {
            this._history.shown = false;
        } else if (code === 'KeyH') {
            this._history.shown = !this._history.shown;
        }

        if (shiftKey) {
            if (code === 'KeyC') {
                client.sendGameInput('endTurn');
            } else if (code === 'KeyD') {
                client.sendGameInput('forceDraw', { count: 2 });
            } else if (code === 'KeyZ') {
                client.sendRequest('refreshGame');
            } else if (code === 'KeyS') {
                this.update({ elapsed: Infinity });
            } else if (code === 'KeyP') {
                this._paused = !this._paused;
            } else if (code === 'KeyX') {
                client.localData.showHidden = !client.localData.showHidden;
            } else if (code === 'KeyA') {
                client.sendRequest('exitGame');
            }
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Game);