import { STARTING_INCOME } from '../constants/game-constants';
import { GOLD_COUNTER_HEIGHT } from '../constants/graphics-constants';
import { Panel } from './panel';

export class GoldCounter extends Panel {
    render({ view }) {
        view
            .setRect(view.rect.setHeightFromBottom(GOLD_COUNTER_HEIGHT))
            .text(`@goldenrod{*${this.owner.gold}*@0.7{ / ${this.owner.income}}}`)
            .textOffsetY(1)
            .textSize('80%')
            .borderRadius('50%')
            .backgroundColor('white')
            .borderColor('saddlebrown')
            .borderWidth('1r')
    }

    getTooltip() {
        if (this.owner.isOpponent) {
            return;
        }

        return [
            `You start the game with an income of ${STARTING_INCOME}.`,
            `You get +1 income per turn.`,
            `Unspent gold is lost at the beginning of combat.`,
        ];
    }
}
globalThis.ALL_FUNCTIONS.push(GoldCounter);