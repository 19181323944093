import { makeMinionCard } from '../../cards/make-minion';

export const InspiredFighterCard = makeMinionCard({
    name: 'Inspired fighter',
    description: 'After this is summoned, give it +2 power for each other minion you have.',
    power: 3,
    goldCost: 2,

    onStateChange(change, output) {
        if (change.summonedMinions.includes(this)) {
            let boost = (this.owner.getMinions().length - 1) * 2;

            output
                .setSource(this)
                .increasePower(this, boost);
        }
    }
})