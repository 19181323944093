export class ViewStack {
    viewIds = []
    views = []

    clear() {
        this.viewIds = [];
        this.views = [];
    }

    softClear() {
        this.views.clear();
    }

    fillFromIds(allViews) {
        let views = [];
        let ids = [];

        for (let view of allViews) {
            let id = view.getDataId();

            if (id && this.viewIds.includes(id)) {
                ids.push(id);
                views.push(view);
            }
        }

        views.reverse();

        this.views = views;
        this.viewIds = ids;
    }

    setFromStack(stack) {
        this.viewIds = stack.viewIds.slice();
        this.views = stack.views.slice();
    }

    setFromArray(views) {
        this.viewIds = views.map(view => view.getDataId()); 
        this.views = views.clone();
        return this;
    }

    setFromView(view) {
        this.clear();

        if (view) {
            this.viewIds.push(view.getDataId());
            this.views.push(view);
        }
        return this;
    }

    list() {
        return this.views;
    }

    first() {
        return this.views.first();
    }

    includes(view){
        return this.views.includes(view);
    }

    emit(name, payload) {
        for (let view of this.views) {
            let object = view.data;

            if (object) {
                if (payload && payload.view !== undefined) {
                    payload.view = view;
                }
                let intercept = object[name]?.(payload);

                if (intercept) {
                    return payload;
                }
            }
        }

        return payload;
    }
}
globalThis.ALL_FUNCTIONS.push(ViewStack);