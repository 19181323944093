const KIND_TO_TEXT = {
    play: 'Play',
    recycle: 'Recycle',
    stash: 'Stash'
}

export class HistoryAction {
    constructor(kind, card) {
        this.kind = kind;
        this.card = card;
    }

    render({ client, view }) {
        this.card.render({ client, view });

        let actionName = KIND_TO_TEXT[this.kind] || '?';

        let textRect = view.rect.getBottomNeighbor(15, -18).scaleWidth(0.6);

        view.renderChild(({ view }) => {
            view
                .text(actionName)
                .textSize('80%')
                .textColor('rebeccapurple')
                .borderColor('rebeccapurple')
                .backgroundColor('white')
                .borderWidth('1r')
                .borderRadius('50%')
        }, textRect);
    }

    getTooltip(evt) {
        return this.card.getTooltip(evt);
    }
}
globalThis.ALL_FUNCTIONS.push(HistoryAction);