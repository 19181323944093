import { BOARD_RECT } from '../constants/graphics-constants';
import { HistoryAction } from './history-action';
import { HistoryPanelHeader } from './history-header';
import { HistoryTurn } from './history-turn';

export class HistoryPanel {
    constructor() {
        this.header = new HistoryPanelHeader();
        this.shown = false;
        this.turns = [];
    }

    pushTurn() {
        let turn = new HistoryTurn(this.turns.length + 1);
        
        this.turns.push(turn);
    }

    pushAction(isOpponent, card, actionType) {
        let copy = card.makePlainCopy();
        copy.owner = card.owner;
        copy.hidden = false;

        let action = new HistoryAction(actionType, copy);
        let currentTurn = this.turns.last();

        currentTurn.actions(isOpponent).push(action);
    }

    render({ view }) {
        view.setRect(BOARD_RECT.scale(0.8));
        view.setPointerBehavior('opaque');
        view.layout()
            .backgroundColor('lavenderblush')
            .borderColor('black')
            .borderWidth('2r')
            .borderRadius(15)
            .topToBottom()
            .margin(15)
            .push(this.header)
            .height(30)
            .childHeight(120)
            .push(this.turns.slice(-5).reverse())
    }
}
globalThis.ALL_FUNCTIONS.push(HistoryPanel);