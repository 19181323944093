import { Card } from '../../entities/card';

export class UpgradeCard extends Card {
    name = "Upgrade"
    description = "Select an upgrade for your active character @0.8{_(costs 2 gold, then 4, then 6, then 8)_.}"
    isCommon = true
    goldCost = 0
    minTargetCount = 1
    maxTargetCount = 1

    // getShownTargets() {
    //     let result = [];
    //     let hasAvailableUpgrades = this.owner.activeCharacter.availableUpgradeCards.length > 0;

    //     for (let card in this.owner.activeCharacter.availableUpgradeCards) {
    //         result.push({ item: card });
    //     }

    //     for (let card in this.owner.activeCharacter.availableEmblemCards) {
    //         result.push({
    //             item: card,
    //             valid: !hasAvailableUpgrades,
    //             subText: 'Requires all upgrades to be unlocked'
    //         })
    //     }

    //     return result;
    // }

    getValidTargets() {
        let availableUpgrades = this.owner.activeCharacter.availableUpgradeCards;
        let availableEmblems = this.owner.activeCharacter.availableEmblemCards;

        return [ ...availableUpgrades, ...availableEmblems ];
    }

    canTargetEntity(entity) {
        return !entity.isEmblem || this.owner.activeCharacter.availableUpgradeCards.length === 0;
    }

    getTargetSubText(entity) {
        if (!this.canTargetEntity(entity)) {
            return 'Requires all upgrades to be unlocked';
        }
    }

    triggerEffect(output, targets) {
        output.giveCard(targets[0]);
    }
}
globalThis.ALL_FUNCTIONS.push(UpgradeCard);