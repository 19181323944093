export const WINDOW_WIDTH = 1600;
export const WINDOW_HEIGHT = 900;

const DEFAULT_WIDGET_STYLE = {
    backgroundColor: 'white',
    textFont: 'Arial',
    textColor: 'black',
    textSize: '100%',
    borderRadius: '15%',
    borderWidth: '3%',
    focusBorderScale: 2,
    hoverOverlayColor: 'black',
    hoverOverlayAlpha: 0.2,
    disabledOverlayColor: 'black',
    disabledOverlayAlpha: 0.4,
};

export const DEFAULT_CONSTANTS = {
    lobbyTitle: 'Game',
    lobbyBackgroundColor: '#dddddd',
    lobbyTextColor: 'black',
    lobbyHeaderHeight: '12%',
    lobbyFooterHeight: '12%',
    lobbyFooterBackgroundColor: 'grey',
    lobbyUsernameTextColor: 'black',
    lobbyUsernameBackgroundColor: 'white',
    buttonStyle: {
        ...DEFAULT_WIDGET_STYLE,
        textSize: '50%',
        hoverCursor: 'pointer',
    },
    textboxStyle: {
        ...DEFAULT_WIDGET_STYLE,
        textSize: '40%',
        hoverCursor: 'text',
        placeholderColor: 'grey'
    }
};

export const DEFAULT_TOOLTIP_WIDTH = 315;
export const DEFAULT_TOOLTIP_HEIGHT = 400;
export const DEFAULT_TOOLTIP_MARGIN = 10;
export const DEFAULT_TOOLTIP_DESCRIPTION_TEXT_SIZE = 15;
export const DEFAULT_TOOLTIP_TITLE_TEXT_SIZE = 27;
export const DEFAULT_TOOLTIP_BADGE_TEXT_SIZE = 20;
export const DEFAULT_TOOLTIP_BADGE_SIZE = 30;
export const DEFAULT_TOOLTIP_BOTTOM_LIST_SCALE = 1;