import { makeUpgradeCard } from '../../cards/make-upgrade-card';
import { KING_COLOR } from '../../constants/graphics-constants';

export const KingUpgrade3Card = makeUpgradeCard({
    name: 'King upgrade 3',
    description: 'Your minions cost 1 more gold and have +3 power.',
    color: KING_COLOR,

    applyUpgrade(character) {
        for (let card of character.hand.cards()) {
            if (card.summonedMinion) {
                let copy = card.makePlainCopy();

                card.goldCost = copy.goldCost + 1;
                card.summonedMinion.power = copy.summonedMinion.power + 3;
            }
        }
    }
})