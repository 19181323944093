Map.prototype.getOrInsertWith = function(key, insertFunction) {
    let value = this.get(key);

    if (value === undefined) {
        value = insertFunction();
        this.set(key, value);
    }

    return value;
};

Map.prototype.processValue = function(key, baseValue, processFunction) {
    let value = this.get(key);

    if (value === undefined) {
        value = baseValue;
    }

    value = processFunction(value);

    this.set(key, value);
};