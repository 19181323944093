import { Color, Entity } from 'vortex';
import { CardTooltip } from '../card-tooltip';
import { EFFECT_INDEX } from '../constants/game-constants';
import { getColorFromString } from '../utils';

export class Effect extends Entity {
    name = "<effect>"
    description = "-"
    icon = '★'
    color = null
    isUpgrade = false

    _generateEmptyFields() {
        if (!this.color) {
            this.color = getColorFromString(this.name)
        }
    }

    render({ view }) {
        this._generateEmptyFields();
        
        view
            .backgroundColor(this.color)
            .text(this.icon)
            .textSize('50%')
            .borderColor('black')
            .borderWidth(2)
    }

    makeCopy() {
        return this.makePlainCopy();
    }

    getOrderIndex() {
        return EFFECT_INDEX;
    }

    getTooltip() {
        return new CardTooltip({
            title: this.name,
            image: this,
            description: this.description
        });
    }

    canInteractWithStateChange(change) {
        return (!this.destroyed || change.createdEffects.includes(this));
    }
}
globalThis.ALL_FUNCTIONS.push(Effect);