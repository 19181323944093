export class LocalStorage {
    constructor() {
        this._storage = window.localStorage;
        this._keyPrefix = '';
    }

    setKeyPrefix(prefix) {
        this._keyPrefix = prefix;
    }

    getItem(key) {
        let json = this._storage.getItem(this._keyPrefix + key);

        try {
            return JSON.parse(json);
        } catch (e) {
            return null;
        }
    }

    setItem(key, data) {
        let json = JSON.stringify(data);

        this._storage.setItem(this._keyPrefix + key, json);
    }

    removeItem(key) {
        this._storage.removeItem(this._keyPrefix + key);
    }

    clear() {
        this._storage.clear();
    }
}
globalThis.ALL_FUNCTIONS.push(LocalStorage);