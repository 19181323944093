import { Entity } from 'vortex';
import { OVERLAY_INDEX } from '../constants/game-constants';
import { BOARD_RECT } from '../constants/graphics-constants';

export class Overlay extends Entity {
    hideIfOpponent = false

    getMirrorPoint() {
        if (this.owner.isOpponent) {
            return { y: BOARD_RECT.y };
        } else {
            return null;
        }
    }

    getOrderIndex() {
        return OVERLAY_INDEX;
    }
}
globalThis.ALL_FUNCTIONS.push(Overlay);