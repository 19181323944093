import { makeEmblemCard } from '../../cards/make-emblem-card';
import { KING_COLOR } from '../../constants/graphics-constants';

export const KingEmblemCard = makeEmblemCard({
    name: "King's emblem",
    description: 'After you summon a minion, give it +50% power (rounded up).',
    color: KING_COLOR,
    onStateChange(change, output) {
        for (let minion of change.summonedMinions) {
            output
                .setSource(this)
                .increasePower(minion, Math.ceil(minion.power * 0.5))
        }
    }
})