export const FIXED_REAL = 'fixed-real';
export const FIXED_VIRTUAL = 'fixed-virtual';
export const SCALED_FROM_PARENT_WIDTH = 'scaled-from-parent-width';
export const SCALED_FROM_PARENT_HEIGHT = 'scaled-from-parent-height';
export const SCALED_FROM_PARENT_MIN = 'scaled-from-parent-min';

export class DisplaySize {
    constructor(kind, value) {
        this.kind = kind;
        this.value = value;
    }

    static _cache = new DisplaySize()

    static zero() { return new DisplaySize(FIXED_VIRTUAL, 0); }
    static real(value) { return new DisplaySize(FIXED_REAL, value); }
    static virtual(value) { return new DisplaySize(FIXED_VIRTUAL, value); }
    static scaledFromWidth(value) { return new DisplaySize(SCALED_FROM_PARENT_WIDTH, value); }
    static scaledFromHeight(value) { return new DisplaySize(SCALED_FROM_PARENT_HEIGHT, value); }

    static from(value, result = new DisplaySize(FIXED_VIRTUAL, 0)) {
        if (!value) {
            result.value = 0;
        } else if (value instanceof DisplaySize) {
            result.kind = value.kind;
            result.value = value.value;
        } else if (typeof value === 'number') {
            result.kind = FIXED_VIRTUAL;
            result.value = value;
        } else if (typeof value === 'string') {
            let number = parseFloat(value) || 0;
            let kind = FIXED_VIRTUAL;
            let last = value.last();

            if (value.includes('%')) {
                number /= 100;
                kind = SCALED_FROM_PARENT_HEIGHT;
            }

            if (last === 'w') {
                kind = SCALED_FROM_PARENT_WIDTH;
            } else if (last === 'h') {
                kind = SCALED_FROM_PARENT_HEIGHT;
            } else if (last === 'm') {
                kind = SCALED_FROM_PARENT_MIN;
            } else if (last === 'v') {
                kind = FIXED_VIRTUAL;
            } else if (last === 'r') {
                kind = FIXED_REAL;
            }

            result.kind = kind;
            result.value = number;
        } else {
            result.value = 0;
        }

        return result;
    }

    clone() {
        return new DisplaySize(this.kind, this.value);
    }

    scale(ratio) {
        return new DisplaySize(this.kind, this.value * ratio);
    }

    div(ratio) {
        return new DisplaySize(this.kind, this.value / ratio);
    }

    resolve(rect, virtualToRealRatio = 1) {
        switch (this.kind) {
            case FIXED_REAL: return this.value;
            case FIXED_VIRTUAL: return this.value * virtualToRealRatio;
            case SCALED_FROM_PARENT_WIDTH: return this.value * virtualToRealRatio * rect.width;
            case SCALED_FROM_PARENT_HEIGHT: return this.value * virtualToRealRatio * rect.height;
            case SCALED_FROM_PARENT_MIN: return this.value * virtualToRealRatio * Math.min(rect.height, rect.width);
            default: return 0;
        }
    }

    static resolve(value, rect, virtualToRealRatio = 1) {
        return DisplaySize.from(value, this._cache).resolve(rect, virtualToRealRatio);
    }
}
globalThis.ALL_FUNCTIONS.push(DisplaySize);