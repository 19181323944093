import { Button } from '../widgets/button';

export class LobbyFooter {
    constructor({ constants }) {
        this.matchmakingButton = new Button({
            text: 'Search game',
            style: { ...constants.buttonStyle, textSize: '40%' },
            onClick: (evt) => this._sendMatchmakingRequest(evt),
            isDisabled: (evt) => !this._isSelectedGearValid(evt)
        });

        this.aiMatchmakingButton = new Button({
            text: 'Play against a bot',
            style: { ...constants.buttonStyle, textSize: '40%' },
            onClick: (evt) => this._sendMatchmakingRequest(evt, 'DumbBot'),
            isDisabled: (evt) => evt.client.user.isSearchingForGame || !this._isSelectedGearValid(evt)
        });

        this.logoutButton = new Button({
            text: 'Log out',
            style: constants.buttonStyle,
            onClick: (evt) => this._sendLogoutRequest(evt),
        });
    }

    _sendMatchmakingRequest({ client }, botClassName) {
        let start = !client.user.isSearchingForGame;

        return client.sendRequest('matchmaking', { start, botClassName });
    }

    _isSelectedGearValid({ client }) {
        return client.gear.isSelectedGearValid(client.user);
    }

    _sendLogoutRequest({ client }) {
        client.sendRequest('logout');
        client.removeLocalStorageItem('username');
    }

    render({ client, view, constants }) {
        if (client.user.isSearchingForGame) {
            this.matchmakingButton.setText('Cancel search');
        } else {
            this.matchmakingButton.setText('Search for game');
        }

        view.layout()
            .backgroundColor(constants.lobbyFooterBackgroundColor)
            .centerToRight()
            .outerMargin('10%')
            .innerMargin('20%')
            .childAspectRatio(4)
            .push([this.matchmakingButton, this.aiMatchmakingButton])
            .reset()
            .bottomRightToLeft()
            .childHeight('50%')
            .childAspectRatio(3)
            .push(this.logoutButton)
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyFooter);