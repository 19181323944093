import { Card } from '../../entities/card';

export class SwapCard extends Card {
    name = "Swap"
    description = "Choose a backline character and swap it with your active character."
    isCommon = true
    minTargetCount = 1
    maxTargetCount = 1
    goldCost = 0

    // getGoldCost() {
    //     return this.owner.swapCountThisTurn * 2;
    // }

    getValidTargets() {
        return this.owner.backline.characters();
    }

    triggerEffect(output, targets) {
        this.owner.swapCountThisTurn += 1;
        output.setActiveCharacter(targets[0]);
    }
}
globalThis.ALL_FUNCTIONS.push(SwapCard);