export class Clock {
    constructor() {
        this._startTime = performance.now();
        this._lastTick = this._startTime;
    }

    getCurrentTime() {
        return (performance.now() - this._startTime) / 1000;
    }

    tick() {
        let now = performance.now();
        let elapsed = now - this._lastTick;

        this._lastTick = now;

        return elapsed / 1000;
    }
}
globalThis.ALL_FUNCTIONS.push(Clock);