import { HOVER_OVERLAY_COLOR } from '../constants/graphics-constants';
import { Panel } from './panel';

export class ShowHistoryButton extends Panel {
    hideIfOpponent = true

    render({ view }) {
        let text = this.owner.history.show ? "Hide history (H)" : "Show history (H)";

        view
            .text(text)
            .textSize('45%')
            .borderRadius('20%')
            .borderWidth('2r')
            .borderColor('black')
            .backgroundColor('white')
            .hoverCursor('pointer')
            .hoverOverlayColor(HOVER_OVERLAY_COLOR)
    }

    onClick() {
        this.owner.history.shown = !this.owner.history.shown;
    }
}
globalThis.ALL_FUNCTIONS.push(ShowHistoryButton);