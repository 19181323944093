import { HISTORY_PANEL_TURN_NUMBER_WIDTH, OPPONENT_PLAYER_COLOR, SELF_PLAYER_COLOR } from '../constants/graphics-constants';

export class HistoryPanelHeader {
    render({ view }) {
        view.layout()
            .leftToRight()
            .push()
            .width(HISTORY_PANEL_TURN_NUMBER_WIDTH)
            .text("Turn")
            .textColor('black')
            .push()
            .text("Your moves")
            .textColor(SELF_PLAYER_COLOR)
            .push()
            .text("Opponent moves")
            .textColor(OPPONENT_PLAYER_COLOR)
    }
}
globalThis.ALL_FUNCTIONS.push(HistoryPanelHeader);