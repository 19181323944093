let path = {};
let fs = {};
let http = {};
let https = {};
let url = {};
let express = {};
let ws = {};

export async function loadNodeModules() {
    let modules = { path, fs, http, https, url, express, ws };

    for (let [name, module] of Object.entries(modules)) {
        Object.assign(module, await import(name));
    }
}

export { path, fs, http, https, url, express, ws };