export class TextBox {
    constructor({ placeholder, style, onEnter }) {
        this._placeholder = placeholder;
        this._style = style;
        this._onEnter = onEnter;
        this._text = '';
        this._cursorIndex = 0;
    }

    get text() {
        return this._text;
    }

    clear() {
        this._text = '';
        this._cursorIndex = 0;
    }

    render({ view }) {
        let style = this._style;
        let text = this._text;
        let cursorIndex = this._cursorIndex;
        let textColor = style.textColor;

        if (!text) {
            text = this._placeholder;
            cursorIndex = -1;
            textColor = style.placeholderColor;
        }

        view
            .addToFocusChain()
            .text(text)
            .backgroundColor(style.backgroundColor)
            .textColor(textColor)
            .textSize(style.textSize)
            .textFont(style.textFont)
            .textHorizontalAlign('left')
            .borderColor(style.textColor)
            .borderWidth(style.borderWidth)
            .borderRadius(style.borderRadius)
            .textPadding(style.borderRadius)
            .textFixVerticalCenter(false)
            .focusBorderScale(style.focusBorderScale)
            .focusTextCursorIndex(cursorIndex)
            .hoverCursor(style.hoverCursor)
            .disabledOverlayColor(style.disabledOverlayColor)
            .disabledOverlayAlpha(style.disabledOverlayAlpha)
    }

    onMouseDown(evt) {
        evt.willBeFocused = this;
    }

    onKeyDown({ client, view, code, ctrlKey, text: keyText }) {
        if (!view.isFocused()) {
            return;
        }

        let text = this._text;
        let cursorIndex = this._cursorIndex;
        let valid = true;

        cursorIndex = Math.clamp(cursorIndex, 0, text.length);

        if (code === 'Escape') {
            client.focus(null);
        } else if (code === 'Enter') {
            this._onEnter?.({ client, view });
        } else if (code === 'Backspace') {
            if (ctrlKey) {
                text = text.substring(cursorIndex);
                cursorIndex = 0;
            } else {
                text = text.substring(0, cursorIndex - 1) + text.substring(cursorIndex);
                cursorIndex -= 1;
            }
        } else if (code === 'Delete') {
            if (ctrlKey) {
                text = text.substring(0, cursorIndex);
            } else {
                text = text.substring(0, cursorIndex) + text.substring(cursorIndex + 1);
            }
        } else if (code === 'Home') {
            cursorIndex = 0;
        } else if (code === 'End') {
            cursorIndex = text.length;
        } else if (code === 'ArrowLeft') {
            if (ctrlKey) {
                cursorIndex = 0;
            } else {
                cursorIndex -= 1;
            }
        } else if (code === 'ArrowRight') {
            if (ctrlKey) {
                cursorIndex = text.length;
            } else {
                cursorIndex += 1;
            }
        } else if (isValidCharacter(keyText) && !ctrlKey) {
            text = text.substring(0, cursorIndex) + keyText + text.substring(cursorIndex, text.length);
            cursorIndex += 1;
        } else {
            valid = false;
        }

        if (valid) {
            cursorIndex = Math.clamp(cursorIndex, 0, text.length);

            this._text = text;
            this._cursorIndex = cursorIndex;
        }

        return valid;
    }
}

function isValidCharacter(c) {
    return c && /^[0-9a-zA-Z_ ~!@#$%&*()+-=,<.>/?|]$/.test(c);
}
globalThis.ALL_FUNCTIONS.push(TextBox);