export class DumbBot {
    constructor(user) {
        this.user = user;
        this.turnPlayed = false;

        user.selectedGear = { 'bot': 1, 'bot-deck': 1 }
    }

    update({ server }) {
        if (!this.user.game) {
            server.removeObjectToUpdate(this);
        }

        let player = this.user.game.getPlayerByUserId(this.user.id);

        if (player.gameStep === 'player-actions') {
            if (!this.turnPlayed) {
                let cardIds = player.activeCharacter.hand.cards().map(card => card.id);

                // server.$gameInput(this.user, { type: 'recycleCard', data: { cardId: cardIds.last(), targetIds: [player.recyclingCenter.cards().first().id] } });
                
                for (let cardId of cardIds) {
                    server.$gameInput(this.user, { type: 'playCard', data: { cardId } });
                }

                server.$gameInput(this.user, { type: 'endTurn' });
                this.turnPlayed = true;
            }
        } else {
            this.turnPlayed = false;
        }
    }
}
globalThis.ALL_FUNCTIONS.push(DumbBot);