import { Card } from '../../entities/card';

export class BattlecryCard extends Card {
    name = 'Battlecry'
    description = 'Give all your minions +1 power.'
    goldCost = 1
    color = 'orange'

    triggerEffect(output) {
        output.increasePower(this.owner.getMinions(), 1);
    }
}

export class UpgradedBattlecryCard extends BattlecryCard {
    name = 'Battlecry+'
    description = 'Give all your minions +1 power.\n\nYour minions with a stronger neighbor get an additional +2 power.'

    triggerEffect(output) {
        super.triggerEffect(output);

        let minions = this.owner.getMinions().filter(minion => minion.getNeighbors().find(neighbor => neighbor.power > minion.power));

        output.increasePower(minions, 2);
    }
}
globalThis.ALL_FUNCTIONS.push(BattlecryCard);
globalThis.ALL_FUNCTIONS.push(UpgradedBattlecryCard);