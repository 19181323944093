import { Panel } from './panel';

export class TurnNumberLabel extends Panel {
    hideIfOpponent = true

    render({ view }) {
        view
            .text(`Turn ${this.owner.turnNumber}`)
            .textBold(true)
    }
}
globalThis.ALL_FUNCTIONS.push(TurnNumberLabel);