import { DisplaySize } from '../../utils/display-size';
import { Rect } from '../../utils/rect';
import { EntityLayoutInfo } from './entity-layout-info';

export class Entity {
    id = null
    owner = null
    aspectRatio = 1
    itemAspectRatio = 1
    itemCountPerColumn = 1
    minItemCountPerColumn = null
    itemCountPerRow = null
    minItemCountPerRow = null
    horizontalAlign = 0.5
    verticalAlign = 0.5
    outerMargin = 0
    innerMargin = 5
    header = null
    headerSize = 0
    layoutInfo = new EntityLayoutInfo()
    prevLayoutInfo = new EntityLayoutInfo()
    mirrorContainer = null
    destroyed = false
    triggerIndex = 0
    t = 1

    getContainer() {
        return this.layoutInfo?.position?.container;
    }

    getPosition() {
        return this.layoutInfo.position;
    }

    getRect() {
        let mirrorPoint = this.getMirrorPoint();
        let start = this.prevLayoutInfo?.getRect(true, mirrorPoint);
        let end = this.layoutInfo?.getRect(false, mirrorPoint);
        let rect = Rect.mix(start, end, this.t);
        let scale = Math.mix(this.prevLayoutInfo?.scale ?? 1, this.layoutInfo?.scale ?? 1, this.t);

        if (this.t >= 1 && !end) {
            rect = null;
        }

        return rect?.scale(scale);
    }

    getHeaderRect() {
        let rect = this.getRect();
        let headerSize = DisplaySize.resolve(this.headerSize, rect);
        let [headerRect] = rect.splitVertically(headerSize);

        return headerRect;
    }

    getMirrorPoint(client) {
        return null;
    }

    getOrderIndex() {
        return 0;
    }

    getTriggerIndex() {
        return this.getOrderIndex() * 1000 + this.triggerIndex;
    }

    makePlainCopy() {
        // @ts-ignore
        let copy = new this.constructor();

        copy.id = this.id;

        return copy;
    }

    makeCopy() {
        // TODO: make a clone and copy all non-Entity fields

        return this;
    }

    getIndexInContainer() {
        let containerItems = this.getPosition()?.container?.layoutInfo?.items;

        if (!containerItems) {
            return -1;
        }

        return containerItems.indexOf(this);
    }

    canInteractWithStateChange(change) {
        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(Entity);