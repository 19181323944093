import { Color, Rect } from 'vortex';

export const DEFAULT_STATE_CHANGE_DURATION = 0.3;
export const DEFAULT_INCREMENTAL_DELAY = 0.18;
export const ANNOUNCEMENT_DURATION = 1.4;

// export const DEFAULT_STATE_CHANGE_DURATION = 0.1;
// export const ANNOUNCEMENT_DURATION = 0.2;

export const SELF_PLAYER_COLOR = 'dodgerblue';
export const OPPONENT_PLAYER_COLOR = 'tomato';
export const NEUTRAL_PLAYER_COLOR = 'black';
export const SELF_PANEL_COLOR = 'skyblue';
export const OPPONENT_PANEL_COLOR = 'salmon';
export const NEUTRAL_PANEL_COLOR = 'thistle';
export const DISABLED_OVERLAY_COLOR = Color.black().multAlpha(0.3);
export const HOVER_OVERLAY_COLOR = Color.black().multAlpha(0.2);

export const BOARD_MARGIN = 10;
export const DEFAULT_BORDER_RADIUS = 10;
export const HEADER_HEIGHT = 45;

export const DECK_OUTER_MARGIN = 10;
export const DECK_PREVIEW_RATIO = 0.6;

export const CHARACTER_ASPECT_RATIO = 1;
export const CARD_ASPECT_RATIO = 0.8;
export const MINION_ASPECT_RATIO = 1;
export const CARD_IMAGE_ASPECT_RATIO = 2;

export const BOARD_RECT = Rect.fromSize(1600, 900);
export const HAND_HEIGHT = 200;
export const HAND_WIDTH = 800;
export const BATTLEFIELD_WIDTH = 1000;
export const BATTLEFIELD_HEIGHT = 100;
export const RECYCLING_CENTER_WIDTH = 250;
export const RECYCLING_CENTER_HEIGHT = 250;
export const STASH_WIDTH = 250;
export const STASH_HEIGHT = HAND_HEIGHT - BOARD_MARGIN * 2;
export const DECK_WIDTH = 250;
export const DECK_HEIGHT = HAND_HEIGHT;
export const CHARACTER_WIDTH = 120;
export const CHARACTER_HEIGHT = CHARACTER_WIDTH;
export const ACTIVE_CARD_WIDTH = 250;
export const ACTIVE_CARD_HEIGHT = ACTIVE_CARD_WIDTH / CARD_ASPECT_RATIO;
export const EFFECT_LIST_HEIGHT = 60;
export const PLAYER_NAME_PANEL_WIDTH = 250;
export const PLAYER_NAME_PANEL_HEIGHT = 60;
export const CURRENT_STEP_BUTTON_WIDTH = 250;
export const CURRENT_STEP_BUTTON_HEIGHT = 60;
export const STAT_COUNTER_MARGIN = 10;
export const HEALTH_COUNTER_HEIGHT = 32;
export const HEALTH_COUNTER_WIDTH = 85;
export const GOLD_COUNTER_HEIGHT = 30;
export const GOLD_COUNTER_WIDTH = GOLD_COUNTER_HEIGHT * 3;

export const CARD_TOOLTIP_WIDTH = 250;
export const CARD_TOOLTIP_HEIGHT = CARD_TOOLTIP_WIDTH * 1.4;
export const CARD_TOOLTIP_IMAGE_ASPECT_RATIO = 2;

export const TRIGGER_AURA_DURATION = 0.5;
export const TRIGGER_AURA_EASING = 'linear';
export const TRIGGER_AURA_SCALE_START = 1;
export const TRIGGER_AURA_SCALE_END = 1.3;
export const TRIGGER_AURA_ALPHA_START = 1;
export const TRIGGER_AURA_ALPHA_END = 0.4;
export const TRIGGER_AURA_BORDER_WIDTH = 6;

export const AFFECT_AURA_DURATION = 0.6;
export const AFFECT_AURA_EASING = 'linear';
export const AFFECT_AURA_ALPHA_START = 0.8;
export const AFFECT_AURA_TEXT_SIZE = 20;
export const AFFECT_AURA_START_TEXT_OFFSET = 5;
export const AFFECT_AURA_END_TEXT_OFFSET = 15;

export const HISTORY_PANEL_TURN_NUMBER_WIDTH = 100;
export const DELAY_BEFORE_COMBAT = 1;

export const KING_COLOR = '#00A1FF';
export const NECROMANCER_COLOR = '#9B01F2'
export const TACTICIAN_COLOR = '#3AAB58';