import { Hash } from '../utils/hash';
import { TRIANGLE_POINTS, HORIZONTAL_HEXAGON_POINTS, VERTICAL_HEXAGON_POINTS, CURVE_POINTS, LINE_POINTS } from './renderer-constants';
import { ImageLoader } from './image-loader';
import { formatText } from './text-formatting';

export class Renderer {
    constructor(windowManager) {
        this._window = windowManager;
        this._ctx = null;
        this._imageLoader = new ImageLoader();
        this._stringHashes = new Map();
        this._cachedTexts = new Map();
        this._cachedImages = new Map();
    }

    registerImage(url, image) {
        this._imageLoader.register(url, image);
    }

    clearCache() {
        this._stringHashes.clear();
        this._cachedTexts.clear();
        this._cachedImages.clear();
    }

    clear() {
        this._window.clear();
    }

    drawGraphics(graphics, rect) {
        let virtualToRealRatio = this._window.getVirtualToRealRatio();
        let width = rect.width * virtualToRealRatio;
        let height = rect.height * virtualToRealRatio;
        let imageSw = 1;
        let imageSh = 1;
        let imageSx = 0;
        let imageSy = 0;
        let imageUrl = graphics.imageUrl;
        let imageSpriteIndex = graphics.imageSpriteIndex;

        if (imageUrl) {
            let spriteCount = graphics.imageSpriteCountPerRow * graphics.imageSpriteCountPerColumn;

            if (graphics.animationCurrentTime >= 0) {
                let progress = (graphics.animationCurrentTime - graphics.animationStartTime) / graphics.animationDuration;

                imageSpriteIndex = Math.floor(progress * spriteCount);
            }

            if (imageSpriteIndex < 0 || imageSpriteIndex >= spriteCount) {
                imageUrl = null;
            } else {
                imageSw = 1 / graphics.imageSpriteCountPerRow;
                imageSh = 1 / graphics.imageSpriteCountPerColumn;
                imageSx = (imageSpriteIndex % graphics.imageSpriteCountPerRow) * imageSw;
                imageSy = (imageSpriteIndex / graphics.imageSpriteCountPerRow) * imageSh;
            }
        }

        let offsetX = graphics.offsetX.resolve(rect, virtualToRealRatio);
        let offsetY = graphics.offsetY.resolve(rect, virtualToRealRatio);

        let x = rect.x * virtualToRealRatio + offsetX;
        let y = rect.y * virtualToRealRatio + offsetY;
        let z = graphics.zIndex;
        let angle = 0;
        let horizontalAnchor = graphics.horizontalAnchor;
        let verticalAnchor = graphics.verticalAnchor;
        let shape = graphics.shape;
        let borderColor = graphics.borderColor.multAlpha(graphics.borderAlpha);
        let borderWidth = graphics.borderWidth.resolve(rect, virtualToRealRatio);
        let borderScale = graphics.borderScale;
        let borderRadius = graphics.borderRadius.resolve(rect, virtualToRealRatio);
        let borderDashLength = graphics.borderDashLength.resolve(rect, virtualToRealRatio);
        let borderGapLength = graphics.borderGapLength.resolve(rect, virtualToRealRatio);
        let backgroundColor = graphics.backgroundColor.multAlpha(graphics.backgroundAlpha);
        let overlayColor = graphics.overlayColor.multAlpha(graphics.overlayAlpha);
        let imageWidth = width * graphics.imageScale;
        let imageHeight = height * graphics.imageScale;
        let text = graphics.text;
        let textFont = graphics.textFont;
        let textSize = graphics.textSize.resolve(rect, virtualToRealRatio);
        let textColor = graphics.textColor.multAlpha(graphics.textAlpha);
        let textPadding = graphics.textPadding.resolve(rect, virtualToRealRatio);
        let textHorizontalAlign = graphics.textHorizontalAlign;
        let textVerticalAlign = graphics.textVerticalAlign;
        let textBold = graphics.textBold;
        let textItalic = graphics.textItalic;
        let textCursorIndex = graphics.textCursorIndex;
        let textFit = graphics.textFit;
        let textAllowMultiline = graphics.textAllowMultiline;
        let textOffsetX = graphics.textOffsetX;
        let textOffsetY = graphics.textOffsetY;
        let shrinkToFitText = graphics.shrinkToFitText;
        let textFixVerticalCenter = graphics.textFixVerticalCenter;
        let cursor = graphics.cursor;

        let textImage = null;

        if (text) {
            textPadding = textPadding + borderWidth;
            let textMaxWidth = (shrinkToFitText || textFit) ? width : 0;
            let textMaxHeight = (shrinkToFitText || textFit) ? height : 0;
            textImage = this._getTextImageFromCache(text, textMaxWidth, textMaxHeight, textAllowMultiline, textPadding, textSize, textColor, textFont, textBold, textItalic, textCursorIndex, textFixVerticalCenter);

            if (shrinkToFitText) {
                width = textImage.width;
                height = textImage.height;
            }
        }

        let w = width / 2;
        let h = height / 2;

        if (horizontalAnchor === 'left') {
            x += w;
        } else if (horizontalAnchor === 'right') {
            x -= w;
        }

        if (verticalAnchor === 'top') {
            y += h;
        } else if (horizontalAnchor === 'bottom') {
            y -= h
        }

        let x1 = x - w;
        let y1 = y - h;
        let x2 = x + w;
        let y2 = y + h;

        if (x2 < 0 || x1 > this._window.getWidth() || y2 < 0 || y1 > this._window.getHeight()) {
            return;
        }

        this._ctx = this._window.getCanvasContext(z);
        this._ctx.save();

        if (angle) {
            this._ctx.translate(x, y);
            this._ctx.rotate(angle);
            this._ctx.translate(-x, -y);
        }

        if (backgroundColor.a || borderColor.a || overlayColor.a) {
            this._drawShape(shape, x, y, width, height, borderRadius);
            if (shape !== 'line') {
                this._ctx.clip();
            }
        }

        if (backgroundColor.a) {
            this._ctx.fillStyle = backgroundColor.toString();
            this._ctx.fill();
        }

        if (imageUrl) {
            let targetWidth = Math.round(imageWidth / imageSw);
            let targetHeight = Math.round(imageHeight / imageSh);
            let image = this._getImageFromCache(imageUrl, targetWidth, targetHeight);

            if (image && targetWidth && targetHeight) {
                let sx = Math.round(imageSx * targetWidth);
                let sy = Math.round(imageSy * targetHeight);
                let sw = Math.round(imageSw * targetWidth);
                let sh = Math.round(imageSh * targetHeight);
                let imageX = Math.floor(x - sw / 2);
                let imageY = Math.floor(y - sh / 2);

                this._ctx.drawImage(image, sx, sy, sw, sh, imageX, imageY, sw, sh);

                // if (!window.TOTAL) {
                //     window.TOTAL = 0;
                //     window.COUNT = 0;
                //     window.GET_AVERAGE = () => console.log(`${window.TOTAL / window.COUNT}ms`);
                // }
                // let now = performance.now();
                // for (let i = 0; i < 10000; ++i) {
                //     this._ctx.drawImage(image, sx, sy, sw, sh, imageX, imageY, sw, sh);
                //     // this._ctx.drawImage(image, imageX, imageY);
                // }
                // window.COUNT += 10000;
                // window.TOTAL += performance.now() - now;
            }
        }

        if (textImage && textImage.width && textImage.height) {
            let textX = x - textImage.width / 2;
            let textY = y - textImage.height / 2;
            let dx = (width - textImage.width) / 2;
            let dy = (height - textImage.height) / 2;

            if (textHorizontalAlign === 'left') {
                textX -= dx;
            } else if (textHorizontalAlign === 'right') {
                textX += dx;
            }

            if (textVerticalAlign === 'top') {
                textY -= dy;
            } else if (textHorizontalAlign === 'bottom') {
                textY += dy;
            }

            this._ctx.drawImage(textImage, Math.floor(textX + textOffsetX), Math.floor(textY + textOffsetY));
        }

        if (overlayColor.a) {
            this._ctx.fillStyle = overlayColor.toString();
            this._ctx.fill();
        }

        if (borderColor.a && borderWidth) {
            if (borderDashLength && borderGapLength) {
                this._ctx.setLineDash([borderDashLength, borderGapLength]);
            } else {
                this._ctx.setLineDash([]);
            }

            borderWidth *= borderScale;

            if (shape === 'rectangle') {
                borderWidth = Math.ceil(borderWidth);
            }

            let m = shape === 'line' ? 1 : 2;
            let lineWidth = borderWidth * m;

            this._ctx.lineWidth = lineWidth;
            this._ctx.strokeStyle = borderColor.toString();
            this._ctx.stroke();
        }

        this._ctx.restore();

        if (cursor) {
            this._window.setCursor(cursor);
        }
    }

    _getStringHash(string) {
        let hash = this._stringHashes.get(string);

        if (!hash) {
            hash = new Hash().string(string).finish();

            this._stringHashes.set(string, hash);
        }

        return hash;
    }

    _getTextImageFromCache(text, maxWidth, maxHeight, allowMultiline, padding, textSize, textColor, textFont, textBold, textItalic, textCursorIndex, fixVerticalCenter) {
        let hash = new Hash()
            .integer(this._getStringHash(text))
            .integer(maxWidth)
            .integer(maxHeight)
            .integer(padding)
            .integer(textSize)
            .color(textColor)
            .integer(this._getStringHash(textFont))
            .integer(textCursorIndex)
            .finish();

        let image = this._cachedTexts.get(hash);

        if (!image) {
            image = formatText({ text, maxWidth, maxHeight, allowMultiline, padding, textSize, textColor, textFont, textBold, textItalic, textCursorIndex, fixVerticalCenter });
            this._cachedTexts.set(hash, image);
        }

        return image;
    }

    _getImageFromCache(url, targetWidth, targetHeight) {
        let hash = new Hash()
            .integer(this._getStringHash(url))
            .integer(targetWidth)
            .integer(targetHeight)
            .finish()
        let image = this._cachedImages.get(hash);

        if (!image) {
            image = this._imageLoader.get(url);
            image = resizeImage(image, targetWidth, targetHeight);

            this._cachedImages.set(hash, image);
        }

        return image;
    }

    _fill(box, color) {
        if (color) {
            this._ctx.fillStyle = color;
            this._ctx.fillRect(box.x1, box.y1, box.width, box.height);
        }
    }

    _drawShape(shape, x, y, width, height, borderRadius) {
        this._ctx.beginPath();

        if (shape === 'rectangle') {
            let x1 = Math.round(x - width / 2);
            let y1 = Math.round(y - height / 2);
            let x2 = Math.round(x + width / 2);
            let y2 = Math.round(y + height / 2);
            let w = Math.max(x2 - x1, 1);
            let h = Math.max(y2 - y1, 1);
            let r = Math.round(borderRadius);

            if (r === 0) {
                this._ctx.rect(x1, y1, w, h);
            } else {
                this._ctx.moveTo(x1 + r, y1);
                this._ctx.lineTo(x2 - r, y1);
                this._ctx.quadraticCurveTo(x2, y1, x2, y1 + r);
                this._ctx.lineTo(x2, y2 - r);
                this._ctx.quadraticCurveTo(x2, y2, x2 - r, y2);
                this._ctx.lineTo(x1 + r, y2);
                this._ctx.quadraticCurveTo(x1, y2, x1, y2 - r);
                this._ctx.lineTo(x1, y1 + r);
                this._ctx.quadraticCurveTo(x1, y1, x1 + r, y1);
                this._ctx.closePath();
            }
        } else if (shape === 'line') {
            this._polygon(LINE_POINTS, x, y, width, height);
        } else if (shape === 'circle') {
            this._ctx.ellipse(x, y, width / 2, height / 2, 0, 0, Math.PI * 2);
        } else if (shape === 'triangle') {
            this._polygon(TRIANGLE_POINTS, x, y, width, height);
        } else if (shape === 'vertical-hexagon') {
            this._polygon(VERTICAL_HEXAGON_POINTS, x, y, width, height);
        } else if (shape === 'horizontal-hexagon') {
            this._polygon(HORIZONTAL_HEXAGON_POINTS, x, y, width, height);
        } else if (shape === 'curve') {
            this._polygon(CURVE_POINTS, x, y, width, height);
        }
    }

    _polygon(points, x, y, width, height) {
        this._ctx.beginPath();
        this._ctx.moveTo(x + points[0][0] * width, y + points[0][1] * height);

        for (let i = 1; i < points.length; ++i) {
            let [px, py] = points[i];

            this._ctx.lineTo(x + px * width, y + py * height);
        }

        if (points.length > 2) {
            this._ctx.closePath();
        }
    }
}

function resizeImage(image, targetWidth, targetHeight) {
    if (!image || image.width === targetWidth || image.height === targetHeight) {
        return image;
    }

    let widthRatio = image.width / targetWidth;
    let heightRatio = image.height / targetHeight;
    let ratio = Math.max(widthRatio, heightRatio);
    let width = image.width;
    let height = image.height;

    if (ratio >= 2) {
        width /= 2;
        height /= 2;
    } else {
        width = targetWidth;
        height = targetHeight;
    }

    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(image, 0, 0, width, height);

    return resizeImage(canvas, targetWidth, targetHeight);
}
globalThis.ALL_FUNCTIONS.push(Renderer);