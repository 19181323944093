import { Animation } from 'vortex';
import { NEUTRAL_PLAYER_COLOR, TRIGGER_AURA_ALPHA_END, TRIGGER_AURA_ALPHA_START, TRIGGER_AURA_BORDER_WIDTH, TRIGGER_AURA_DURATION, TRIGGER_AURA_EASING, TRIGGER_AURA_SCALE_END, TRIGGER_AURA_SCALE_START } from '../constants/graphics-constants';

export class TriggerAura extends Animation {
    duration = TRIGGER_AURA_DURATION
    easing = TRIGGER_AURA_EASING

    render({ view, client }) {
        let color = NEUTRAL_PLAYER_COLOR;
        let rect = client.getView(this.target).rect;

        if (this.target.owner) {
            color = this.target.owner.getPlayerColor();
        }

        view
            .setRect(rect)
            .scale(Math.mix(TRIGGER_AURA_SCALE_START, TRIGGER_AURA_SCALE_END, this.t))
            .borderColor(color)
            .borderAlpha(Math.mix(TRIGGER_AURA_ALPHA_START, TRIGGER_AURA_ALPHA_END, this.t))
            .borderWidth(TRIGGER_AURA_BORDER_WIDTH)
    }
}
globalThis.ALL_FUNCTIONS.push(TriggerAura);