export class User {
    constructor({ id = null, name = null, selectedGear = {} } = {}) {
        this.id = id;
        this.name = name;
        this.selectedGear = selectedGear;
        this.isSearchingForGame = false;
        this.bot = null;
        this.game = null;
        this.connection = null;
    }

    setConnection(connection) {
        this.connection = connection;
        
        if (connection) {
            connection.user = this;
        }
    }

    isLoggedIn() {
        return !!this.id;
    }

    static getRandomId() {
        return Math.floor(Math.random() * 1000000000) + 1;
    }
}
globalThis.ALL_FUNCTIONS.push(User);