import { OUTPOST_ENV } from 'outpost';
import { formatObject } from './utils/object';
import { loadNodeModules } from './modules';
import { Client } from './client/client';
import { Server } from './server/server';
import { DEFAULT_SERVER_REFRESH_RATE } from './common/constants';
import { DEFAULT_CONSTANTS } from './framework/constants';
import { FrameworkRoot } from './framework/root';
import { Gear } from './framework/gear';

const IS_CLIENT_SIDE = typeof window !== 'undefined';

export class Vortex {
    static async start(options) {
        let parameters = formatObject(options, {
            serverRefreshRate: DEFAULT_SERVER_REFRESH_RATE,
            serverWorld: {},
            clientRoot: FrameworkRoot,
            clientLocalData: {},
            gear: [],
            bots: {},
            game: function() {},
            constants: DEFAULT_CONSTANTS,
            autoMatchmaking: false
        });

        parameters.gear = Gear.from(parameters.gear);

        if (IS_CLIENT_SIDE) {
            // @ts-ignore
            parameters.port = window.OUTPOST_APP_PORT;

            // @ts-ignore
            await (new Client(parameters)).start();
        } else {
            parameters.port = OUTPOST_ENV.APP_PORT;
            parameters.serverHttpRootPath = OUTPOST_ENV.CLIENT_DIR;
            parameters.serverDataPath = OUTPOST_ENV.SERVER_DATA_DIR;

            await loadNodeModules();
            // @ts-ignore
            await (new Server(parameters)).start();
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Vortex);