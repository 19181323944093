import { DisplaySize } from './display-size';
import { BOTTOM_TO_TOP, LayoutItem, LEFT_TO_RIGHT, RIGHT_TO_LEFT, TOP_TO_BOTTOM } from './layout-item';

export class Layout {
    constructor() {
        this._rect = null;
        this._rootList = [];
        this._root = null;
        this._currentContainer = null;
        this._currentItem = null;

        this._initRoot();
    }

    _flush() {
        if (this._root) {
            this._rootList.push(this._root);
        }

        this._root = null;
        this._currentContainer = null;
        this._currentItem = null;

        this._initRoot();
    }

    _initRoot() {
        if (this._root) {
            return;
        }

        this._root = new LayoutItem(null);
        this._root.direction = LEFT_TO_RIGHT;
        this._root.outerMargin = this._rootList.last()?.outerMargin || 0;
        this._currentContainer = this._root;
        this._currentItem = this._root;
    }

    _getCurrentItem() {
        return this._currentItem;
    }

    getRootProperties() {
        let result = {};

        for (let root of this._rootList) {
            Object.assign(result, root.properties);
        }

        Object.assign(result, this._root.properties);

        return result;
    }

    compute(rect) {
        this._flush();

        let result = [];

        for (let root of this._rootList) {
            root.setRect(rect, rect);
            root.collect(result);
        }

        this._rootList = [];
        
        return result;
    }

    push(object = null) {
        if (Array.isArray(object)) {
            for (let item of object) {
                this.push(item);
            }
            return this;
        }

        if (!this._currentContainer) {
            return null;
        }

        let container = this._currentContainer;
        this._currentItem = new LayoutItem(container, object);
        this._currentItem.force = container.childForce;
        this._currentItem.aspectRatio = container.childAspectRatio;
        this._currentItem.width = container.childWidth;
        this._currentItem.height = container.childHeight;

        container.children.push(this._currentItem);

        return this;
    }

    back() {
        if (this._currentContainer) {
            this._currentContainer = this._currentContainer.parent || this._currentContainer;
            this._currentItem = this._currentContainer;
        }

        return this;
    }

    /**
     * 
     * @param {LayoutDirection} direction 
     * @returns 
     */
    direction(direction) {
        this._currentContainer = this._currentItem;
        this._currentItem.direction = direction;
        return this;
    }

    /**
     * 
     * @param {number} alignment 
     * @returns 
     */
    alignment(alignment) {
        this._currentItem.alignment = alignment;
        return this;
    }

    /**
     * 
     * @param {number} subAlignment 
     * @returns 
     */ 
    subAlignment(subAlignment) {
        this._currentItem.subAlignment = subAlignment;
        return this;
    }

    /**
     * 
     * @param {number} force 
     * @returns 
     */
    force(force) {
        let item = this._currentItem;

        item.force = force;
        item.aspectRatio = null;
        item.width = null;
        item.height = null;
        return this;
    }

    /**
    * 
    * @param {DisplaySize} width 
    * @returns 
    */
    width(width) {
        this._currentItem.width = width;
        return this;
    }

    /**
    * 
    * @param {DisplaySize} width 
    * @returns 
    */
    height(height) {
        this._currentItem.height = height;
        return this;
    }

    /**
     * 
     * @param {number} aspectRatio 
     */
    aspectRatio(aspectRatio) {
        this._currentItem.aspectRatio = aspectRatio;
        // TODO: unset width or height
        return this;
    }

    childForce(force) {
        let item = this._currentItem;

        item.childForce = force;
        item.childAspectRatio = null;
        item.childWidth = null;
        item.childHeight = null;
        return this;
    }

    childWidth(width) {
        this._currentContainer.childWidth = width;
        return this;
    }

    childHeight(height) {
        this._currentContainer.childHeight = height;
        return this;
    }

    childAspectRatio(aspectRatio) {
        this._currentContainer.childAspectRatio = aspectRatio;
        // TODO: unset width or height
        return this;
    }

    innerMargin(innerMargin) {
        this._currentContainer.innerMargin = innerMargin;
        return this;
    }

    outerMargin(outerMargin) {
        this._currentContainer.outerMargin = outerMargin;
        return this;
    }

    margin(margin) {
        return this
            .innerMargin(margin)
            .outerMargin(margin)
    }

    reset() {
        this._flush();
        return this;
    }

    _bulkConfig(direction, alignment, subAlignment) {
        return this
            .direction(direction)
            .alignment(alignment)
            .subAlignment(subAlignment)
    }

    centerToRight() { return this._bulkConfig(LEFT_TO_RIGHT, 0.5, 0.5); }
    centerToLeft() { return this._bulkConfig(RIGHT_TO_LEFT, 0.5, 0.5); }
    centerToBottom() { return this._bulkConfig(TOP_TO_BOTTOM, 0.5, 0.5); }
    centerToTop() { return this._bulkConfig(BOTTOM_TO_TOP, 0.5, 0.5); }

    leftToRight() { return this._bulkConfig(LEFT_TO_RIGHT, 0, 0.5); }
    rightToLeft() { return this._bulkConfig(RIGHT_TO_LEFT, 0, 0.5); }
    topToBottom() { return this._bulkConfig(TOP_TO_BOTTOM, 0, 0.5); }
    bottomToTop() { return this._bulkConfig(BOTTOM_TO_TOP, 0, 0.5); }

    topLeftToRight() { return this._bulkConfig(LEFT_TO_RIGHT, 0, 0); }
    bottomLeftToRight() { return this._bulkConfig(LEFT_TO_RIGHT, 0, 1); }
    topRightToLeft() { return this._bulkConfig(RIGHT_TO_LEFT, 0, 0); }
    bottomRightToLeft() { return this._bulkConfig(RIGHT_TO_LEFT, 0, 1); }
    topLeftToBottom() { return this._bulkConfig(TOP_TO_BOTTOM, 0, 0); }
    topRightToBottom() { return this._bulkConfig(TOP_TO_BOTTOM, 0, 1); }
    bottomLeftToTop() { return this._bulkConfig(BOTTOM_TO_TOP, 0, 0); }
    bottomRightToTop() { return this._bulkConfig(BOTTOM_TO_TOP, 0, 1); }
}
globalThis.ALL_FUNCTIONS.push(Layout);