import { DisplaySize } from '../../utils/display-size';
import { LEFT_TO_RIGHT, TOP_TO_BOTTOM } from '../../utils/layout-item';
import { GridHeader } from './grid-header';
import { GridScrollBar } from './grid-scroll-bar';

const EPSILON = 0.000001;

export class Grid {
    constructor(parameters) {
        this.scroll = 0;
        this.maxScroll = 0;
        this.scrolling = false;

        this.aspectRatio = parameters.aspectRatio ?? null;
        this.items = parameters.items ?? [];

        this.itemCountPerRow = parameters.itemCountPerRow ?? null;
        this.itemCountPerColumn = parameters.itemCountPerColumn ?? null;
        this.minItemCountPerRow = parameters.minItemCountPerRow ?? null;
        this.minItemCountPerColumn = parameters.minItemCountPerColumn ?? null;
        this.itemAspectRatio = parameters.itemAspectRatio ?? 1;
        this.margin = parameters.margin ?? null;
        this.outerMargin = parameters.outerMargin ?? null;
        this.innerMargin = parameters.innerMargin ?? null;
        this.horizontalOuterMargin = parameters.horizontalOuterMargin ?? null;
        this.verticalOuterMargin = parameters.verticalOuterMargin ?? null;
        this.horizontalInnerMargin = parameters.horizontalInnerMargin ?? null;
        this.verticalInnerMargin = parameters.verticalInnerMargin ?? null;
        this.horizontalAlign = parameters.horizontalAlign ?? 0.5;
        this.verticalAlign = parameters.verticalAlign ?? 0.5;

        this.headerSize = parameters.headerSize ?? '20%';
        this.title = parameters.title ?? null;
        this.titleSize = parameters.titleSize ?? '50%';
        this.titleColor = parameters.titleColor ?? 'black';
        this.titleFont = parameters.titleFont ?? 'Arial';
        this.direction = parameters.direction ?? TOP_TO_BOTTOM;
        this.allowScroll = parameters.allowScroll ?? true;
        this.scrollBarSize = parameters.scrollBarSize ?? '3%m';
        this.backgroundColor = parameters.backgroundColor ?? null;
        this.borderColor = parameters.borderColor ?? null;
        this.borderWidth = parameters.borderWidth ?? null;
        this.borderRadius = parameters.borderRadius ?? null;

        this._header = new GridHeader({ text: this.title });
        this._scrollBar = new GridScrollBar(this);
    }

    render({ view, client }) {
        view.stripToMatchAspectRatio(this.aspectRatio);

        let parentRect = view.rect;
        let titleSize = DisplaySize.resolve(this.headerSize, parentRect);
        let [titleRect, gridRect] = parentRect.splitVertically(titleSize);

        if (this.title) {
            view.renderChild(this._header, titleRect);
        } else {
            gridRect = parentRect;
        }

        let topToBottom = this.direction === TOP_TO_BOTTOM;
        let itemCount = this.items.length;
        let { cells } = gridRect.computeGridLayout({
            itemCount,
            itemCountPerRow: this.itemCountPerRow,
            itemCountPerColumn: this.itemCountPerColumn,
            minItemCountPerRow: this.minItemCountPerRow,
            minItemCountPerColumn: this.minItemCountPerColumn,
            itemAspectRatio: this.itemAspectRatio,
            margin: getMargin(gridRect, this.margin),
            outerMargin: getMargin(gridRect, this.outerMargin),
            innerMargin: getMargin(gridRect, this.innerMargin),
            horizontalOuterMargin: getMargin(gridRect, this.horizontalOuterMargin),
            verticalOuterMargin: getMargin(gridRect, this.verticalOuterMargin),
            horizontalInnerMargin: getMargin(gridRect, this.horizontalInnerMargin),
            verticalInnerMargin: getMargin(gridRect, this.verticalInnerMargin),
            topToBottom,
            horizontalAlign: this.horizontalAlign,
            verticalAlign: this.verticalAlign,
        });

        for (let i = 0; i < itemCount; ++i) {
            view.renderChild(this.items[i], cells[i]);
        }

        view
            .backgroundColor(this.backgroundColor)
            .borderColor(this.borderColor)
            .borderWidth(this.borderWidth)
            .borderRadius(this.borderRadius)

        // let rowSize = this.itemCountPerRow;
        // let columnSize = this.itemCountPerColumn;
        // let itemCount = Math.max(1, this.items.length);

        // if (!rowSize && !columnSize) {
        //     let gridAspectRatio = view.rect.aspectRatio();
        //     let itemAspectRatio = this.itemAspectRatio || 1;
        //     let ratio = gridAspectRatio / itemAspectRatio;

        //     columnSize = Math.ceil(Math.sqrt(itemCount / ratio));
        //     rowSize = Math.ceil(columnSize * ratio);

        //     if ((Math.abs(columnSize - 1)) < EPSILON) {
        //         rowSize = itemCount;
        //     }
        // } else if (!columnSize) {
        //     columnSize = Math.ceil(itemCount / rowSize);

        //     if (this.minItemCountPerColumn) {
        //         columnSize = Math.max(columnSize, this.minItemCountPerColumn);
        //     }
        // } else if (!rowSize) {
        //     rowSize = Math.ceil(itemCount / columnSize);

        //     if (this.minItemCountPerRow) {
        //         rowSize = Math.max(rowSize, this.minItemCountPerRow);
        //     }
        // }

        // let displayedItemCount = Math.round(rowSize * columnSize);


        // let itemCountPerRow = rowSize;
        // let itemCountPerColumn = columnSize;
        // let itemCountPerStep = itemCountPerRow;

        // if (this.direction === LEFT_TO_RIGHT) {
        //     itemCountPerStep = itemCountPerColumn;
        // }

        // if (this.allowScroll && displayedItemCount < this.items.length) {
        //     let scrollBarWidth = DisplaySize.resolve(this.scrollBarSize, parentRect);
        //     let scrollBarRect = gridRect.stripFromSides(0, 0, 0, gridRect.width - scrollBarWidth);

        //     this._scrollBar.direction = this.direction;
        //     this._scrollBar.stepSize = itemCountPerStep;
        //     this._scrollBar.displayedItemCount = displayedItemCount;
        //     this._scrollBar.totalItemCount = this.items.length;
        //     this._scrollBar.round = true;

        //     view.renderChild(this._scrollBar, scrollBarRect);
        // }

        // let itemRect = new Rect(0, 0, gridRect.width / rowSize, gridRect.height / columnSize)
        //     .stripToMatchAspectRatio(this.itemAspectRatio);

        // let inputHorizontalOuterMargin = this.horizontalOuterMargin ?? this.outerMargin ?? this.margin;
        // let inputVerticalOuterMargin = this.verticalOuterMargin ?? this.outerMargin ?? this.margin;
        // let inputHorizontalInnerMargin = this.horizontalInnerMargin ?? this.innerMargin ?? this.margin;
        // let inputVerticalInnerMargin = this.verticalInnerMargin ?? this.innerMargin ?? this.margin;

        // let horizontalOuterMargin = DisplaySize.resolve(inputHorizontalOuterMargin, itemRect) + EPSILON;
        // let verticalOuterMargin = DisplaySize.resolve(inputVerticalOuterMargin, itemRect) + EPSILON;
        // let horizontalInnerMargin = DisplaySize.resolve(inputHorizontalInnerMargin, itemRect) + EPSILON;
        // let verticalInnerMargin = DisplaySize.resolve(inputVerticalInnerMargin, itemRect) + EPSILON;

        // let maxItemWidth = (gridRect.width - (2 * horizontalOuterMargin) - ((rowSize - 1) * horizontalInnerMargin)) / rowSize;
        // let maxItemHeight = (gridRect.height - (2 * verticalOuterMargin) - ((columnSize - 1) * verticalInnerMargin)) / columnSize;
        // let xStart = gridRect.x1();
        // let yStart = gridRect.y1();
        
        // itemRect = new Rect(0, 0, maxItemWidth, maxItemHeight)
        //     .stripToMatchAspectRatio(this.itemAspectRatio);

        // let finalItemWidth = itemRect.width;
        // let finalItemHeight = itemRect.height;
        // let [additionalHorizontalOuterMargin, additionalHorizontalInnerMargin] = getAdditionalMargins(maxItemWidth, finalItemWidth, rowSize, horizontalOuterMargin, horizontalInnerMargin);
        // let [additionalVerticalOuterMargin, additionalVerticalInnerMargin] = getAdditionalMargins(maxItemHeight, finalItemHeight, columnSize, verticalOuterMargin, verticalInnerMargin);

        // horizontalOuterMargin += additionalHorizontalOuterMargin;
        // horizontalInnerMargin += additionalHorizontalInnerMargin;
        // verticalOuterMargin += additionalVerticalOuterMargin;
        // verticalInnerMargin += additionalVerticalInnerMargin;

        // let horizontal = this.direction === LEFT_TO_RIGHT;
        // let startIndex = itemCountPerStep * this.scroll;
        // let itemsToDisplay = this.items.slice(startIndex, startIndex + displayedItemCount);

        // this.maxScroll = Math.ceil((this.items.length - displayedItemCount) / itemCountPerStep);

        // for (let i = 0; i < itemsToDisplay.length; ++i) {
        //     let item = itemsToDisplay[i];
        //     let xIndex = i % itemCountPerStep;
        //     let yIndex = Math.floor(i / itemCountPerStep);

        //     if (horizontal) {
        //         let tmp = xIndex;
        //         xIndex = yIndex;
        //         yIndex = tmp;
        //     }

        //     let itemX = xStart + horizontalOuterMargin + xIndex * (horizontalInnerMargin + finalItemWidth) + (finalItemWidth / 2);
        //     let itemY = yStart + verticalOuterMargin + yIndex * (verticalInnerMargin + finalItemHeight) + (finalItemHeight / 2);

        //     itemRect = itemRect.setCenter(itemX, itemY);

        //     view.renderChild(item, itemRect);
        // }
    }

    onScroll({ deltaY }) {
        if (!this.allowScroll) {
            return;
        }

        let dy = Math.sign(deltaY);

        this.scroll = Math.clamp(this.scroll + dy, 0, this.maxScroll);

        return true;
    }
}

function getAdditionalMargins(maxItemSize, actualItemSize, itemCount, outerMargin, innerMargin) {
    let totalLeftover = (maxItemSize - actualItemSize) * itemCount;

    if (itemCount === 1) {
        return [totalLeftover / 2, 0];
    }

    let ratio = innerMargin / outerMargin;
    let additionalOuterMargin = totalLeftover / (2 + ratio * (itemCount - 1));
    let additionalInnerMargin = additionalOuterMargin * ratio;

    return [additionalOuterMargin, additionalInnerMargin];

}

function getMargin(rect, margin) {
    if (margin !== null) {
        return DisplaySize.resolve(margin, rect);
    } else {
        return null;
    }
}
globalThis.ALL_FUNCTIONS.push(Grid);