import { CARD_ASPECT_RATIO } from '../constants/graphics-constants';
import { Panel } from './panel';

export class Hand extends Panel {
    itemAspectRatio = CARD_ASPECT_RATIO
    innerMargin = 10
    outerMargin = 10

    cards() {
        return this.layoutInfo.items;
    }

    render({ view }) {
        // view.backgroundColor('white');
    }
}
globalThis.ALL_FUNCTIONS.push(Hand);