import { TitleLabel } from './title-label';
import { UsernameLabel } from './username-label';

export class LobbyHeader {
    render({ view }) {
        view.layout()
            .push(new TitleLabel())
            .reset()
            .rightToLeft()
            .outerMargin(10)
            .push(new UsernameLabel())
            .width('15%w')
            .height('60%h')
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyHeader);