export class SummonLocationIndicator {
    constructor(player, offset, leftSummon) {
        this._player = player;
        this._offset = offset;
        this._leftSummon = leftSummon;
    }

    render({ client, view }) {
        this._shown = false;

        let allMinions = this._player.getMinions();
        let lastMinion = this._leftSummon ? allMinions.first() : allMinions.last();
        let battlefieldRect = client.getView(this._player.battlefield).rect;
        let rectSize = battlefieldRect.height - 2 * this._player.battlefield.outerMargin;
        let rect = null;

        if (!lastMinion) {
            rect = battlefieldRect.setSize(rectSize, rectSize);
        } else {
            let minionRect = client.getView(lastMinion).rect;
            rect = this._leftSummon
                ? minionRect.getLeftNeighbor(minionRect.width, this._player.battlefield.innerMargin)
                : minionRect.getRightNeighbor(minionRect.width, this._player.battlefield.innerMargin)
        }

        for (let i = 0; i < this._offset; ++i) {
            rect = this._leftSummon
                ? rect.getRightNeighbor(rect.width, this._player.battlefield.innerMargin)
                : rect.getLeftNeighbor(rect.width, this._player.battlefield.innerMargin)
        }

        view
            .setRect(rect)
            .borderColor('black')
            .borderWidth(2)
            // .backgroundColor('#DDDDDD')
            .setPointerBehavior('none')
    }
}
globalThis.ALL_FUNCTIONS.push(SummonLocationIndicator);