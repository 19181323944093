export class Button {
    constructor({ text, style, onClick, isDisabled = null }) {
        this._text = text;
        this._style = style;
        this._onClick = onClick;
        this._isDisabled = isDisabled;
    }

    setText(text) {
        this._text = text;
    }

    render({ client, view }) {
        let style = this._style;
        let disabled = !!this._isDisabled?.({ client });

        view
            .addToFocusChain()
            .text(this._text)
            .backgroundColor(style.backgroundColor)
            .textColor(style.textColor)
            .textSize(style.textSize)
            .textFont(style.textFont)
            .borderColor(style.textColor)
            .borderWidth(style.borderWidth)
            .borderRadius(style.borderRadius)
            .focusBorderScale(style.focusBorderScale)
            .textFixVerticalCenter(true)
            .hoverOverlayColor(style.hoverOverlayColor)
            .hoverOverlayAlpha(style.hoverOverlayAlpha)
            .hoverCursor(style.hoverCursor)
            .disabledOverlayColor(style.disabledOverlayColor)
            .disabledOverlayAlpha(style.disabledOverlayAlpha)
            .setDisabled(disabled)
    }

    onClick(evt) {
        this._onClick?.(evt);
    }

    onKeyDown({ client, view, code }) {
        if (view.isFocused() && code == 'Enter') {
            this.onClick({ client, view });
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Button);