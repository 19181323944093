import { Button } from '../widgets/button';
import { TextBox } from '../widgets/textbox';

export class LoginPanel {
    constructor({ constants }) {
        this.loginRequestSent = false
        this.usernameTextbox = new TextBox({
            placeholder: 'Username',
            style: constants.textboxStyle,
            onEnter: (evt) => this._sendLoginRequest(evt)
        });

        this.loginButton = new Button({
            text: 'Log in',
            style: constants.buttonStyle,
            onClick: (evt) => this._sendLoginRequest(evt),
            isDisabled: () => this._getUsername().length === 0
        });
    }

    _getUsername() {
        return this.usernameTextbox.text;
    }

    _sendLoginRequest({ client }, forcedUsername) {
        let username = forcedUsername || this._getUsername();

        if (username.length) {
            return client.sendRequest('login', { username }).then(response => {
                this._onLoginResponse(client, username, response);
                return response;
            });
        }
    }

    _onLoginResponse(client, username, response) {
        if (response) {
            client.setLocalStorageItem('username', username);
        } else {
            client.removeLocalStorageItem('username');
        }
    }

    update({ client }) {
        if (!this.loginRequestSent && !client.user.isLoggedIn()) {
            let storedUsername = client.getLocalStorageItem('username');

            if (storedUsername) {
                let promise = this._sendLoginRequest({ client }, storedUsername);

                client.freezeUntil(promise, (response, client) => !response || client.user.isLoggedIn());
            }
        }

        this.loginRequestSent = true;
    }

    render({ view }) {
        view.layout()
            .centerToBottom()
            .innerMargin(20)
            .childHeight('15%')
            .childAspectRatio(5)
            .push(this.usernameTextbox)
            .push(this.loginButton)
    }
}
globalThis.ALL_FUNCTIONS.push(LoginPanel);