export class Position {
    constructor({ container = null, indexInContainer = null, rect = null }) {
        this.container = container;
        this.indexInContainer = indexInContainer;
        this.rect = rect;
    }

    clone() {
        return new Position({ container: this.container, indexInContainer: this.indexInContainer, rect: this.rect });
    }

    isEmpty() {
        return !this.container && !this.rect;
    }

    static empty() {
        return new Position({});
    }

    static relative(container, indexInContainer = null) {
        if (!container) {
            return null;
        }

        return new Position({ container, indexInContainer });
    }

    static absolute(rect) {
        if (!rect) {
            return null;
        }
        
        return new Position({ rect });
    }
}
globalThis.ALL_FUNCTIONS.push(Position);