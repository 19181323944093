import { HITBOX_INDEX } from '../constants/game-constants';
import { DEFAULT_BORDER_RADIUS } from '../constants/graphics-constants';
import { Panel } from '../panels/panel';

export class Hitbox extends Panel {
    hideIfOpponent = true

    render({ view }) {
        view
            .setPointerBehavior('none')
            .borderRadius(DEFAULT_BORDER_RADIUS)
    }

    getOrderIndex() {
        return HITBOX_INDEX;
    }
}
globalThis.ALL_FUNCTIONS.push(Hitbox);