const REQUIRED_USER_COUNT_PER_GAME = 2;

export class Matchmaking {
    constructor() {
        this._entries = [];
    }

    async init() {
        
    }

    add(users) {
        if (!Array.isArray(users)) {
            users = [users];
        }

        for (let entry of this._entries) {
            if (entry.users.length + users.length <= REQUIRED_USER_COUNT_PER_GAME) {
                entry.users.append(users);
                return;
            }
        }

        this._entries.push({
            users
        });
    }

    extractGroups() {
        let result = this._entries
            .filter(entry => entry.users.length >= REQUIRED_USER_COUNT_PER_GAME)
            .map(entry => entry.users);

        this._entries = this._entries.filter(entry => entry.users.length < REQUIRED_USER_COUNT_PER_GAME)

        return result;
    }

    remove(user) {
        for (let entry of this._entries) {
            entry.users.remove(user);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Matchmaking);