export class GameLog {
    turns = []

    getCurrentTurn() {
        return this.turns.last();
    }

    pushNewTurn() {
        this.turns.push([]);
    }

    recordAction(type, target) {
        let turn = this.getCurrentTurn();

        if (turn) {
            turn.push({ type, target });
        }
    }

    getActionTargetsForCurrentTurn(type, filterCallback = ((target) => true)) {
        return this.getCurrentTurn().filter(action => action.type === type && filterCallback(action.target)).map(action => action.target.makePlainCopy());
    }
}
globalThis.ALL_FUNCTIONS.push(GameLog);