import { applyEasing } from '../../utils/easing';
import { instanciate } from '../../utils/object';
import { Rect } from '../../utils/rect';
import { Entity } from './entity';
import { Position } from './position';

const EPSILON = 0.00000001;

export class BoardStateChange {
    constructor(board) {
        this._board = board;
        this._changes = new Map();
        this._animations = [];
        this._callbacks = [];

        this._containersToUpdate = new Map();
        this._entitiesToUpdate = [];
        this._duration = 0;
        this._easing = 'linear';
        this._additionalDurationFromDelays = 0;
        this._next = null;
        this._elapsed = 0;
        this._updateOpponent = true;

        this.tag = null;
    }

    _getEntityChange(entity) {
        if (!(entity instanceof Entity)) {
            console.error(entity);
            throw new Error(`not an entity`);
        }
        return this._changes.getOrInsertWith(entity, () => new EntityStateChange(entity));
    }

    _changeProperty(entity, name, callback) {
        let entityChange = this._getEntityChange(entity);
        let propertyChange = entityChange.propertyChanges[name];

        if (!propertyChange) {
            propertyChange = new PropertyChange();
            entityChange.propertyChanges[name] = propertyChange;
        }

        callback(propertyChange);

        return this;
    }

    _addEntityChange(entity, callback) {
        let change = this._getEntityChange(entity);

        callback(change);
    }

    // getChain() {
    //     let chain = [];
    //     let current = this;

    //     while (current) {
    //         chain.push(current);
    //         // @ts-ignore
    //         current = current._next;
    //     }

    //     return chain;
    // }

    getLast() {
        if (!this._next) {
            return this;
        } else {
            return this._next.getLast();
        }
    }

    _then() {
        this._next = new BoardStateChange(this._board);
        this._next.tag = this.tag;

        return this._next;
    }

    _withLast(callback) {
        let last = this.getLast();

        callback(last);

        return last;
    }

    then(delay = 0) {
        let last = this.getLast()._then();

        if (delay > 0) {
            last._duration = delay;
            last = last._then();
        }

        return last;
    }

    do(callback) {
        return this._withLast(self => self._callbacks.push(callback));
    }

    setDuration(duration) {
        return this._withLast(self => self._duration = duration);
    }

    setEasing(easing) {
        return this._withLast(self => self._easing = easing);
    }

    setDelay(entities, startingDelay, incrementalDelay) {
        if (!Array.isArray(entities)) {
            entities = [entities];
        }

        return this._withLast(self => {
            let delay = startingDelay;

            for (let entity of entities) {
                let change = self._changes.get(entity);

                if (change) {
                    change.delay = delay;
                }

                delay += incrementalDelay;
            }

            self._additionalDurationFromDelays = Math.max(self._additionalDurationFromDelays, incrementalDelay * (entities.length - 1));
        });
    }

    updateOpponent(value) {
        return this._withLast(self => self._updateOpponent = value);
    }

    addAnimation(animation, entity) {
        animation = instanciate(animation);

        if (entity !== undefined) {
            animation.target = entity;
        }

        return this._withLast(self => {
            self._animations.push(animation);

            if (animation.duration !== undefined) {
                self._duration = animation.duration;
            }

            if (animation.easing !== undefined) {
                self._easing = animation.easing;
            }
        });
    }

    process(entity, callback) {
        return this._withLast(self => {
            self._addEntityChange(entity, change => change.processFunctions.push(callback));
        });
    }

    spawn(entity, owner = null, position = null) {
        if (position && position instanceof Rect) {
            position = Position.absolute(position);
        }

        return this._withLast(self => {
            self._addEntityChange(entity, change => {
                change.spawn = true;
                change.spawnOwner = owner;
                change.spawnPosition = position;
            });
        });
    }

    despawn(entity) {
        return this._withLast(self => self._addEntityChange(entity, change => change.despawn = true));
    }

    setOwner(entity, owner) {
        return this._withLast(self => self._addEntityChange(entity, change => change.owner = owner));
    }

    scale(entity, startScale, endScale) {
        return this._withLast(self => self._addEntityChange(entity, change => {
            change.doScale = true;
            change.startScale = startScale;
            change.endScale = endScale;
        }));
    }

    move(entity, position, indexInContainer = null) {
        let finalPosition = position;

        if (position) {
            if (position instanceof Entity) {
                finalPosition = Position.relative(position, indexInContainer)
            } else if (position instanceof Rect) {
                finalPosition = Position.absolute(position);
            }
        } else {
            finalPosition = Position.empty();
        }

        return this._withLast(self => self._addEntityChange(entity, change => {
            change.spawn = true;
            change.position = finalPosition;
        }));
    }

    defragment(entity) {
        return this._withLast(self => self._addEntityChange(entity, change => change.defragment = true));
    }

    addToProperty(entity, name, value) {
        return this._withLast(self => self._changeProperty(entity, name, change => change.add += value));
    }

    multiplyProperty(entity, name, value) {
        return this._withLast(self => self._changeProperty(entity, name, change => change.mult *= value));
    }

    multiplyIntProperty(entity, name, value) {
        return this._withLast(self => self._changeProperty(entity, name, change => {
            change.mult *= value;
            change.floorAfterMult = true;
        }));
    }

    setProperty(entity, name, value) {
        return this._withLast(self => self._changeProperty(entity, name, change => change.set = value));
    }

    _getContainerChange(container) {
        return this._containersToUpdate.getOrInsertWith(container, () => new ContainerChange(this._board, container));
    }

    onStart() {
        for (let callback of this._callbacks) {
            callback();
        }

        for (let change of this._changes.values()) {
            if (change.spawn && !this._board.entities.has(change.entity)) {
                let id = this._board.nextEntityId++;

                this._board.entities.add(change.entity);

                change.entity.id = id;
                change.entity.owner = change.spawnOwner;
                change.entity.layoutInfo.position = change.spawnPosition;
            }
        }

        if (this._updateOpponent && this._board.opponent) {
            this._entitiesToUpdate = this._board.getAllEntities();
        } else {
            this._entitiesToUpdate = Array.from(this._board.entities);
        }

        // for (let entity of this._board.entities) {
        //     entity.prevLayoutInfo = entity.layoutInfo;
        //     entity.layoutInfo = entity.prevLayoutInfo?.clone();
        // }

        for (let entity of this._entitiesToUpdate) {
            entity.prevLayoutInfo = entity.layoutInfo;
            entity.layoutInfo = entity.prevLayoutInfo?.clone();
            entity.t = 0;
        }

        for (let change of this._changes.values()) {
            let entity = change.entity;

            if (change.despawn) {
                let currentContainer = entity.getContainer();
                
                if (currentContainer) {
                    let containerChange = this._getContainerChange(currentContainer);
                    containerChange.removedEntities.push(entity);
                }
            } else if (change.position) {
                let currentContainer = entity.getContainer();
                let newContainer = change.position.container;

                if (currentContainer) {
                    let containerChange = this._getContainerChange(currentContainer);
                    containerChange.removedEntities.push(entity);
                }

                if (newContainer) {
                    let containerChange = this._getContainerChange(newContainer);
                    containerChange.addedEntities.push(entity);
                }

                entity.layoutInfo.position = change.position;
            }

            if (change.doScale) {
                entity.prevLayoutInfo.scale = change.startScale ?? entity.prevLayoutInfo.scale;
                entity.layoutInfo.scale = change.endScale ?? entity.prevLayoutInfo.scale;
            }

            if (change.defragment) {
                let containerChange = this._getContainerChange(entity);
                containerChange.defragment = true;
            }

            for (let key in change.propertyChanges) {
                let value = entity[key];
                let propertyChange = change.propertyChanges[key];

                if (propertyChange.set !== null) {
                    value = change.set;
                }

                value += propertyChange.add;
                value *= propertyChange.mult;

                if (propertyChange.floorAfterMult) {
                    value = Math.floor(value);
                }

                value = Math.min(value, propertyChange.max);
                value = Math.max(value, propertyChange.min);
                
                entity[key] = value;
            }

            if (change.owner) {
                entity.owner = change.owner;
            }

            for (let callback of change.processFunctions) {
                callback(entity);
            }
        }

        for (let change of this._containersToUpdate.values()) {
            for (let entity of change.removedEntities) {
                change.entities.delete(entity);
            }

            for (let entity of change.addedEntities) {
                change.entities.add(entity);
            }

            let maxIndex = -1;

            for (let entity of change.entities) {
                let index = entity.getPosition().indexInContainer;

                if (index !== null) {
                    maxIndex = Math.max(maxIndex, index);
                }
            }

            for (let entity of change.entities) {
                let position = entity.getPosition();

                if (position.indexInContainer === null) {
                    position.indexInContainer = maxIndex + 1;
                    maxIndex += 1;
                }
            }

            let sortedEntities = Array.from(change.entities).sort((e1, e2) => e1.getPosition().indexInContainer - e2.getPosition().indexInContainer);

            if (change.defragment) {
                for (let i = 0; i < sortedEntities.length; ++i) {
                    let entity = sortedEntities[i];
                    let position = entity.getPosition();

                    position.indexInContainer = i;
                }

                maxIndex = sortedEntities.length - 1;
            }

            change.container.layoutInfo.items = sortedEntities;

            let newItemCount = maxIndex + 1;

            if (change.defragment || newItemCount > change.container.layoutInfo.itemCount) {
                change.container.layoutInfo.itemCount = newItemCount;
            }
        }

        for (let change of this._changes.values()) {
            if (change.despawn && change.position) {
                change.entity.layoutInfo.position = change.position;
            }
        }

        let sortedEntities = Array.from(this._board.entities).sort((e1, e2) => {
            return (e1.getTriggerIndex?.() || 0) - (e2.getTriggerIndex?.() || 0)
        });

        this._board.entities = new Set(sortedEntities);

        for (let animation of this._animations) {
            animation.t = 0;
        }

        this._board.animations = this._animations;
    }

    onProgress(elapsed) {
        this._elapsed += elapsed;

        let duration = this._duration;
        let easing = this._easing;
        let totalDuration = this._duration + this._additionalDurationFromDelays;
        let ratio = this._elapsed / totalDuration;

        for (let entity of this._entitiesToUpdate) {
            let delay = this._changes.get(entity)?.delay || 0;
            let rawT = Math.clamp((this._elapsed - delay + EPSILON) / (duration + EPSILON), 0, 1);
            let t = applyEasing(easing, rawT);

            entity.t = t;
        }

        for (let animation of this._animations) {
            animation.t = ratio;
            animation.elapsed = this._elapsed;
        }

        return this._elapsed - totalDuration;
    }

    onEnd() {
        for (let entity of this._entitiesToUpdate) {
            entity.t = 1;
        }

        for (let change of this._changes.values()) {
            let entity = change.entity;

            if (change.despawn) {
                this._board.entities.delete(entity);
            }
        }

        this._board.animations = [];

        return this._next;
    }
}

class EntityStateChange {
    constructor(entity) {
        this.entity = entity;
        this.propertyChanges = {};
        this.position = null;
        this.defragment = false;
        this.clear = false;
        this.spawn = false;
        this.spawnPosition = null;
        this.spawnOwner = null;
        this.despawn = false;
        this.owner = null;
        this.doScale = false;
        this.startScale = null;
        this.encScale = null;
        this.processFunctions = [];
        this.delay = 0;
    }
}

class PropertyChange {
    constructor() {
        this.add = 0;
        this.mult = 1;
        this.set = null;
        this.min = -Infinity;
        this.max = Infinity;
        this.floorAfterMult = false;
    }
}

class ContainerChange {
    constructor(board, container) {
        this.container = container;
        this.entities = new Set(Array.from(board.entities).filter(entity => entity.getContainer() === container));
        this.removedEntities = [];
        this.addedEntities = [];
        this.defragment = false;
    }
}
globalThis.ALL_FUNCTIONS.push(BoardStateChange);
globalThis.ALL_FUNCTIONS.push(EntityStateChange);
globalThis.ALL_FUNCTIONS.push(PropertyChange);
globalThis.ALL_FUNCTIONS.push(ContainerChange);