const EASING = {
    'linear': t => t,
    'quadratic-in': (t, u) => t * t,
    'quadratic-out': (t, u) => 1 - u * u,
    'quadratic-in-out': (t, u) => t < 0.5 ? t * t : 1 - u * u,
    'cubic-in': (t, u) => t * t * t,
    'cubic-out': (t, u) => 1 - u * u * u,
    'cubic-in-out': (t, u) => t < 0.5 ? t * t * t : 1 - u * u * u,
}

export function applyEasing(easing, t) {
    let u = 1 - t;
    let func = EASING[easing] || EASING.linear;

    return func(t, u);
}