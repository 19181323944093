import { GameScreen } from './game/game-screen';
import { LobbyScreen } from './lobby/lobby-screen';
import { LoginScreen } from './lobby/login-screen';
import { Tooltip } from './tooltip';
import { MessageBox } from './widgets/message-box';

export class FrameworkRoot {
    constructor(parameters) {
        this._parameters = parameters;

        this.tooltip = new Tooltip();
        this.loginScreen = new LoginScreen(parameters);
        this.lobbyScreen = new LobbyScreen(parameters);
        this.gameScreen = new GameScreen(parameters);
        this.serverMessages = [];

        this._matchmakingSent = false;
    }

    render({ client, view }) {
        let screen = null;

        if (client.game) {
            screen = this.gameScreen;
        } else if (client.user.isLoggedIn()) {
            screen = this.lobbyScreen;
            this.loginScreen.loginPanel.usernameTextbox.clear();
        } else {
            screen = this.loginScreen;
        }

        view.renderChild(screen);
        view.renderChild(this.tooltip);

        this.serverMessages = this.serverMessages.filter(message => !message.acknowledged);

        for (let message of this.serverMessages) {
            view.renderChild(message);
        }
    }

    onKeyDown({ client, code, shiftKey }) {
        if (code === 'Tab') {
            if (shiftKey) {
                client.focusPrev();
            } else {
                client.focusNext();
            }
        }
    }

    $showMessage({ kind, content }) {
        this.serverMessages.push(new MessageBox(this._parameters, { kind, content }));
    }
}
globalThis.ALL_FUNCTIONS.push(FrameworkRoot);