export class EventEmitter {
    constructor({ triggerOwnHandler = true } = {}) {
        this._constructorToCallback = new Map();
        this._triggerOwnHandler = triggerOwnHandler;
    }

    emitEvent(entities, eventName, payload, isValidTarget, makeOutput) {
        let result = [];

        for (let entity of entities) {
            if (!isValidTarget(entity, payload)) {
                continue;
            }

            let eventHandlers = this._getEventHandlersForClass(entity, eventName);

            for (let handler of eventHandlers) {
                let output = makeOutput();

                handler.call(entity, payload, output);
                result.push(output);
            }

            if (this._triggerOwnHandler) {
                let descriptor = Object.getOwnPropertyDescriptor(entity, eventName);

                if (descriptor) {
                    let output = makeOutput();

                    descriptor.value.call(entity, payload, output);
                    result.push(output);
                }
            }
        }

        return result;
    }

    _getEventHandlersForClass(object, eventName) {
        let eventNameToList = this._constructorToCallback.getOrInsertWith(object.constructor, () => ({}));
        let list = eventNameToList[eventName];

        if (!list) {
            list = [];
            let constructor = object.constructor;

            while (constructor.prototype) {
                let descriptor = Object.getOwnPropertyDescriptor(constructor.prototype, eventName);

                if (descriptor && typeof descriptor.value === 'function') {
                    list.push(descriptor.value);
                }

                constructor = Object.getPrototypeOf(constructor);
            }

            list.reverse();

            eventNameToList[eventName] = list;
        }

        return list;
    }
}
globalThis.ALL_FUNCTIONS.push(EventEmitter);