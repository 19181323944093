export class GameInputManager {
    constructor(players, game, { steps, sync, isGameFinished }) {
        this._players = players;
        this._game = game;
        this._steps = steps;
        this._sync = sync;
        this._isGameFinished = isGameFinished;
        this._completeInputHistory = [];
        this._currentStepId = null;
        this._currentStep = null;
        this._lastUpdateDone = false;
    }

    start(gameStep) {
        this._setCurrentStep(gameStep || Object.keys(this._steps)[0]);
    }

    _setCurrentStep(stepId) {
        this._currentStepId = stepId;
        this._currentStep = this._steps[stepId];
        this._completeInputHistory.push(new Map())

        this._currentStep.onStart?.(this._game);
    }

    _getPlayerCurrentStepInputs(userId) {
        let current = this._completeInputHistory.last();

        return current.getOrInsertWith(userId, () => []);
    }

    _isPlayerActive(userId) {
        return !this._isGameFinished(this._game) && this._currentStep.isPlayerActive(this._game, userId);
    }

    _isDelayedSync() {
        return this._currentStep.delayedSync ?? true;
    }

    _getInputMethod(name) {
        return this._currentStep.api[name];
    }

    _getNextStepId() {
        let stepId = this._currentStep.getNextStepId?.(this._game);

        if (!stepId) {
            let keys = Object.keys(this._steps);
            let index = keys.indexOf(this._currentStepId);

            stepId = keys[(index + 1) % keys.length];
        }

        return stepId;
    }

    processInput(input) {
        let { userId, type, data = {} } = input;

        if (this._isGameFinished(this._game)) {
            return { error: 'Game finished.' };
        }

        if (type === '#sync') {
            this._sync(this._game, this._getSyncEvent());
            return;
        }

        if (!this._isPlayerActive(userId)) {
            return { error: 'Not your turn.' };
        }

        let method = this._getInputMethod(type);

        if (!method) {
            return { error: 'Invalid game input.' };
        }

        let result = method(this._game, userId, data) ?? {};

        if (result.error) {
            return result;
        }

        if (userId) {
            this._getPlayerCurrentStepInputs(userId).push(input);
        }

        result = { gameInputs: {} };

        if (!this._isDelayedSync()) {
            for (let { userId } of this._players) {
                result.gameInputs[userId] = input;
            }
        } else {
            result.gameInputs[userId] = input;
        }

        return result;
    }

    update(server) {
        if (this._isGameFinished(this._game)) {
            return;
        }

        let stepFinished = this._players.every(({ userId }) => !this._isPlayerActive(userId));

        if (stepFinished) {
            if (server && this._isDelayedSync()) {
                let inputsToSend = {};

                for (let { userId } of this._players) {
                    let inputList = [];

                    for (let { userId: otherUserId } of this._players) {
                        if (userId === otherUserId) {
                            continue;
                        }

                        let playerInputs = this._getPlayerCurrentStepInputs(otherUserId);

                        inputList.append(playerInputs);
                    }

                    inputsToSend[userId] = inputList;
                }

                server.sendGameInputs(inputsToSend);
            }

            this._currentStep.onEnd?.(this._game);

            let nextStepId = this._getNextStepId();

            this._setCurrentStep(nextStepId);
        }
    }

    getCompleteInputHistory(userId) {
        let inputList = [];
        let ids = this._players.map(({ userId }) => userId).sort((id1, id2) => +(id1 !== userId) - +(id2 !== userId));
        let excludeLast = this._isDelayedSync();

        for (let i = 0; i < this._completeInputHistory.length; ++i) {
            let playerToInputs = this._completeInputHistory[i];
            let isLast = i === this._completeInputHistory.length - 1;

            for (let id of ids) {
                if (!excludeLast || !isLast || id === userId) {
                    inputList.append(playerToInputs.get(id) || []);
                }
            }

            if (!isLast) {
                inputList.push({ userId: null, type: '#sync' });
            }
        }

        return inputList;
    }

    _getSyncEvent() {
        return {
            onStart: () => this.update(null)
        };
    }
}
globalThis.ALL_FUNCTIONS.push(GameInputManager);