import { Entity } from 'vortex';
import { CardTooltip } from '../card-tooltip';
import { STARTING_CHARACTER_HEALTH } from '../constants/game-constants';
import { EffectList } from '../panels/effect-list';
import { Hand } from '../panels/hand';
import { getColorFromString } from '../utils';

export class Character extends Entity {
    name = "<character>"
    description = "|-|"
    color = null
    health = STARTING_CHARACTER_HEALTH
    hand = new Hand()
    upgradeList = new EffectList()
    cards = []
    availableUpgradeCards = []
    availableEmblemCards = []

    static is(value) {
        return value && value instanceof Character;
    }

    _generateEmptyFields() {
        if (!this.color) {
            this.color = getColorFromString(this.name);
        }
    }

    render({ view }) {
        this._generateEmptyFields();
        
        view
            .backgroundColor(this.color)
            .borderColor('black')
            .borderWidth(1.5)
            .borderRadius('10%')
        
        view.layout()
            .bottomToTop()
            .push()
            .height('40%')
            .push()
            .height('20%')
            .text(this.name)
            .textFit(true)
            .textPadding(3)
            // .backgroundColor('yellow')
        
        view.layout()
            .bottomToTop()
            .outerMargin('5%')
            .push()
            .height('25%')
            .aspectRatio(2.8)
            .backgroundColor('white')
            // .textHorizontalAlign('right')
            .text(`${this.health} @1.1{@red{❤}}`)
            .textSize('75%')
            .textOffsetY(1)
            .borderRadius('50%')
            .borderColor('black')
            .borderWidth('1r')
    }

    getTooltip() {
        return new CardTooltip({
            title: this.name,
            image: this,
            description: this.description,
            // bottomList: [ ...this.cards, ...this.availableUpgradeCards, ...this.availableEmblemCards ]
            bottomList: this.owner ? [] : [ ...this.cards ]
        });
    }

    onStateChange(change, output) {
        if (change.turnStart) {
            output.giveCard(this.cards.map(card => card.makePlainCopy()), this);

            if (this.owner.activeCharacter !== this || this.owner.isOpponent) {
                output.makeInstant();
            }
        } else if (change.combatStart) {
            output
                .discard(this.hand.cards())
                .makeInstant()
        }
    }

    canInteractWithStateChange(change) {
        return !!this.getContainer();
    }

    onDeath() {

    }
}
globalThis.ALL_FUNCTIONS.push(Character);