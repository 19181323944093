import { makeMinionCard } from '../../cards/make-minion';

export const GeneralCard = makeMinionCard({
    name: 'General',
    description: 'Combat start: give all weaker minions +1 power.',
    power: 4,
    goldCost: 2,

    onStateChange(change, output) {
        if (change.combatStart) {
            let minions = this.owner.getMinions().filter(minion => minion.power < this.power);

            output
                .setSource(this)
                .increasePower(minions, 1);
        }
    }
})