import { DEFAULT_STATE_CHANGE_DURATION, TACTICIAN_COLOR } from '../../constants/graphics-constants'
import { makeCharacter } from '../make-character';
import { ReversalCard } from './reversal';

export const TacticianCharacter = makeCharacter({
    id: 'tactician',
    name: 'Tactician',
    description: `When this character dies, gain 8 gold.`,
    color: TACTICIAN_COLOR,

    cards: [
        {
            name: 'Scout',
            goldCost: 1,
            power: 5,
            description: `Combat start: move this minion and its right neighbor to the right of the battlefield.`,
            onStateChange(change, output) {
                if (change.combatStart) {
                    let minions = this.owner.getMinions();
                    let rightNeighbor = this.getNeighbor(1);

                    if (minions.length <= 1) {
                        return;
                    }

                    if (minions.remove(this)) {
                        minions.push(this);
                        if (minions.remove(rightNeighbor)) {
                            minions.push(rightNeighbor);
                        }
                    }

                    output
                        .setSource(this)
                        .setMinionsOrder(minions)
                }
            }
        }, {
            name: 'Super archer',
            goldCost: 1,
            power: 4,
            description: `This minion deals +50% damages to players (rounded up).`,
            playerDamageModifier: 1.5,
        }, {
            name: 'Pincer strategy',
            goldCost: 1,
            description: `This turn, your minions are summoned on the left.`,
            triggerEffect(output) {
                output.do(() => this.owner.leftSummonThisTurn = true);
            },
            effectOnStateChange(change, output) {
                if (change.turnStart) {
                    output.consumeEffect(this);
                }
            }
        }, {
            name: 'Elite mercenary',
            goldCost: 2,
            power: 6,
            description: `This minion doesn't take damages from weaker minions.`,
            immuneToWeakerMinions: true,
        }, {
            name: 'All-out attack',
            goldCost: 3,
            description: `This turn, combat triggers an additional time.`,
            effectOnStateChange(change, output) {
                if (change.combatEnd) {
                    output
                        .setSource(this)
                        .do(() => this.owner.additionalCombatCountThisTurn += 1)
                        .consumeEffect(this)
                }
            }
        }
    ],

    upgrades: [
        {
            description: `Get a "Reversal" each turn (starting now).`,
            applyUpgrade(character, output) {
                if (!character.cards.find(card => card.constructor === ReversalCard)) {
                    character.cards.push(new ReversalCard());
                }
            },
            onStateChange(change, output) {
                if (change.createdEffects.includes(this)) {
                    output
                        .giveCard(new ReversalCard())
                        .setDuration(DEFAULT_STATE_CHANGE_DURATION)
                }
            }
        }, {
            description: `After you play a non-common spell, give your rightmost minion +3 power.`,
            onAfterStateChange(change, output) {
                if (change.playedCard && !change.playedCard.summonedMinion && !change.playedCard.isCommon) {
                    let minion = this.owner.getMinions().last();

                    if (minion) {
                        output
                            .setSource(this)
                            .increasePower(minion, 3);
                    }
                }
            }
        }, {
            description: `After you summon a minion, set its neighbors' powers to their original value (only if it is to your advantage).`,
            onStateChange(change, output) {
                if (change.summonedMinions.length > 0) {
                    let neighbors = new Set();

                    for (let minion of change.summonedMinions) {
                        let left = minion.getNeighbor(-1);
                        let right = minion.getNeighbor(1);

                        if (left && !change.summonedMinions.includes(left)) {
                            neighbors.add(left)
                        }

                        if (right && !change.summonedMinions.includes(right)) {
                            neighbors.add(right);
                        }
                    }

                    if (neighbors.size > 0) {
                        let affectedCount = 0;

                        for (let minion of neighbors)  {
                            let originalPower = minion.makePlainCopy().power;

                            if (originalPower > minion.power) {
                                affectedCount += 1;
                                output.setPower(minion, originalPower);
                            }
                        }

                        if (affectedCount > 0) {
                            output.setSource(this);
                        }
                    }
                }
            }
        }
    ],

    emblems: [
        {
            description: `After combat, give all your minions +2 power.`,
            onStateChange(change, output) {
                if (change.combatEnd) {
                    output
                        .setSource(this)
                        .increasePower(this.owner.getMinions(), 2)
                }
            }
        }
    ],

    onDeath() {
        this.owner.emitStateChange()
            .setSource(this)
            .giveGold(8, true)
    }
});