import { HOVER_OVERLAY_COLOR } from '../constants/graphics-constants';
import { Panel } from './panel';

export class ExitButton extends Panel {
    hideIfOpponent = true

    render({ view }) {
        let text = this.owner.isFinished() ? 'Exit game' : 'Forfeit';

        view
            .text(text)
            .textSize('50%')
            .borderRadius('20%')
            .borderWidth('2r')
            .borderColor('black')
            .backgroundColor('white')
            .hoverCursor('pointer')
            .hoverOverlayColor(HOVER_OVERLAY_COLOR)
    }

    onClick({ client }) {
        if (this.owner.isFinished()) {
            client.sendRequest('exitGame');
        } else {
            client.sendGameInput('forfeit');
        }
    }
}
globalThis.ALL_FUNCTIONS.push(ExitButton);