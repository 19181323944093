Array.prototype.isEmpty = function() {
    return this.length === 0;
};

Array.prototype.first = function() {
    return this[0];
};

Array.prototype.last = function() {
    return this[this.length - 1];
};

Array.prototype.clear = function() {
    this.length = 0;
};

Array.prototype.filterMap = function(callback) {
    let result = [];
    
    for (let i = 0; i < this.length; ++i) {
        let item = this[i];
        let mapped = callback(item, i);

        if (mapped) {
            result.push(mapped);
        }
    }

    return result;
};

Array.prototype.remove = function(item) {
    let index = this.indexOf(item);

    if (index !== -1) {
        this.splice(index, 1);
        return true;
    } else {
        return false;
    }
};

Array.prototype.clone = function() {
    return this.slice();
};

Array.prototype.append = function(items) {
    for (let item of items) {
        this.push(item);
    }
};

Array.prototype.pushOrAppend = function(items) {
    if (Array.isArray(items)) {
        this.append(items);
    } else {
        this.push(items);
    }
};

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
Array.prototype.shuffle = function(randFunction) {
    let length = this.length;
    let currentIndex = length;

    while (currentIndex > 0) {
        let randomIndex = randFunction(0, currentIndex - 1);
        currentIndex -= 1;

        let tmp = this[currentIndex];
        this[currentIndex] = this[randomIndex];
        this[randomIndex] = tmp;
    }

    return this;
};