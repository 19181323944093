import { Panel } from './panel';

export class NamePanel extends Panel {
    render({ view }) {
        let color = this.owner.getPlayerColor();

        view
            .backgroundColor('wheat')
            .borderColor('tan')
            .borderRadius('50%')
            .borderWidth(2)
            .text(this.owner.name)
            .textSize('60%')
            .textColor(color)
    }
}
globalThis.ALL_FUNCTIONS.push(NamePanel);