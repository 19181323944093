import { Card } from '../../entities/card';

export class ReplayCard extends Card {
    name = 'Replay'
    description = `Refresh your active character's cards.`
    isCommon = true
    goldCost = 3

    triggerEffect(output) {
        output
            .discard(this.owner.activeCharacter.hand.cards())
            .makeInstant()
            .then()
            .giveCard(this.owner.activeCharacter.cards.map(card => card.makePlainCopy()))
    }
}
globalThis.ALL_FUNCTIONS.push(ReplayCard);