import { NECROMANCER_COLOR } from '../../constants/graphics-constants'
import { Character } from '../../entities/character'
import { Minion } from '../../entities/minion'
import { makeCharacter } from '../make-character'

class OmegaSkeleton extends Minion {
    name = 'Skeleton'
    power = 2
}

class EmblemSkeleton extends Minion {
    name = 'Skeleton'
    power = 3
}

export const NecromancerCharacter = makeCharacter({
    id: 'necromancer',
    name: 'Necromancer',
    description: 'When this character dies, destroy all minions.',
    color: NECROMANCER_COLOR,

    cards: [
        {
            name: 'Sleleton',
            tags: ['skeleton'],
            goldCost: 1,
            power: 1,
            summonedCount: 3,
        }, {
            name: 'Golem alpha',
            goldCost: 1,
            sacrificeCost: 1,
            power: 2,
            description: (self) => `Combat start: give this +${self.upgraded ? 6 : 3} power.`,
            onStateChange(change, output) {
                if (change.combatStart) {
                    output
                        .setSource(this)
                        .increasePower(this, this.upgraded ? 6 : 3)
                }
            }
        }, {
            name: 'Golem omega',
            goldCost: 1,
            sacrificeCost: 2,
            power: 4,
            description: (self) => `Combat start: summon ${self.upgraded ? 'two' : 'a'} 2-power skeleton.`,
            onStateChange(change, output) {
                if (change.combatStart) {
                    output
                        .setSource(this)
                        .summon(new OmegaSkeleton())
                    
                    if (this.upgraded) {
                        output.summon(new OmegaSkeleton())
                    }
                }
            }
        }, {
            name: 'Feasting pet',
            goldCost: 2,
            power: 7,
            description: `After one of your minions dies, give this +1 power.`,
            onStateChange(change, output) {
                let boost = change.sacrificedMinions.length + change.killedMinions.length;

                if (boost > 0) {
                    output
                        .setSource(this)
                        .increasePower(this, boost);
                }
            }
        }, {
            name: 'Dark ritual',
            goldCost: 3,
            description: `Summon a plain copy of each minion you have sacrificed this turn.`,
            triggerEffect(output) {
                let minions = this.owner.log.getActionTargetsForCurrentTurn('sacrifice');

                output.summon(minions);
            }
        }
    ],

    upgrades: [
        {
            description: `*Skeletons* summons 2 additional minions.`,
            applyUpgrade(character) {
                for (let card of character.hand.cards()) {
                    if (card.hasTag('skeleton')) {
                        card.summonedCount = card.makePlainCopy().summonedCount + 2;
                        card.generateName();
                    }
                }
            }
        }, {
            description: `Your minions have +2 power for each sacrifice they require.`,
            triggerIndex: 1,
            applyUpgrade(character) {
                for (let card of character.hand.cards()) {
                    if (card.summonedMinion) {
                        card.summonedMinion.power = card.summonedMinion.makePlainCopy().power + card.sacrificeCost * 2;
                    }
                }
            }
        }, {
            description: `Your golems cost 1 more sacrifice. Their abilities trigger twice.`,
            applyUpgrade(character) {
                for (let card of character.hand.cards()) {
                    let name = card.summonedMinion?.name;

                    if (name?.startsWith('Golem ')) {
                        card.sacrificeCost = card.makePlainCopy().sacrificeCost + 1;
                        card.summonedMinion.upgrade();
                        card.generateDescription();
                    }
                }
            }
        }
    ],

    emblems: [
        {
            description: `Combat start: summon four 3-power skeletons.`,
            onStateChange(change, output) {
                if (change.combatStart) {
                    output.setSource(this);
                    
                    for (let i = 0; i < 4; ++i) {
                        output.summon(new EmblemSkeleton());
                    }
                }
            }
        }
    ],

    onDeath() {
        this.owner.emitStateChange()
            .setSource(this)
            .sacrifice(this.owner.getMinions());

        this.owner.opponent.emitStateChange()
            .sacrifice(this.owner.opponent.getMinions());
    }
})
globalThis.ALL_FUNCTIONS.push(OmegaSkeleton);
globalThis.ALL_FUNCTIONS.push(EmblemSkeleton);