String.prototype.first = function() {
    return this[0];
};

String.prototype.last = function() {
    return this[this.length - 1];
}

String.prototype.kebabToCamelCase = function() {
    return this.replace(/-\w/g, str => str[1].toUpperCase());
};

String.prototype.camelToKebabCase = function() {
    return this.replace(/[a-z][A-Z]/g, str => str[0] + '-' + str[1]).toLowerCase();
}

String.prototype.lowerise = function() {
    if (!this) {
        return '';
    }
    
    return this[0].toLowerCase() + this.substring(1);
};