import { TOP_TO_BOTTOM } from '../../utils/layout-item';

export class GridScrollBar {
    constructor(grid) {
        this._grid = grid;
        this._stepPixelSize = 0;
        this._startScroll = 0;

        this.direction = TOP_TO_BOTTOM;
        this.stepSize = 0;
        this.displayedItemCount = 0;
        this.totalItemCount = 0;
        this.round = false;
    }

    render({ view }) {
        let step = this.stepSize;
        let roundedTotalItemCount = Math.ceil(this.totalItemCount / step) * step;
        let displayedRatio = this.displayedItemCount / roundedTotalItemCount;
        let offsetRatio = this._grid.scroll / this._grid.maxScroll;
        let parentRect = view.rect;
        let barRect = parentRect.clone();

        if (this.direction === TOP_TO_BOTTOM) {
            let emptySize = parentRect.height * (1 - displayedRatio);

            barRect.height = parentRect.height * displayedRatio;
            barRect = barRect.setY1(parentRect.y1() + emptySize * offsetRatio);

            this._stepPixelSize = step / roundedTotalItemCount * parentRect.height;
        }

        view
            .setRect(barRect)
            .backgroundColor('black')

        if (this.round) {
            view.borderRadius('50%m');
        }

        this._startScroll = this._grid.scroll;
    }

    onPostRender({ client }) {
        if (!this._grid.scrolling) {
            return;
        }

        for (let view of client.views()) {
            view.setPointerBehavior('none');
        }
    }

    onDragStart() {
        this._startScroll = this._grid.scroll;
        this._grid.scrolling = true;
    }

    onDragProgress({ dy }) {
        let scrollDy = Math.round(dy / this._stepPixelSize);

        this._grid.scroll = Math.clamp(this._startScroll + scrollDy, 0, this._grid.maxScroll);
    }

    onDragEnd() {
        this._grid.scrolling = false;
    }
}
globalThis.ALL_FUNCTIONS.push(GridScrollBar);