import { instanciate } from '../../utils/object';

export class GearSelectionIcon {
    constructor(client, item) {
        this._client = client;
        this._itemId = item.id;
        this._isHidden = !!item.hidden;
        this._item = instanciate(item);
    }

    _isItemSelected() {
        return this._client.gear.getSelectedGearItemCount(this._client.user, this._itemId) > 0;
    }

    isHidden() {
        return !!this._isHidden;
    }

    render(evt) {
        let isSelected = this._isItemSelected();
        let borderColor = 'black';

        if (isSelected) {
            borderColor = 'red';
        }

        this._item.render?.(evt);

        evt.view
            .borderColor(borderColor)
            .borderWidth('4%')
            .borderRadius('10%m')
            .hoverCursor('pointer')
        
        if (evt.client.user.isSearchingForGame) {
            evt.view.setDisabled(true);
        }
    }

    onClick({ client, button }) {
        if (button !== 'left') {
            return;
        }

        let isSelected = this._isItemSelected();
        let count = +!isSelected;

        this._sendSelectItemRequest(count);
    }

    getTooltip(evt) {
        return this._item.getTooltip(evt);
    }

    _sendSelectItemRequest(count) {
        this._client.sendRequest('selectGearItem', { id: this._itemId, count });
    }
}
globalThis.ALL_FUNCTIONS.push(GearSelectionIcon);