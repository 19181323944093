import { User } from '../common/user';
import { formatPlayerData } from '../framework/game/player-data';
import { Clock } from '../utils/clock';
import { Hash } from '../utils/hash';
import { HttpServer } from './http-server';
import { Matchmaking } from './matchmaking';
import { WebSocketServer } from './websocket-server';

export class Server {
    constructor({ port, serverHttpRootPath, serverDataPath, serverRefreshRate, serverWorld, gear, game, bots }) {
        this._httpServer = new HttpServer(port, serverHttpRootPath);
        this._webSocketServer = new WebSocketServer(this._httpServer);
        this._matchmaking = new Matchmaking();
        this._clock = new Clock();

        this._serverRefreshRate = serverRefreshRate;

        this._world = serverWorld;
        this._gear = gear;
        this._gameClass = game;
        this._bots = bots;
        this._users = new Map();
        this._objectsToUpdate = new Set();
        this._objectsToAdd = new Set();
        this._objectsToRemove = new Set();
        this._lastUpdate = this._clock.getCurrentTime();
        this._gameData = new WeakMap();
    }

    async start() {
        await this._httpServer.init();
        await this._webSocketServer.init();
        await this._matchmaking.init();

        let update = () => {
            this._update();

            setTimeout(update, this._serverRefreshRate);
        };

        update();
    }

    addObjectToUpdate(object) {
        this._objectsToAdd.add(object);
    }

    removeObjectToUpdate(object) {
        this._objectsToRemove.add(object);
    }

    _setUserGame(user, game) {
        let prevGame = user.game;

        user.game = game;

        if (prevGame) {
            let isGameAlive = false;
            
            for (let user of this._users.values()) {
                if (user.game === prevGame) {
                    isGameAlive = true;
                }
            }

            if (!isGameAlive) {
                this.removeObjectToUpdate(prevGame);
            }
        }
    }

    _update() {
        this._processNetworkEvents();
        this._triggerMatchmaking();
        this._triggerUpdates();
    }

    _makeUserRngSeed(user, currentTime) {
        return new Hash().string(user.id.toString()).float(currentTime).finish();
    }

    _triggerMatchmaking() {
        let currentTime = this._clock.getCurrentTime();

        for (let users of this._matchmaking.extractGroups()) {
            let players = users.map(user => ({
                userId: user.id,
                name: user.name,
                selectedGear: user.selectedGear,
                rngSeed: this._makeUserRngSeed(user, currentTime)
            }));

            let game = new this._gameClass(formatPlayerData(players, this._gear));

            game.init?.({ server: this });

            for (let user of users) {
                user.isSearchingForGame = false;
                this._setUserGame(user, game);
            }

            this.addObjectToUpdate(game);

            this._gameData.set(game, { players });

            for (let user of users) {
                this._updateUserState(user);
                this._sendCompleteGameState(user);
            }
        }
    }

    _triggerUpdates() {
        let currentTime = this._clock.getCurrentTime();
        let elapsed = currentTime - this._lastUpdate;

        this._lastUpdate = currentTime;

        for (let object of this._objectsToAdd) {
            this._objectsToUpdate.add(object);
        }

        for (let object of this._objectsToRemove) {
            this._objectsToUpdate.delete(object);
        }

        this._objectsToAdd.clear();
        this._objectsToRemove.clear();

        for (let object of this._objectsToUpdate) {
            object.update?.({ server: this, elapsed, currentTime });
        }
    }

    _emitWorldEvent(eventName, data) {
        this._world[eventName]?.({ server: this, ...data });
    }

    _processNetworkEvents() {
        let networkEvents = this._webSocketServer.pollEvents();

        for (let { action, connection, requestId, requestType, data } of networkEvents) {
            if (action === 'connect') {
                let user = new User({ selectedGear: this._gear.getDefaultSelectedGear() });

                user.setConnection(connection);

                this._emitWorldEvent('onUserConnect', { user });
            } else if (action === 'disconnect') {
                this._emitWorldEvent('onUserDisconnect', { user: connection.user });

                connection.user.setConnection(null);
            } else if (action === 'request') {
                let methodName = `$${requestType}`;
                let result = this[methodName]?.(connection.user, data);

                this._handleRequestResult(connection.user, requestId, result);

                // Promise.resolve(result)
                //     .then(data => this._handleRequestResult(connection.user, requestId, data));
            }
        }
    }

    _handleRequestResult(user, requestId, result) {
        let object = result ?? {};
        let defaultResponse = true;
        let messageKind = null;
        let messageContent = null;

        if (object.error) {
            messageKind = 'error';
            messageContent = object.error;
            defaultResponse = false;
        } else if (object.warning) {
            messageKind = 'warning';
            messageContent = object.warning;
        } else if (object.info) {
            messageKind = 'info';
            messageContent = object.info;
        } else if (object.success) {
            messageKind = 'success';
            messageContent = object.success;
        }

        let response = object.response ?? defaultResponse;

        this._webSocketServer.answerRequest(user.connection, requestId, response);

        if (messageKind) {
            this._showMessage(user, messageKind, messageContent);
        }

        if (object.updateState) {
            this._updateUserState(user);
        }

        if (object.refreshGameState) {
            this._sendCompleteGameState(user);
        }

        if (object.gameInputs) {
            this.sendGameInputs(object.gameInputs);
        }
    }

    _sendMessage(user, type, data) {
        this._webSocketServer.sendMessage(user.connection, type, data);
    }

    _showMessage(user, kind, content) {
        this._sendMessage(user, 'showMessage', { kind, content });
    }

    _updateUserState(user) {
        let { id, name, isSearchingForGame, selectedGear } = user;

        this._sendMessage(user, 'updateState', { id, name, isSearchingForGame, selectedGear });
    }

    _sendCompleteGameState(user) {
        let state = null;

        if (user.game) {
            let { players } = this._gameData.get(user.game);
            let inputs = user.game.getCompleteInputHistory?.(user.id) || [];

            state = { players, inputs };
        }

        this._sendMessage(user, 'setGameState', state);
    }

    _sendGameInput(user, input) {
        this._sendMessage(user, 'gameInput', input);
    }

    sendGameInputs(inputs) {
        for (let [name, input] of Object.entries(inputs)) {
            let user = this._users.get(name);

            if (user) {
                this._sendGameInput(user, input);
            }
        }
    }

    $login(user, { username }) {
        if (user.isLoggedIn()) {
            return { error: 'You are already logged in.' };
        }

        let existingUser = this._users.get(username);

        if (existingUser) {
            if (existingUser.connection) {
                return { error: 'This used is already logged in.' };
            }

            existingUser.setConnection(user.connection);
            user.setConnection(null);
        } else {
            existingUser = user;

            existingUser.id = username;
            existingUser.name = username;
            this._users.set(username, existingUser);
        }

        return { updateState: true, refreshGameState: true };
    }

    $logout(user) {
        if (!user.isLoggedIn()) {
            return { error: 'You are not logged in.' };
        }

        let blankUser = new User();

        blankUser.setConnection(user.connection);
        user.setConnection(null);

        return { updateState: true };
    }

    $matchmaking(user, { start, botClassName, botName = 'Bot', botGear = this._gear.getDefaultSelectedGear() }) {
        if (user.game) {
            return { error: 'In game.' };
        }

        if (!user.isLoggedIn()) {
            return { error: 'Not logged in.' };
        }

        if (start) {
            if (user.isSearchingForGame) {
                return { error: 'Already in queue.' };
            }

            if (botClassName) {
                let botUser = new User({ id: User.getRandomId(), name: botName, selectedGear: botGear });
                let bot = new this._bots[botClassName](botUser);

                this._matchmaking.add([user, botUser]);
                this.addObjectToUpdate(bot);
            } else {
                this._matchmaking.add(user);
            }

            user.isSearchingForGame = true;
        } else {
            if (!user.isSearchingForGame) {
                return { error: 'Not in queue.' };
            } 

            this._matchmaking.remove(user);
            user.isSearchingForGame = false;
        }

        return { updateState: true };
    }

    $selectGearItem(user, { id, count }) {
        let item = this._gear.getItemById(id);
        let category = this._gear.getItemCategory(item);

        if (!item) {
            return { error: `Item '${id}' does not exist.` };
        }

        if (count > 0) {
            for (let item of category.items) {
                user.selectedGear[item.id] = 0;
            }
        }

        user.selectedGear[id] = count;

        return { updateState: true };
    }

    $gameInput(user, { type, data = {} }) {
        if (!user.game) {
            return { error: 'Not in game.' };
        }

        let userId = user.id;
        let input = { userId, type, data };
        let result = user.game.onPlayerInput?.(input) ?? {};

        // if (!result.error && result.gameInputs === undefined) {
        //     result.gameInputs = {
        //         [userId]: input
        //     };
        // }

        return result;
    }

    $refreshGame(user) {
        return { refreshGameState: true };
    }

    $exitGame(user) {
        if (!user.game) {
            return { error: 'Not in game.' };
        }

        user.game.onPlayerExit?.(user.id);

        this._setUserGame(user, null);

        return { refreshGameState: true };
    }
}
globalThis.ALL_FUNCTIONS.push(Server);