import { Card } from '../entities/card';
import { PlayerStateChange } from './player-state-change';

export class PlayerInput {
    constructor(player) {
        this._player = player;
        this._action = null;
        this._cardId = -1;
        this._stashedCardIndex = -1;
        this._targetIds = [];
        this._endTurn = false;
        this._characterId = -1;
        this._forfeit = false;
    }

    playCard(cardId, targetIds = []) {
        this._action = 'play';
        this._cardId = cardId;
        this._targetIds = targetIds;

        return this;
    }

    recycleCard(cardId, targetIds = []) {
        this._action = 'recycle';
        this._cardId = cardId;
        this._targetIds = targetIds;

        return this;
    }

    selectCharacter(characterId) {
        this._characterId = characterId;

        return this;
    }

    forfeit() {
        this._forfeit = true;

        return this;
    }

    endTurn() {
        this._endTurn = true;

        return this;
    }

    onPreTrigger() {
        if (this._player.isOpponent && this._player.opponent.gameStep === 'waiting-for-opponent') {
            return new PlayerStateChange(this._player.opponent).setGameStep('opponent-actions');
        }
    }

    onEnd() {
        let change = new PlayerStateChange(this._player);
        let card = this._player.board.getEntityById(this._cardId);
        let character = this._player.board.getEntityById(this._characterId, character => this._player.backline.characters().includes(character));
        let targets = this._targetIds.map(id => this._player.board.getEntityById(id));

        targets = Array.from(new Set(targets.filter(card => card)));

        if (this._forfeit) {
            this._player.hasLost = true;
            change.setGameStep('defeat');
        }

        if (character && this._player.mustChooseCharacter) {
            change
                .removeCharacter(this._player.activeCharacter)
                .setActiveCharacter(character);

            this._player.mustChooseCharacter = false;
            this._player.characterSelected = false;
        } else if (card) {
            if (this._action === 'play') {
                change.playCard(card, targets);
            } else if (this._action === 'recycle') {
                let selectedCard = targets[0];

                if (selectedCard && selectedCard instanceof Card && selectedCard.getContainer() === this._player.recyclingCenter) {
                    change.recycleCard(card, selectedCard);
                }
            }
        }

        if (this._endTurn) {
            this._player.hasFinishedTurn = true;
            change.setGameStep('waiting-for-opponent');
        }

        change.markAsPlayerAction();

        return [change];
    }
}
globalThis.ALL_FUNCTIONS.push(PlayerInput);