import { Card } from '../entities/card';
import { Minion } from '../entities/minion';
import { PlayerStateChange } from '../events/player-state-change';

/**
* @callback MinionOnStateChangeCallback
* @param {Minion} this
* @param {PlayerStateChange} change
* @param {PlayerStateChange} output
*/

/**
 * @typedef {object} MakeMinionOptions
 * @property {string} name
 * @property {string[]} [tags]
 * @property {string|(()=>string)} [description]
 * @property {string} [color]
 * @property {number} [goldCost]
 * @property {number} [sacrificeCost]
 * @property {number} [summonedCount]
 * @property {number} [power]
 * @property {boolean} [poisonous]
 * @property {boolean} [indestructible]
 * @property {number} [playerDamageModifier]
 * @property {number} [minionDamageModifier]
 * @property {number} [receivedDamageModifier]
 * @property {boolean} [immuneToWeakerMinions]
 * @property {boolean} [bypassCombat]
 * @property {number} [fixedPowerDivider]
 * @property {MinionOnStateChangeCallback} [onStateChange]
 */

/**
 * 
 * @param {MakeMinionOptions} options
 * @returns 
 */
export function makeMinionCard(options) {
    let {
        name,
        tags = [],
        description = null,
        color = null,
        goldCost = 0,
        sacrificeCost = 0,
        summonedCount = 1,
        power = 1,
        poisonous = false,
        indestructible = false,
        playerDamageModifier = 1,
        minionDamageModifier = 1,
        receivedDamageModifier = 1,
        immuneToWeakerMinions = false,
        bypassCombat = false,
        fixedPowerDivider = null,
        onStateChange = (change, output) => {},
    } = options;

    let makeDescription = null;

    if (typeof description === 'string') {
        makeDescription = new Function(`return "${description}";`);
    } else if (typeof description === 'function') {
        makeDescription = description;
    } else {
        makeDescription = () => '';
    }

    let MinionClass = class extends Minion {
        name = name
        description = makeDescription(this)
        color = color
        power = power
        poisonous = poisonous
        indestructible = indestructible
        playerDamageModifier = playerDamageModifier
        minionDamageModifier = minionDamageModifier
        receivedDamageModifier = receivedDamageModifier
        immuneToWeakerMinions = immuneToWeakerMinions
        bypassCombat = bypassCombat
        fixedPowerDivider = fixedPowerDivider
        goldCost = goldCost

        onStateChange(change, output) {
            onStateChange.call(this, change, output);
        }

        upgrade() {
            this.upgraded = true;
            this.description = makeDescription(this);
        }
    };

    let MinionCardClass = class extends Card {
        summonedMinion = new MinionClass()
        summonedCount = summonedCount
        goldCost = goldCost
        sacrificeCost = sacrificeCost
        tags = tags
    };

    return MinionCardClass;
}

export function getSacrificeKeywordDescription(sacrificeCost) {
    let str = 'leftmost minion';

    if (sacrificeCost > 1) {
        str = `${sacrificeCost} leftmost minions`;
    }

    return `*Sacrifice ${sacrificeCost}* @0.85{_(to play this card, sacrifice your ${str})._}`;
}