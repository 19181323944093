export function serializeObject(object) {
    let string = JSON.stringify(object);
    let buffer = new TextEncoder().encode(string);

    return buffer;
}

export function deserializeBuffer(data) {
    let buffer = new Uint8Array(data);
    let string = new TextDecoder().decode(buffer);

    return JSON.parse(string);
}