import { makeUpgradeCard } from '../../cards/make-upgrade-card';
import { KING_COLOR } from '../../constants/graphics-constants';
import { BattlecryCard, UpgradedBattlecryCard } from './battlecry';

export const KingUpgrade2Card = makeUpgradeCard({
    name: 'King upgrade 2',
    description: '*Battlecry* now has: "Your minions with a stronger neighbor get an additional +2 power".',
    color: KING_COLOR,

    applyUpgrade(character, output) {
        for (let card of character.hand.cards()) {
            if (card.constructor === BattlecryCard) {
                output.replaceCard(card, new UpgradedBattlecryCard());
            }
        }
    }
})