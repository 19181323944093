import { EMBLEM_COST } from '../constants/game-constants';
import { Card } from '../entities/card';
import { Effect } from '../entities/effect';
import { PlayerStateChange } from '../events/player-state-change';


/**
* @typedef {object} MakeEmblemCardOptions
* @property {string} [options.name]
* @property {string} options.description
* @property {string} [options.color]
* @property {number} [options.goldCost]
* @property {import('./make-spell-card').EffectOnStateChangeCallback} [options.onStateChange]
*/

/**
* 
* @param {MakeEmblemCardOptions} options 
* @returns 
*/
export function makeEmblemCard(options) {
    let {
        name,
        description = null,
        color = null,
        goldCost = EMBLEM_COST,
        onStateChange = (change, output) => {}
    } = options;

    description = `${getEmblemKeywordDescription()}\n\n${description}`;

    let EmblemClass = class extends Effect {
        name = name
        description = description
        color = color
        icon = '♦'
        character = null
        isEmblem = true

        onStateChange(change, output) {
            if (change.createdEffects.includes(this)) {
                this.character = this.owner.activeCharacter;
                this.character.availableEmblemCards = this.character.availableEmblemCards.filter(card => !(card instanceof EmblemCardClass));
            }

            onStateChange.call(this, change, output);
        }
    };

    let EmblemCardClass = class extends Card {
        createdEffect = new EmblemClass()
        isEmblem = true
        goldCost = goldCost

        canBePlayed() {
            return super.canBePlayed() && this.owner.activeCharacter.availableUpgradeCards.length === 0;
        }
    };

    return EmblemCardClass;
}

function getEmblemKeywordDescription() {
    return `*Emblem* @0.85{_(permanent effect regardless of the active character)._}`;
}