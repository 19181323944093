import { LobbyHeader } from './lobby-header';
import { LoginPanel } from './login-panel';

export class LoginScreen {
    constructor(parameters) {
        this.loginPanel = new LoginPanel(parameters);
    }

    render({ view, constants }) {
        view.layout()
            .backgroundColor(constants.lobbyBackgroundColor)
            .topToBottom()
            .push(new LobbyHeader())
            .height(constants.lobbyHeaderHeight)
            .push(this.loginPanel)
            .force(1)
            .push()
            .height(constants.lobbyFooterHeight)
    }
}
globalThis.ALL_FUNCTIONS.push(LoginScreen);