import { EventChain } from './event-chain';

export class EventChainGroup {
    constructor() {
        this._eventChains = [];
    }

    add() {
        let eventChain = new EventChain();

        this._eventChains.push(eventChain);

        return eventChain;
    }

    onProgress(elapsed) {
        let remaining = elapsed;

        for (let eventChain of this._eventChains) {
            remaining = Math.min(remaining, eventChain.trigger(elapsed));
        }

        return remaining;
    }
}
globalThis.ALL_FUNCTIONS.push(EventChainGroup);