import seedrandom from 'seedrandom';

export class Rng {
    constructor(seed) {
        this._seed = seed;
        this._rng = seedrandom(seed);
    }

    int(min, max) {
        let dif = (max - min + 1);

        return Math.abs(this._rng.int32()) % dif + min
    }

    float(min, max) {
        let dif = (max - min) * 1000000;

        return (this._rng.int32() % dif) / 1000000 + min;
    }

    shuffle(array) {
        return array.shuffle((min, max) => this.int(min, max));
    }
}
globalThis.ALL_FUNCTIONS.push(Rng);