import { WEBSOCKET_BINARY_TYPE, WEBSOCKET_UPGRADE_PATHNAME } from '../common/constants';
import { deserializeBuffer, serializeObject } from '../common/serialization';

export class WebSocketManager {
    constructor() {
        this._webSocket = null;
        this._events = [];
        this._nextRequestId = 1;
        this._messagePromiseResolves = new Map();
    }

    async init() {
        let url = computeWebSocketUrl();

        this._webSocket = new WebSocket(url);
        this._webSocket.binaryType = WEBSOCKET_BINARY_TYPE;

        this._webSocket.addEventListener('open', () => {
            this._events.push({ action: 'connect' });
        });

        this._webSocket.addEventListener('close', (evt) => {
            this._events.push({ action: 'disconnect' });
        });

        this._webSocket.addEventListener('error', (evt) => {
            console.log('ERROR');
            console.log(evt)
        });

        this._webSocket.addEventListener('message', (event) => {
            let { requestId, type, data } = deserializeBuffer(event.data);

            if (requestId) {
                let resolve = this._messagePromiseResolves.get(requestId);

                if (resolve) {
                    this._messagePromiseResolves.delete(requestId);
                    resolve(data);
                }
            } else {
                this._events.push({ action: 'message', type, data });
            }
        });

        return new Promise(resolve => {
            this._webSocket.addEventListener('open', resolve);
        });
    }

    pollEvents() {
        let result = this._events;
        this._events = [];
        return result;
    }

    sendMessage(requestType, data) {
        return new Promise(resolve => {
            let requestId = this._nextRequestId++;
            let buffer = serializeObject({ requestId, requestType, data });

            this._messagePromiseResolves.set(requestId, resolve);
            this._webSocket.send(buffer);
        });
    }
}

function computeWebSocketUrl() {
    let protocol = 'ws';
    let port = window.location.port || 80;
    let hostname = window.location.hostname;

    if (hostname === 'localhost') {
        // @ts-ignore
        port = window.OUTPOST_APP_PORT;
    }

    if (window.location.protocol === 'https:') {
        protocol = 'wss';
        port = 443;
    }

    return `${protocol}://${hostname}:${port}${WEBSOCKET_UPGRADE_PATHNAME}`;
}
globalThis.ALL_FUNCTIONS.push(WebSocketManager);