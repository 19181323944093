import { OUTPOST_ENV } from 'outpost';
import { path, fs, http, https, url, express } from '../modules';

export class HttpServer {
    constructor(port, rootPath) {
        this._port = port;
        this._rootPath = rootPath;
        this._app = null;
        this._server = null;
    }

    async init() {
        this._app = (express.default)();
        this._app.use(express.default.static(this._rootPath));

        try {
            let certPath = OUTPOST_ENV.SSL_CERT;
            let keyPath = OUTPOST_ENV.SSL_KEY;
            let cert = fs.readFileSync(certPath, 'utf8');
            let key = fs.readFileSync(keyPath, 'utf8');

            this._server = https.createServer({ cert, key }, this._app);
        } catch (e) {
            this._server = http.createServer(this._app);
        }

        return new Promise(resolve => {
            this._server.listen(this._port, resolve);
        });
    }

    configureRequestUpgrade(url, webSocketServer) {
        this._server.on('upgrade', (request, socket, head) => {
            if (request.url === url) {
                webSocketServer.handleUpgrade(request, socket, head, (ws) => {
                    webSocketServer.emit('connection', ws, request);
                });
            } else {
                socket.destroy();
            }
        });
    }
}
globalThis.ALL_FUNCTIONS.push(HttpServer);