export class GridHeader {
    constructor({ text }) {
        this.text = text;
    }

    render({ view }) {
        view
            .text(this.text)
            .textFont('Arial')
            .textColor('black')
            .textSize('35%')
            // .backgroundColor('lightgreen')
    }
}
globalThis.ALL_FUNCTIONS.push(GridHeader);