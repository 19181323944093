import { Vortex } from 'vortex';
import { DumbBot } from './bots/dumb-bot';
import { AUTO_MATCHMAKING } from './constants/game-constants';
import { AVAILABLE_CHARACTERS } from './constants/game-data';
import { Game } from './game';

function makeCategory(name, items) {
    return {
        name,
        items,
        requiredCount: 1,
        itemCountPerRow: 3,
        itemCountPerColumn: 3,
        backgroundColor: 'BurlyWood',
    }
}

const GEAR = [
    makeCategory('Character', AVAILABLE_CHARACTERS),
];

Vortex.start({
    gear: GEAR,
    game: Game,
    bots: { DumbBot },
    autoMatchmaking: AUTO_MATCHMAKING,
    clientLocalData: {
        showHidden: true,
    },
    constants: {
        lobbyTitle: 'Inglio',
        lobbyBackgroundColor: 'blanchedalmond',
        lobbyFooterBackgroundColor: 'tan',
        buttonStyle: {
            backgroundColor: 'white'
        }
    }
});