export function formatObject(value, defaultValue) {
    if (isObject(defaultValue)) {
        let object = value;
        let result = {};

        if (!isObject(value)) {
            if (value && !isArray(value) && typeof value === 'object') {
                return value;
            }

            object = {};
        }

        for (let [itemKey, itemValue] of Object.entries(object)) {
            result[itemKey] = itemValue;
        }

        for (let [itemKey, itemDefaultValue] of Object.entries(defaultValue)) {
            let itemValue = object[itemKey];

            result[itemKey] = formatObject(itemValue, itemDefaultValue);
        }

        return result;
    } else if (isArray(defaultValue)) {
        if (!isArray(value)) {
            return defaultValue.map(item => formatObject(undefined, item));
        } else {
            return value;
        }
    } else if (value === undefined) {
        return defaultValue;
    } else {
        return value;
    }
}

function isObject(value) {
    return value?.constructor === Object;
}

function isArray(value) {
    return Array.isArray(value);
}

export function instanciate(objectOrFunction, parameters) {
    if (typeof objectOrFunction === 'function') {
        return new objectOrFunction(parameters);
    } else {
        return objectOrFunction;
    }
}

export function isInstanceOf(object, classObject) {
    return object && object instanceof classObject;
}