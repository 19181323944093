export class UsernameLabel {
    render({ client, view, constants }) {
        if (!client.user.isLoggedIn()) {
            return;
        }

        view
            .text(client.user.name)
            .textColor(constants.lobbyUsernameTextColor)
            .textSize('65%')
            .borderColor(constants.lobbyUsernameTextColor)
            .borderWidth('3r')
            .backgroundColor(constants.lobbyUsernameBackgroundColor)
            .borderRadius('50%')
    }
}
globalThis.ALL_FUNCTIONS.push(UsernameLabel);