import { BATTLEFIELD_HEIGHT, BATTLEFIELD_WIDTH, BOARD_MARGIN, BOARD_RECT, CHARACTER_HEIGHT, CHARACTER_WIDTH, CURRENT_STEP_BUTTON_HEIGHT, CURRENT_STEP_BUTTON_WIDTH, EFFECT_LIST_HEIGHT, HAND_HEIGHT, HAND_WIDTH, OPPONENT_PANEL_COLOR, OPPONENT_PLAYER_COLOR, PLAYER_NAME_PANEL_HEIGHT, PLAYER_NAME_PANEL_WIDTH, RECYCLING_CENTER_HEIGHT, RECYCLING_CENTER_WIDTH, SELF_PANEL_COLOR, SELF_PLAYER_COLOR, STASH_HEIGHT, STASH_WIDTH, HEALTH_COUNTER_HEIGHT, STAT_COUNTER_MARGIN, HEALTH_COUNTER_WIDTH, GOLD_COUNTER_WIDTH, GOLD_COUNTER_HEIGHT } from './constants/graphics-constants';
import { Battlefield } from './panels/battlefield';
import { CharacterPortrait } from './panels/character-portait';
import { EffectList } from './panels/effect-list';
import { NamePanel } from './panels/name-panel';
import { RecyclingCenter } from './panels/recycling-center';
import { PlayerStateChange } from './events/player-state-change';
import { EndTurnButton } from './panels/end-turn-button';
import { ShowHistoryButton } from './panels/show-history-button';
import { ExitButton } from './panels/exit-button';
import { Character } from './entities/character';
import { BattlefieldHitbox } from './hitboxes/battlefield-hitbox';
import { RecyclingCenterHitbox } from './hitboxes/recycling-center-hitbox';
import { GoldCounter } from './panels/gold-counter';
import { CardSelectionOverlay as CardSelectionOverlay } from './panels/card-selection-overlay';
import { STARTING_INCOME } from './constants/game-constants';
import { PlayerInput } from './events/player-input';
import { GameLog } from './log/game-log';
import { Board, Entity, Rect } from 'vortex';
import { Backline } from './panels/backline';
import { TurnNumberLabel } from './panels/turn-number-label';
import { RECRUTABLE_CHARACTERS } from './constants/game-data';
import { SummonLocationIndicator } from './panels/summon-location-indicator';
import { getNewCommonCards } from './constants/common-cards';

export class Player extends Entity {
    constructor({ userId, name, gear, rng, eventChain, history }) {
        super();

        this.board = new Board();
        this.eventChain = eventChain;
        this.history = history;
        this.userId = userId;
        this.name = name;
        this.gear = gear;
        this.rng = rng;
        this.opponent = false;

        this.log = new GameLog();
        this.hasFinishedTurn = false;
        this.mustChooseCharacter = false;
        this.hasLost = false;
        this.gameStep = null;
        this.turnNumber = 0;
        this.leftSummonThisTurn = false;
        this.additionalCombatCountThisTurn = 0;

        let startingCharacterConstructor = gear.find(item => Character.is(item)).constructor;

        this.availableCharacters = RECRUTABLE_CHARACTERS.map(CharacterClass => new CharacterClass());
        this.startingCharacter = this.availableCharacters.find(character => character.constructor === startingCharacterConstructor) || new startingCharacterConstructor();
        this.activeCharacter = null;
        this.recruitedCharacterCount = 0;
        this.swapCountThisTurn = 0;
        this.retainGoldThisTurn = false;
        this.gold = 0;
        this.income = 0;

        this.backline = new Backline();
        this.battlefield = new Battlefield();
        this.effectList = new EffectList();
        this.activeCharacterZone = new CharacterPortrait();
        this.recyclingCenter = new RecyclingCenter();
        this.namePanel = new NamePanel();
        this.endTurnButton = new EndTurnButton();
        this.showHistoryButton = new ShowHistoryButton();
        this.exitButton = new ExitButton();
        this.turnNumberLabel = new TurnNumberLabel();
        this.battlefieldHitbox = new BattlefieldHitbox();
        this.recyclingCenterHitbox = new RecyclingCenterHitbox();
        this.goldCounter = new GoldCounter();
        this.cardSelectionOverlay = new CardSelectionOverlay();
        this.handRect = null;
        this.upgradeListRect = null;

        // fields only used client-side
        this.summonLocationIndicator = new SummonLocationIndicator(this);
        this.isOpponent = false;
        this.characterSelected = false;
    }

    setOpponent(opponent) {
        this.opponent = opponent;
        this.battlefield.mirrorContainer = opponent.battlefield;
        this.board.opponent = opponent.board;
    }

    isFinished() {
        return ['victory', 'defeat', 'draw'].includes(this.gameStep);
    }

    getPlayerColor() {
        if (this.isOpponent) {
            return OPPONENT_PLAYER_COLOR;
        } else {
            return SELF_PLAYER_COLOR;
        }
    }

    getPanelColor() {
        if (this.isOpponent) {
            return OPPONENT_PANEL_COLOR;
        } else {
            return SELF_PANEL_COLOR;
        }
    }

    init({ client }) {
        if (client && client.user.id !== this.userId) {
            this.isOpponent = true;
        }

        let recyclingCenterRect =  new Rect(RECYCLING_CENTER_WIDTH / 2 + BOARD_MARGIN, BOARD_RECT.y, RECYCLING_CENTER_WIDTH, RECYCLING_CENTER_HEIGHT);
        let handRect = new Rect(BOARD_RECT.x, BOARD_RECT.height - HAND_HEIGHT / 2, HAND_WIDTH, HAND_HEIGHT);
        let battlefieldRect = new Rect(BOARD_RECT.x, BOARD_RECT.y + BATTLEFIELD_HEIGHT / 2, BATTLEFIELD_WIDTH, BATTLEFIELD_HEIGHT);
        let stashRect = new Rect(STASH_WIDTH / 2 + BOARD_MARGIN, BOARD_RECT.height - STASH_HEIGHT / 2 - BOARD_MARGIN, STASH_WIDTH, STASH_HEIGHT);
        let deckRect = stashRect.mirror(BOARD_RECT.x, stashRect.y);
        let characterRect = new Rect(BOARD_RECT.x, (handRect.y1() + battlefieldRect.y2()) / 2, CHARACTER_WIDTH, CHARACTER_HEIGHT);
        let dy = characterRect.getVerticalSpaceUntil(battlefieldRect);
        let effectListWidth = (battlefieldRect.width - characterRect.width) / 2 - dy;
        // let effectListRect = Rect.fromTopLeft(characterRect.x2() + dy, characterRect.y1(), effectListWidth, EFFECT_LIST_HEIGHT);
        let endTurnButtonRect = new Rect((battlefieldRect.x2() + BOARD_RECT.width) / 2, BOARD_RECT.height / 2, CURRENT_STEP_BUTTON_WIDTH, CURRENT_STEP_BUTTON_HEIGHT);
        let namePanelRect = endTurnButtonRect.getBottomNeighbor(PLAYER_NAME_PANEL_HEIGHT, 100);
        let turnNumberLabelRect = endTurnButtonRect.getTopNeighbor(25, 15);
        let showHistoryButtonRect = endTurnButtonRect.getBottomNeighbor(30, 30).stripFromSides(0, 0, 0, endTurnButtonRect.width / 2);
        let exitButtonRect = showHistoryButtonRect.getLeftNeighbor(showHistoryButtonRect.width - 10, 10)
        let battlefieldHitboxRect = Rect.fromRectList([battlefieldRect, characterRect]).extendFromSides(1, 0, 0, 0).pad(12);
        let goldCounterRect = characterRect.getLeftNeighbor(GOLD_COUNTER_WIDTH, STAT_COUNTER_MARGIN);
        // @ts-ignore
        let [effectListRect, upgradeListRect] = characterRect.getRightNeighbor(effectListWidth, dy).splitVertically('45%', '10%');

        this.handRect = handRect;
        this.upgradeListRect = upgradeListRect;

        this.emitStateChange()
            .spawn(this, null)
            .addPanel(this.recyclingCenter, recyclingCenterRect)
            .addPanel(this.battlefield, battlefieldRect)
            .addPanel(this.effectList, effectListRect)
            .addPanel(this.namePanel, namePanelRect)
            .addPanel(this.activeCharacterZone, characterRect)
            .addPanel(this.backline, stashRect)
            .addPanel(this.endTurnButton, endTurnButtonRect)
            .addPanel(this.showHistoryButton, showHistoryButtonRect)
            .addPanel(this.exitButton, exitButtonRect)
            .addPanel(this.turnNumberLabel, turnNumberLabelRect)
            .addPanel(this.battlefieldHitbox, battlefieldHitboxRect)
            .addPanel(this.recyclingCenterHitbox, recyclingCenterRect)
            .addPanel(this.goldCounter, goldCounterRect)
            .addPanel(this.cardSelectionOverlay, BOARD_RECT)
            .spawn(this.startingCharacter, null)
            .spawn(this.startingCharacter.hand, null)
            .spawn(this.startingCharacter.upgradeList, null)
            .spawn(this.startingCharacter.availableUpgradeCards, null)
            .spawn(this.startingCharacter.availableEmblemCards, null)
            .spawn(this.availableCharacters, null)
            .spawn(this.availableCharacters.map(character => character.hand), null)
            .spawn(this.availableCharacters.map(character => character.upgradeList), null)
            .spawn(this.availableCharacters.map(character => character.availableUpgradeCards).flat(), null)
            .spawn(this.availableCharacters.map(character => character.availableEmblemCards).flat(), null)
            .setActiveCharacter(this.startingCharacter)
    }

    emitStateChange() {
        return this.eventChain.add(new PlayerStateChange(this));
    }

    emitStateChangeDelayed() {
        return this.eventChain.append(new PlayerStateChange(this));
    }

    emitPlayerInputDelayed() {
        return this.eventChain.append(new PlayerInput(this));
    }

    skipAnimations() {
        this.eventChain.trigger(Infinity);
    }

    getEntities() {
        return Array.from(this.board.entities);
    }

    getAnimations() {
        return Array.from(this.board.animations);
    }
    
    getMinions() {
        return this.battlefield.minions().slice();
    }

    onStateChange(change, output) {
        if (change.turnStart) {
            this.log.pushNewTurn();
            this.turnNumber += 1;
            this.swapCountThisTurn = 0;
            this.additionalCombatCountThisTurn = 0;
            this.leftSummonThisTurn = false;
            this.retainGoldThisTurn = false;

            output.addCommonCards(getNewCommonCards(this.turnNumber));

            if (this.turnNumber === 1) {
                this.income = STARTING_INCOME;
            } else {
                this.income += 1;
            }

            output.giveGold(this.income);
            output.makeInstant();
        } else if (change.combatStart && !this.retainGoldThisTurn) {
            output.takeGold(this.gold);
        }

        for (let minion of change.sacrificedMinions) {
            this.log.recordAction('sacrifice', minion);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Player);