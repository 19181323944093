import { EvolveCard } from '../cards/common/evolve';
import { RecruitCard } from '../cards/common/recruit';
import { SwapCard } from '../cards/common/swap';
import { UpgradeCard } from '../cards/common/upgrade';
import { ReplayCard } from '../cards/common/replay';
import { StashCard } from '../cards/common/stash';
import { FinalGambitCard } from '../cards/common/final-gambit';

const COMMON_CARDS = new Map([
    [1, [UpgradeCard]],
    [2, [RecruitCard, SwapCard]],
    [3, [StashCard]],
    [4, [ReplayCard]],
    [5, [FinalGambitCard]],
])

export function getNewCommonCards(turnNumber) {
    // if (turnNumber === 1) {
    //     return Array.from(COMMON_CARDS.values()).flat().map(CardClass => new CardClass());
    // }
    // return;

    return (COMMON_CARDS.get(turnNumber) || []).map(CardClass => new CardClass());
}