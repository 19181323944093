import { makeEmblemCard } from '../cards/make-emblem-card';
import { makeMinionCard } from '../cards/make-minion';
import { makeSpellCard } from '../cards/make-spell-card';
import { makeUpgradeCard } from '../cards/make-upgrade-card';
import { Character } from '../entities/character';

/**
* @callback onCharacterDeath
* @param {Character} this
*/

/**
 * 
 * @param {object} options
 * @param {string} options.id
 * @param {string} options.name
 * @param {string} [options.color]
 * @param {string} [options.description]
 * @param {onCharacterDeath} [options.onDeath]
 * @param {(import('../cards/make-minion').MakeMinionOptions|import('../cards/make-spell-card').MakeSpellCardOptions)[]} [options.cards]
 * @param {import('../cards/make-upgrade-card').MakeUpgradeCardOptions[]} [options.upgrades]
 * @param {import('../cards/make-emblem-card').MakeEmblemCardOptions[]} [options.emblems]
 * @returns 
 */
export function makeCharacter(options) {
    let {
        id,
        name,
        color,
        description = '',
        onDeath = () => {},
        cards = [],
        upgrades = [],
        emblems = []
    } = options;

    let cardClasses = cards.map(card => {
        // @ts-ignore
        if (card.power !== undefined) {
            // @ts-ignore
            return makeMinionCard(card);
        } else {
            // @ts-ignore
            return makeSpellCard(card);
        }
    });

    let upgradeClasses = upgrades.map((upgrade, i) => makeUpgradeCard({
        name: `${name} upgrade ${i+1}`,
        color,
        ...upgrade,
    }));

    let emblemClasses = emblems.map(emblem => makeEmblemCard({
        name: `${name}'s emblem`,
        color: color,
        ...emblem
    }));

    let CharacterClass = class extends Character {
        static id = id

        name = name
        description = description
        color = color
        cards = cardClasses.map(CardClass => new CardClass())
        availableUpgradeCards = upgradeClasses.map(UpgradeCardClass => new UpgradeCardClass())
        availableEmblemCards = emblemClasses.map(EmblemCardClass => new EmblemCardClass())

        onDeath() {
            onDeath.call(this);
        }
    };

    return CharacterClass;
}