import { CARD_ASPECT_RATIO, DEFAULT_BORDER_RADIUS, HEADER_HEIGHT, NEUTRAL_PANEL_COLOR } from '../constants/graphics-constants';
import { Panel } from './panel';

export class RecyclingCenter extends Panel {
    header = new RecyclingCenterHeader()
    hideIfOpponent = true
    headerSize = HEADER_HEIGHT
    itemAspectRatio = CARD_ASPECT_RATIO
    outerMargin = 8
    innerMargin = 8
    itemCountPerRow = 3
    itemCountPerColumn = 2
    horizontalAlign = 0
    verticalAlign = 0

    cards() {
        return this.layoutInfo.items;
    }

    render({ view }) {
        view
            .backgroundColor(NEUTRAL_PANEL_COLOR)
            .borderColor('black')
            .borderWidth(2)
            .borderRadius(DEFAULT_BORDER_RADIUS)
    }
}

class RecyclingCenterHeader {
    render({ view }) {
        let text = "Common cards (❓)";

        view
            .text(text)
            .textSize('50%')
    }

    getTooltip() {
        return `Drag a card to trade it against one of the available cards.`;
    }
}
globalThis.ALL_FUNCTIONS.push(RecyclingCenter);
globalThis.ALL_FUNCTIONS.push(RecyclingCenterHeader);