import { Layout } from '../utils/layout';
import { Color } from '../utils/color';
import { DisplaySize } from '../utils/display-size';

export class ViewLayout extends Layout {
    constructor(data) {
        super(data);
    }

    // GENERATION START

    shape(shape) {
        this._currentItem.properties.shape = shape;
        return this;
    }
    offsetX(offsetX) {
        this._currentItem.properties.offsetX = DisplaySize.from(offsetX);
        return this;
    }
    offsetY(offsetY) {
        this._currentItem.properties.offsetY = DisplaySize.from(offsetY);
        return this;
    }
    zIndex(zIndex) {
        this._currentItem.properties.zIndex = zIndex;
        return this;
    }
    horizontalAnchor(horizontalAnchor) {
        this._currentItem.properties.horizontalAnchor = horizontalAnchor;
        return this;
    }
    verticalAnchor(verticalAnchor) {
        this._currentItem.properties.verticalAnchor = verticalAnchor;
        return this;
    }
    borderRadius(borderRadius) {
        this._currentItem.properties.borderRadius = DisplaySize.from(borderRadius);
        return this;
    }
    borderWidth(borderWidth) {
        this._currentItem.properties.borderWidth = DisplaySize.from(borderWidth);
        return this;
    }
    borderScale(borderScale) {
        this._currentItem.properties.borderScale = borderScale;
        return this;
    }
    borderDashLength(borderDashLength) {
        this._currentItem.properties.borderDashLength = DisplaySize.from(borderDashLength);
        return this;
    }
    borderGapLength(borderGapLength) {
        this._currentItem.properties.borderGapLength = DisplaySize.from(borderGapLength);
        return this;
    }
    borderColor(borderColor) {
        this._currentItem.properties.borderColor = Color.from(borderColor);
        return this;
    }
    borderAlpha(borderAlpha) {
        this._currentItem.properties.borderAlpha = borderAlpha;
        return this;
    }
    backgroundColor(backgroundColor) {
        this._currentItem.properties.backgroundColor = Color.from(backgroundColor);
        return this;
    }
    backgroundAlpha(backgroundAlpha) {
        this._currentItem.properties.backgroundAlpha = backgroundAlpha;
        return this;
    }
    overlayColor(overlayColor) {
        this._currentItem.properties.overlayColor = Color.from(overlayColor);
        return this;
    }
    overlayAlpha(overlayAlpha) {
        this._currentItem.properties.overlayAlpha = overlayAlpha;
        return this;
    }
    imageUrl(imageUrl) {
        this._currentItem.properties.imageUrl = imageUrl;
        return this;
    }
    imageScale(imageScale) {
        this._currentItem.properties.imageScale = imageScale;
        return this;
    }
    imageLayout(imageSpriteCountPerRow, imageSpriteCountPerColumn) {
        this._currentItem.properties.imageSpriteCountPerRow = imageSpriteCountPerRow;
        this._currentItem.properties.imageSpriteCountPerColumn = imageSpriteCountPerColumn;
        return this;
    }
    imageSpriteIndex(imageSpriteIndex) {
        this._currentItem.properties.imageSpriteIndex = imageSpriteIndex;
        return this;
    }
    animationStartTime(animationStartTime) {
        this._currentItem.properties.animationStartTime = animationStartTime;
        return this;
    }
    animationCurrentTime(animationCurrentTime) {
        this._currentItem.properties.animationCurrentTime = animationCurrentTime;
        return this;
    }
    animationDuration(animationDuration) {
        this._currentItem.properties.animationDuration = animationDuration;
        return this;
    }
    text(text) {
        this._currentItem.properties.text = text;
        return this;
    }
    textFont(textFont) {
        this._currentItem.properties.textFont = textFont;
        return this;
    }
    textSize(textSize) {
        this._currentItem.properties.textSize = DisplaySize.from(textSize);
        return this;
    }
    textColor(textColor) {
        this._currentItem.properties.textColor = Color.from(textColor);
        return this;
    }
    textAlpha(textAlpha) {
        this._currentItem.properties.textAlpha = textAlpha;
        return this;
    }
    textPadding(textPadding) {
        this._currentItem.properties.textPadding = DisplaySize.from(textPadding);
        return this;
    }
    textHorizontalAlign(textHorizontalAlign) {
        this._currentItem.properties.textHorizontalAlign = textHorizontalAlign;
        return this;
    }
    textVerticalAlign(textVerticalAlign) {
        this._currentItem.properties.textVerticalAlign = textVerticalAlign;
        return this;
    }
    textBold(textBold) {
        this._currentItem.properties.textBold = textBold;
        return this;
    }
    textItalic(textItalic) {
        this._currentItem.properties.textItalic = textItalic;
        return this;
    }
    textOffsetX(textOffsetX) {
        this._currentItem.properties.textOffsetX = textOffsetX;
        return this;
    }
    textOffsetY(textOffsetY) {
        this._currentItem.properties.textOffsetY = textOffsetY;
        return this;
    }
    textCursorIndex(textCursorIndex) {
        this._currentItem.properties.textCursorIndex = textCursorIndex;
        return this;
    }
    textFit(textFit) {
        this._currentItem.properties.textFit = textFit;
        return this;
    }
    textAllowMultiline(textAllowMultiline) {
        this._currentItem.properties.textAllowMultiline = textAllowMultiline;
        return this;
    }
    textFixVerticalCenter(textFixVerticalCenter) {
        this._currentItem.properties.textFixVerticalCenter = textFixVerticalCenter;
        return this;
    }
    shrinkToFitText(shrinkToFitText) {
        this._currentItem.properties.shrinkToFitText = shrinkToFitText;
        return this;
    }
    cursor(cursor) {
        this._currentItem.properties.cursor = cursor;
        return this;
    }

    // GENERATION STOP
}
globalThis.ALL_FUNCTIONS.push(ViewLayout);