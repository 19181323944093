import { Color } from 'vortex/src';

export function getColorFromString(string) {
    let color = Color.fromStringHash(string);

    while (color.getLuminance() < 0.2) {
        string += '.';
        color = color = Color.fromStringHash(string);
    }

    return color;
}

export function makeArrayFromCounts(counts) {
    let result = [];

    for (let [item, count] of counts) {
        for (let i = 0; i < count; ++i) {
            result.push(instanciate(item));
        }
    }

    return result;
}

export function makeArray(length, makeItem) {
    let result = new Array(length);

    for (let i = 0; i < length; ++i) {
        result[i] = makeItem(i);
    }

    return result;
}

export function toArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else if (value === undefined) {
        return [];
    } else {
        return [value];
    }
}

export function instanciate(objectOrFunction, parameters) {
    if (typeof objectOrFunction === 'function') {
        return new objectOrFunction(parameters);
    } else {
        return objectOrFunction;
    }
}

export function isInstanceOf(object, classObject) {
    return object && object instanceof classObject;
}