import { Card } from '../../entities/card';
import { Effect } from '../../entities/effect';

export class StashCard extends Card {
    createdEffect = new StashEffect()
    isCommon = true
    goldCost = 0
}


class StashEffect extends Effect {
    name = 'Stash'
    description = `You do not lose your unspent gold this turn.`

    onStateChange(change, output) {
        if (change.createdEffects.includes(this)) {
            output.do(() => this.owner.retainGoldThisTurn = true)
        } else if (change.combatStart) {
            output.consumeEffect(this);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(StashCard);
globalThis.ALL_FUNCTIONS.push(StashEffect);