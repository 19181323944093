import { EventEmitter } from './event-emitter';

export class Board {
    constructor(entities = []) {
        this.nextEntityId = 1;
        this.entities = new Set(entities);
        this.animations = [];
        this.eventEmitter = new EventEmitter();
        this.opponent = null;
    }

    getAllEntities() {
        return [
            ...this.entities,
            ...this.opponent.entities
        ];
    }

    getEntityById(id, predicate = (entity) => true) {
        for (let entity of this.entities) {
            if (entity.id === id) {
                if (predicate(entity)) {
                    return entity;
                } else {
                    return null;
                }
            }
        }

        return null;
    }

    emitEvent(eventName, payload, isValidTarget, makeOutput) {
        return this.eventEmitter.emitEvent(this.entities, eventName, payload, isValidTarget, makeOutput);
    }

    inspect() {
        let entities = Array.from(this.entities).sort((e1, e2) => e1.id - e2.id);

        for (let e of entities) {
            console.log(`${e.id}: ${e.constructor.name}`)
        }
    }
}
globalThis.ALL_FUNCTIONS.push(Board);