import { PlayerStateChange } from './player-state-change';

export class ProcessEndOfCombat {
    constructor(game) {
        this.game = game;
        this.players = game.getPlayers();
    }

    onEnd() {
        let result = [];

        if (!this.game.isFinished()) {
            for (let player of this.players) {
                if (player.activeCharacter.health <= 0) {
                    player.activeCharacter.onDeath();

                    if (player.backline.characters().length > 0) {
                        this.game.wait(0.8).emitEvent(() => player.mustChooseCharacter = true);
                    } else {
                        player.hasLost = true;
                    }
                }
            }
        }

        for (let player of this.players) {
            let newGameStep = null;

            if (player.hasLost) {
                if (player.opponent.hasLost) {
                    newGameStep = 'draw';
                } else {
                    newGameStep = 'defeat';
                }
            } else if (player.opponent.hasLost) {
                newGameStep = 'victory';
            }

            if (newGameStep) {
                result.push(new PlayerStateChange(player).setGameStep(newGameStep));
            }
        }

        return result;
    }
}
globalThis.ALL_FUNCTIONS.push(ProcessEndOfCombat);