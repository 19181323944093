export class Hash {
    constructor() {
        this.value = 0;
    }

    integer(n) {
        let hash = n;

        hash = ~hash + (hash << 15);
        hash = hash ^ (hash >> 12);
        hash = hash + (hash << 2);
        hash = hash ^ (hash >> 4);
        hash = hash * 2057;
        hash = hash ^ (hash >> 16);

        this.value = (this.value + hash) & 0x3fffffff;

        return this;
    }
    
    float(n) {
        return this.integer(n * 1000000);
    }

    color(color) {
        return this
            .integer(color.r * 255 | 0)
            .integer(color.g * 255 | 0)
            .integer(color.b * 255 | 0)
            .integer(color.a * 255 | 0)
    }

    string(string) {
        for (let i = 0; i < string.length; ++i) {
            let code = string.charCodeAt(i);

            this.integer(code);
        }

        return this;
    }

    finish() {
        return this.value;
    }
}
globalThis.ALL_FUNCTIONS.push(Hash);