import { DisplaySize } from '../utils/display-size';
import { Color } from '../utils/color';

export class Graphics {
    shape = 'rectangle'
    horizontalAnchor = 'center'
    verticalAnchor = 'middle'
    offsetX = DisplaySize.zero()
    offsetY = DisplaySize.zero()
    zIndex = 0
    borderRadius = DisplaySize.zero()
    borderWidth = DisplaySize.zero()
    borderScale = 1
    borderDashLength = DisplaySize.zero()
    borderGapLength = DisplaySize.zero()
    borderColor = Color.transparent()
    borderAlpha = 1
    backgroundColor = Color.transparent()
    backgroundAlpha = 1
    overlayColor = Color.transparent()
    overlayAlpha = 1
    imageUrl = null
    imageScale = 1
    imageSpriteCountPerRow = 1
    imageSpriteCountPerColumn = 1
    imageSpriteIndex = 0
    animationStartTime = 0
    animationCurrentTime = -1
    animationDuration = 1
    text = null
    textFont = 'Arial'
    textSize = DisplaySize.scaledFromHeight(1)
    textColor = Color.black()
    textAlpha = 1
    textPadding = DisplaySize.zero()
    textMaxWidth = DisplaySize.zero()
    textMaxHeight = DisplaySize.zero()
    textHorizontalAlign = 'center'
    textVerticalAlign = 'middle'
    textBold = false
    textItalic = false
    textOffsetX = 0
    textOffsetY = 0
    textCursorIndex = -1
    textFit = false
    textAllowMultiline = false
    textFixVerticalCenter = true
    shrinkToFitText = false
    cursor = null
}
globalThis.ALL_FUNCTIONS.push(Graphics);