import { toArray } from './array';
import { DisplaySize } from './display-size';

const EPSILON = 0.000001;

export class Rect {
    constructor(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    static empty() {
        return new Rect(0, 0, 0, 0);
    }

    static fromTopLeft(x1, y1, width, height) {
        let x = x1 + width / 2;
        let y = y1 + height / 2;

        return new Rect(x, y, width, height);
    }

    static fromSize(width, height) {
        let x = width / 2.0;
        let y = height / 2.0;

        return new Rect(x, y, width, height);
    }

    static fromCorners(x1, y1, x2, y2) {
        let x = (x1 + x2) / 2;
        let y = (y1 + y2) / 2;
        let width = x2 - x1;
        let height = y2 - y1;

        return new Rect(x, y, width, height);
    }

    static fromRectList(rectList) {
        if (rectList.length === 0) {
            return Rect.empty();
        }

        let x1 = Infinity;
        let y1 = Infinity;
        let x2 = -Infinity;
        let y2 = -Infinity;

        for (let rect of rectList) {
            x1 = Math.min(x1, rect.x1());
            y1 = Math.min(y1, rect.y1());
            x2 = Math.max(x2, rect.x2());
            y2 = Math.max(y2, rect.y2());
        }

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    static mix(rectStart, rectEnd, t) {
        if (!rectStart) {
            return rectEnd;
        } else if (!rectEnd) {
            return rectStart;
        }

        let x = Math.mix(rectStart.x, rectEnd.x, t);
        let y = Math.mix(rectStart.y, rectEnd.y, t);
        let width = Math.mix(rectStart.width, rectEnd.width, t);
        let height = Math.mix(rectStart.height, rectEnd.height, t);

        return new Rect(x, y, width, height);
    }

    x1() {
        return this.x - this.width / 2;
    }

    y1() {
        return this.y - this.height / 2;
    }

    x2() {
        return this.x + this.width / 2;
    }

    y2() {
        return this.y + this.height / 2;
    }

    halfWidth() {
        return this.width / 2;
    }

    halfHeight() {
        return this.height / 2;
    }

    aspectRatio() {
        return this.width / this.height;
    }

    setX(x) {
        return new Rect(x, this.y, this.width, this.height);
    }

    setY(y) {
        return new Rect(this.x, y, this.width, this.height);
    }

    setWidth(width) {
        return new Rect(this.x, this.y, width, this.height);
    }

    setHeight(height) {
        return new Rect(this.x, this.y, this.width, height);
    }

    setWidthFromLeft(width) {
        return new Rect(this.x1() + width / 2, this.y, width, this.height);
    }

    setWidthFromRight(width) {
        return new Rect(this.x2() - width / 2, this.y, width, this.height);
    }

    setHeightFromTop(height) {
        return new Rect(this.x, this.y1() + height / 2, this.width, height);
    }

    setHeightFromBottom(height) {
        return new Rect(this.x, this.y2() - height / 2, this.width, height);
    }

    setX1(x1) {
        let x = x1 + this.width / 2;

        return new Rect(x, this.y, this.width, this.height);
    }

    setY1(y1) {
        let y = y1 + this.height / 2;

        return new Rect(this.x, y, this.width, this.height);
    }

    setX2(x2) {
        let x = x2 - this.width / 2;

        return new Rect(x, this.y, this.width, this.height);
    }

    setY2(y2) {
        let y = y2 - this.height / 2;

        return new Rect(this.x, y, this.width, this.height);
    }

    setCenter(x, y) {
        return new Rect(x, y, this.width, this.height);
    }

    setSize(width, height) {
        return new Rect(this.x, this.y, width, height);
    }

    contains(x, y) {
        return x > this.x1() && x < this.x2() && y > this.y1() && y < this.y2();
    }

    containsPoint({ x, y }) {
        return this.contains(x, y);
    }

    getHorizontalSpaceUntil(other) {
        let dx1 = other.x1() - this.x2();
        let dx2 = this.x1() - other.x2();

        return Math.max(0, dx1, dx2);
    }

    getVerticalSpaceUntil(other) {
        let dy1 = other.y1() - this.y2();
        let dy2 = this.y1() - other.y2();

        return Math.max(0, dy1, dy2);
    }

    clone() {
        return new Rect(this.x, this.y, this.width, this.height);
    }

    round() {
        let x1 = Math.round(this.x1());
        let y1 = Math.round(this.y1());
        let x2 = Math.round(this.x2());
        let y2 = Math.round(this.y2());

        return Rect.fromCorners(x1, y1, x2, y2);
    }

    translate(tx, ty) {
        let x = this.x + tx;
        let y = this.y + ty;

        return new Rect(x, y, this.width, this.height);
    }

    scale(ratioWidth, ratioHeight = ratioWidth) {
        let width = this.width * ratioWidth;
        let height = this.height * ratioHeight;

        return new Rect(this.x, this.y, width, height);
    }

    scaleWidth(ratio) {
        let width = this.width * ratio;

        return new Rect(this.x, this.y, width, this.height);
    }

    scaleHeight(ratio) {
        let height = this.height * ratio;

        return new Rect(this.x, this.y, this.width, height);
    }

    scaleTowards(cx, cy, ratioWidth, ratioHeight = ratioWidth) {
        let x = cx + (this.x - cx) * ratioWidth;
        let y = cy + (this.y - cy) * ratioHeight;
        let width = this.width * ratioWidth;
        let height = this.height * ratioHeight;

        return new Rect(x, y, width, height);
    }

    scaleTowardsTop(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x, this.y1(), ratioWidth, ratioHeight);
    }

    scaleTowardsRight(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x2(), this.y, ratioWidth, ratioHeight);
    }

    scaleTowardsBottom(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x, this.y2(), ratioWidth, ratioHeight);
    }

    scaleTowardsLeft(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x1(), this.y, ratioWidth, ratioHeight);
    }

    scaleTowardsTopLeft(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x1(), this.y1(), ratioWidth, ratioHeight);
    }

    scaleTowardsTopRight(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x2(), this.y1(), ratioWidth, ratioHeight);
    }

    scaleTowardsBottomRight(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x2(), this.y2(), ratioWidth, ratioHeight);
    }

    scaleTowardsBottomLeft(ratioWidth, ratioHeight = ratioWidth) {
        return this.scaleTowards(this.x1(), this.y2(), ratioWidth, ratioHeight);
    }

    multiply(ratio) {
        let x = this.x * ratio;
        let y = this.y * ratio;
        let width = this.width * ratio;
        let height = this.height * ratio;

        return new Rect(x, y, width, height);
    }

    pad(borderWidth, borderHeight = borderWidth) {
        let width = this.width + borderWidth * 2;
        let height = this.height + borderHeight * 2;

        return new Rect(this.x, this.y, width, height);
    }

    strip(borderWidth, borderHeight = borderWidth) {
        let width = this.width - borderWidth * 2;
        let height = this.height - borderHeight * 2;

        return new Rect(this.x, this.y, width, height);
    }

    padToMatchAspectRatio(aspectRatio) {
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToPad = 0;
        let heightToPad = 0;

        if (this.width < widthFromHeight) {
            widthToPad = widthFromHeight - this.width;
        } else {
            heightToPad = heightFromWidth - this.height;
        }

        let width = this.width + widthToPad;
        let height = this.height + heightToPad;

        return new Rect(this.x, this.y, width, height);
    }

    stripToMatchAspectRatio(aspectRatio) {
        if (!aspectRatio) {
            return this;
        }

        let widthFromHeight = this.height * aspectRatio;
        let heightFromWidth = this.width / aspectRatio;
        let widthToStrip = 0;
        let heightToStrip = 0;

        if (this.width > widthFromHeight) {
            widthToStrip = this.width - widthFromHeight;
        } else {
            heightToStrip = this.height - heightFromWidth;
        }

        let width = this.width - widthToStrip;
        let height = this.height - heightToStrip;

        return new Rect(this.x, this.y, width, height);
    }

    mirror(cx, cy) {
        let x = 2 * cx - this.x;
        let y = 2 * cy - this.y;

        return new Rect(x, y, this.width, this.height);
    }

    stripFromSides(top, right, bottom, left) {
        let x = this.x + (left - right) / 2;
        let y = this.y + (top - bottom) / 2;
        let width = this.width - left - right;
        let height = this.height - top - bottom;

        return new Rect(x, y, width, height);
    }

    extendFromSides(topRatio, rightRatio, bottomRatio, leftRatio) {
        let dx1 = -leftRatio * this.width;
        let dx2 = rightRatio * this.width;
        let dy1 = -topRatio * this.height;
        let dy2 = bottomRatio * this.height;

        return Rect.fromCorners(this.x1() + dx1, this.y1() + dy1, this.x2() + dx2, this.y2() + dy2);
    }

    splitHorizontally(widthList, margin = 0) {
        let result = [];
        let innerMargin = DisplaySize.resolve(margin, this);
        let x1 = this.x1();
        let y1 = this.y1();

        for (let value of toArray(widthList)) {
            let width = DisplaySize.resolve(value, this);
            let rect = Rect.fromTopLeft(x1, y1, width, this.height);

            result.push(rect);
            x1 += (width + innerMargin);
        }

        let lastRect = Rect.fromTopLeft(x1, y1, this.x2() - x1, this.height);

        result.push(lastRect);

        return result;
    }

    splitVertically(heightList, margin = 0) {
        let result = [];
        let innerMargin = DisplaySize.resolve(margin, this);
        let x1 = this.x1();
        let y1 = this.y1();

        for (let value of toArray(heightList)) {
            let height = DisplaySize.resolve(value, this);
            let rect = Rect.fromTopLeft(x1, y1, this.width, height);

            result.push(rect);
            y1 += (height + innerMargin);
        }

        let lastRect = Rect.fromTopLeft(x1, y1, this.width, this.y2() - y1);

        result.push(lastRect);

        return result;
    }

    _getHorizontalHeighbor(direction, width, margin) {
        let x = this.x + (this.width / 2 + width / 2 + margin) * direction;

        return new Rect(x, this.y, width, this.height);
    }

    _getVerticalNeighbor(direction, height, margin)  {
        let y = this.y + (this.height / 2 + height / 2 + margin) * direction;

        return new Rect(this.x, y, this.width, height);
    }

    getLeftNeighbor(width, margin = 0)  {
        return this._getHorizontalHeighbor(-1, width, margin)
    }

    getRightNeighbor(width, margin = 0)  {
        return this._getHorizontalHeighbor(1, width, margin)
    }

    getTopNeighbor(height, margin = 0)  {
        return this._getVerticalNeighbor(-1, height, margin)
    }

    getBottomNeighbor(height, margin = 0)  {
        return this._getVerticalNeighbor(1, height, margin)
    }

    getTopLeftBadge(width, height = width) {
        return new Rect(this.x1(), this.y1(), width, height);
    }

    getTopRightBadge(width, height = width) {
        return new Rect(this.x2(), this.y1(), width, height);
    }

    getBottomRightBadge(width, height = width) {
        return new Rect(this.x2(), this.y2(), width, height);
    }

    getBottomLeftBadge(width, height = width) {
        return new Rect(this.x1(), this.y2(), width, height);
    }

    computeGridLayout(parameters) {
        let {
            itemCount = 1,
            itemCountPerRow = null,
            itemCountPerColumn = null,
            minItemCountPerRow = 0,
            minItemCountPerColumn = 0,
            itemAspectRatio = 1,
            margin = null,
            outerMargin = null,
            innerMargin = null,
            horizontalOuterMargin = null,
            verticalOuterMargin = null,
            horizontalInnerMargin = null,
            verticalInnerMargin = null,
            topToBottom = true,
            horizontalAlign = 0.5,
            verticalAlign = 0.5,
        } = parameters;

        let rowSize = itemCountPerRow;
        let columnSize = itemCountPerColumn;

        if (!rowSize && !columnSize) {
            let gridAspectRatio = this.aspectRatio();

            [rowSize, columnSize] = getItemCounts(itemCount, itemAspectRatio, gridAspectRatio);
        } else if (!columnSize) {
            columnSize = Math.ceil(itemCount / rowSize);
        } else if (!rowSize) {
            rowSize = Math.ceil(itemCount / columnSize);
        }

        if (minItemCountPerRow > rowSize && minItemCountPerColumn > columnSize) {
            rowSize = minItemCountPerRow;
            columnSize = minItemCountPerColumn
        } else if (minItemCountPerRow > rowSize) {
            rowSize = minItemCountPerRow;
            columnSize = Math.max(minItemCountPerColumn, Math.ceil(itemCount / rowSize));
        } else if (minItemCountPerColumn > columnSize) {
            columnSize = minItemCountPerColumn;
            rowSize = Math.max(minItemCountPerRow, Math.ceil(itemCount / columnSize));
        }

        let displayedItemCount = Math.min(itemCount, Math.round(rowSize * columnSize));
        let itemCountPerStep = rowSize;

        if (!topToBottom) {
            itemCountPerStep = columnSize;
        }

        horizontalOuterMargin = horizontalOuterMargin ?? outerMargin ?? margin ?? 0;
        verticalOuterMargin = verticalOuterMargin ?? outerMargin ?? margin ?? 0;
        horizontalInnerMargin = horizontalInnerMargin ?? innerMargin ?? margin ?? 0;
        verticalInnerMargin = verticalInnerMargin ?? innerMargin ?? margin ?? 0;

        let itemWidth = (this.width - (horizontalOuterMargin * 2) - (horizontalInnerMargin * (rowSize - 1))) / rowSize;
        let itemHeight = (this.height - (verticalOuterMargin * 2) - (verticalInnerMargin * (columnSize - 1))) / columnSize;
        let itemRect = new Rect(0, 0, itemWidth, itemHeight).stripToMatchAspectRatio(itemAspectRatio);
        itemWidth = itemRect.width;
        itemHeight = itemRect.height;

        rowSize = Math.floor((this.width - 2 * horizontalOuterMargin - horizontalInnerMargin) / (itemWidth + horizontalInnerMargin));
        columnSize = Math.floor((this.height - 2 * verticalOuterMargin - verticalInnerMargin) / (itemHeight + verticalInnerMargin));

        let leftoverWidth = this.width - (horizontalOuterMargin * 2) - (horizontalInnerMargin * (rowSize - 1)) - (itemWidth * rowSize);
        let leftoverHeight = this.height - (verticalOuterMargin * 2) - (verticalInnerMargin * (columnSize - 1)) - (itemHeight * columnSize);

        horizontalOuterMargin += leftoverWidth * horizontalAlign;
        verticalOuterMargin += leftoverHeight * verticalAlign;

        let xStart = this.x1() + horizontalOuterMargin;
        let yStart = this.y1() + verticalOuterMargin;
        let horizontal = !topToBottom;
        let cells = [];

        for (let i = 0; i < displayedItemCount; ++i) {
            let xIndex = i % itemCountPerStep;
            let yIndex = Math.floor(i / itemCountPerStep);

            if (horizontal) {
                let tmp = xIndex;
                xIndex = yIndex;
                yIndex = tmp;
            }

            let itemX = xStart + xIndex * (horizontalInnerMargin + itemWidth) + (itemWidth / 2);
            let itemY = yStart + yIndex * (verticalInnerMargin + itemHeight) + (itemHeight / 2);

            itemRect = itemRect.setCenter(itemX, itemY);

            cells.push(itemRect);
        }

        let finalGridRect = Rect.fromRectList(cells);
        let dx = (this.width - horizontalOuterMargin * 2) - finalGridRect.width;
        let dy = (this.height - verticalOuterMargin * 2) - finalGridRect.height;

        for (let rect of cells) {
            rect.x += dx * horizontalAlign;
            rect.y += dy * verticalAlign;
        }

        return {
            cells,
            itemCountPerRow: rowSize,
            itemCountPerColumn: columnSize
        };
    }
}

function getItemCounts(itemCount, itemAspectRatio, gridAspectRatio) {
    // TODO: minimize empty space on the sides
    let itemWidth = itemAspectRatio / gridAspectRatio;
    let itemHeight = 1;
    let totalWidth = itemWidth;
    let totalHeight = itemHeight;
    let w = 1;
    let h = 1;

    while (w * h < itemCount) {
        if (totalWidth <= totalHeight || Math.floor(totalWidth + itemWidth) === Math.floor(totalWidth)) {
            w += 1;
            totalWidth += itemWidth;
        } else {
            h += 1;
            totalHeight += itemHeight;
        }
    }

    return [w, h];
}
globalThis.ALL_FUNCTIONS.push(Rect);