import { Card } from '../../entities/card';
import { Effect } from '../../entities/effect';

export class FinalGambitCard extends Card {
    createdEffect = new FinalGambitEffect()
    isCommon = true
    goldCost = 0
}

class FinalGambitEffect extends Effect {
    name = 'Final gambit'
    description = `At the end of this turn, destroy your active character.`

    onStateChange(change, output) {
        if (change.combatEnd) {
            if (this.owner.activeCharacter.health > 0) {
                output
                    .setSource(this)
                    .destroyCharacter(this.owner.activeCharacter)
            }

            output.consumeEffect(this);
        }
    }
}
globalThis.ALL_FUNCTIONS.push(FinalGambitCard);
globalThis.ALL_FUNCTIONS.push(FinalGambitEffect);