import { CHARACTER_ASPECT_RATIO, DEFAULT_BORDER_RADIUS, HEADER_HEIGHT } from '../constants/graphics-constants';
import { Panel } from './panel';

export class Backline extends Panel {
    header = new BacklineHeader(this)
    headerSize = HEADER_HEIGHT
    outerMargin = 10
    innerMargin = 10
    itemAspectRatio = CHARACTER_ASPECT_RATIO
    itemCountPerColumn = 1
    minItemCountPerRow = 3

    characters() {
        return this.layoutInfo.items;
    }

    render({ view }) {
        view
            .backgroundColor(this.owner.getPanelColor())
            .borderColor('black')
            .borderWidth(2)
            .borderRadius(DEFAULT_BORDER_RADIUS)
    }
}

class BacklineHeader {
    constructor(backline) {
        this.backline = backline;
    }

    render({ view }) {
        let text = this.backline.owner.isOpponent ? "Opponent backline" : "Your backline (❓)"

        view
            .text(text)
            .textSize('50%')
    }

    getTooltip() {
        
    }
}
globalThis.ALL_FUNCTIONS.push(Backline);
globalThis.ALL_FUNCTIONS.push(BacklineHeader);