import { Card } from '../entities/card';
import { Character } from '../entities/character';
import { Effect } from '../entities/effect';
import { PlayerStateChange } from '../events/player-state-change';

/**
* @callback ApplyUpgradeCallback
* @param {Character} character
* @param {PlayerStateChange} output
*/

/**
 * @typedef {object} MakeUpgradeCardOptions
 * @property {string} [name]
 * @property {string} description
 * @property {string} [color]
 * @property {number} [triggerIndex]
 * @property {ApplyUpgradeCallback} [applyUpgrade]
 * @property {import('./make-spell-card').EffectOnStateChangeCallback} [onStateChange]
 * @property {import('./make-spell-card').EffectOnStateChangeCallback} [onAfterStateChange]
 */

/**
* 
* @param {MakeUpgradeCardOptions} options 
* @returns 
*/
export function makeUpgradeCard(options) {
    let {
        name,
        description = null,
        color = null,
        triggerIndex = 0,
        applyUpgrade = (character, output) => {},
        onStateChange = (change, output) => {},
        onAfterStateChange = (change, output) => {},
    } = options;

    description = `${getUpgradeKeywordDescription()}\n\n${description}`;

    let UpgradeClass = class extends Effect {
        name = name
        description = description
        color = color
        icon = '⬆'
        triggerIndex = triggerIndex
        character = null
        isUpgrade = true

        onStateChange(change, output) {
            if (change.createdEffects.includes(this)) {
                this.character = this.owner.activeCharacter;
                this.character.availableUpgradeCards = this.character.availableUpgradeCards.filter(card => !(card instanceof UpgradeCardClass));

                for (let upgradeCard of this.character.availableUpgradeCards) {
                    upgradeCard.goldCost += 2;
                }

                output.makeInstant();
                applyUpgrade(this.character, output);
            } else if ((change.givenCards.length > 0 && this.owner.activeCharacter === this.character) || change.newActiveCharacter === this.character) {
                output.makeInstant();
                applyUpgrade(this.character, output);
            }

            if (this.owner.activeCharacter === this.character) {
                onStateChange.call(this, change, output);
            }
        }

        onAfterStateChange(change, output) {
            if (this.owner.activeCharacter === this.character) {
                onAfterStateChange.call(this, change, output);
            }
        }
    };

    let UpgradeCardClass = class extends Card {
        createdEffect = new UpgradeClass()
        isUpgrade = true
        goldCost = 2
    };

    return UpgradeCardClass;
}

function getUpgradeKeywordDescription() {
    return `*Upgrade* @0.85{_(permanent effect tied to the character that unlocks it)._}`;
}