import { instanciate } from '../utils/object';

export class Gear {
    categories = []

    static from(value) {
        if (Array.isArray(value)) {
            value = { categories: value };
        }

        let result = new Gear();

        Object.assign(result, value);

        result.categories = result.categories.map(category => GearCategory.from(category));

        return result;
    }

    getItemById(id) {
        for (let category of this.categories) {
            for (let item of category.items) {
                if (item.id === id) {
                    return item;
                }
            }
        }

        return null;
    }

    getItemCategory(item) {
        for (let category of this.categories) {
            if (category.items.includes(item)) {
                return category;
            }
        }

        return null;
    }

    getSelectedGearItemCount(user, itemId) {
        return user.selectedGear[itemId] || 0;
    }

    getDefaultSelectedGear() {
        let result = {};

        for (let category of this.categories) {
            if (category.items.length) {
                result[category.items[0].id] = 1;
            }
        }

        return result;
    }

    makeUserGear(selectedGear) {
        let result = [];

        for (let [id, count] of Object.entries(selectedGear)) {
            if (count > 0) {
                let item = this.getItemById(id);

                result.push(instanciate(item));
            }
        }

        return result;
    }

    isSelectedGearValid(user) {
        let valid = true;

        for (let category of this.categories) {
            let selectedItemCountInCategory = 0;

            for (let item of category.items) {
                let maxSelectedCount = item.maxSelectedCount ?? 1;
                let selectedItemCount = this.getSelectedGearItemCount(user, item.id);

                selectedItemCountInCategory += selectedItemCount;

                if (selectedItemCount > maxSelectedCount) {
                    valid = false;
                }
            }

            if (selectedItemCountInCategory !== category.requiredItemCount) {
                valid = false;
            }
        }

        return valid;
    }
}

export class GearCategory {
    name = ''
    items = []
    requiredItemCount = 1
    itemCountPerRow = null
    itemCountPerColumn = null
    backgroundColor = null

    static from(value) {
        if (typeof value === 'string') {
            value = { name: value };
        }

        if (!value.name) {
            throw new Error('gear categories must have a `name` properties');
        }

        let result = new GearCategory();

        Object.assign(result, value);

        result.items = result.items.map(item => {
            if (typeof item === 'string') {
                item = { id: item };
            }

            if (!item.id) {
                throw new Error('gear items must have a `id` properties');
            }

            return item;
        });

        return result;
    }
}
globalThis.ALL_FUNCTIONS.push(Gear);
globalThis.ALL_FUNCTIONS.push(GearCategory);