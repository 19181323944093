import { Grid, Tooltip } from 'vortex';
import { CARD_TOOLTIP_HEIGHT, CARD_TOOLTIP_WIDTH } from '../constants/graphics-constants';
import { Card } from '../entities/card';
import { Overlay } from './overlay';

export class CardSelectionOverlay extends Overlay {
    shown = false
    shownCards = []
    selectedCards = []
    grid = new Grid({
        headerSize: 0,
        outerMargin: 20,
        innerMargin: 30,
        itemAspectRatio: CARD_TOOLTIP_WIDTH / CARD_TOOLTIP_HEIGHT,
        // direction: 'left-to-right'
        // backgroundColor: 'pink'
    })
    text = ''
    okButton = new OkButton(this)
    cancelButton = new CancelButton(this)
    minRequiredSelectionCount = 1
    maxRequiredSelectionCount = 1
    itemKindName = null
    isValidOption = (card) => true
    getSubText = (card) => null
    onComplete = null
    onCancel = null
    allowCancel = false

    show(options) {
        let {
            cards,
            minRequiredSelectionCount = 1,
            maxRequiredSelectionCount = 1,
            allowCancel = true,
            rowSize = null,
            columnSize = 1,
            minRowSize = null,
            minColumnSize = null,
            itemKindName = 'card',
            isValidOption = (card) => true,
            getSubText = (card) => null,
            onComplete = null,
            onCancel = null
        } = options;

        this.hide();

        this.shown = true;
        this.selectedCards = [];
        this.shownCards = cards;
        this.minRequiredSelectionCount = minRequiredSelectionCount;
        this.maxRequiredSelectionCount = maxRequiredSelectionCount;
        this.itemKindName = itemKindName;
        this.isValidOption = isValidOption;
        this.getSubText = getSubText;
        this.onComplete = onComplete;
        this.onCancel = onCancel;
        this.allowCancel = allowCancel;

        this.grid.items = cards.map(card => new CardSelectionOverlaySlot(this, card));
        this.grid.itemCountPerRow = rowSize;
        this.grid.itemCountPerColumn = columnSize;
        this.grid.minItemCountPerRow = minRowSize;
        this.grid.minItemCountPerColumn = minColumnSize;

        if (minRequiredSelectionCount === 1 && maxRequiredSelectionCount === 1) {
            this.text = `Choose a ${this.itemKindName}:`;
        } else if (minRequiredSelectionCount === maxRequiredSelectionCount) {
            this.text = `Choose ${minRequiredSelectionCount} ${this.itemKindName}s:`;
        } else {
            this.text = `Choose up to ${maxRequiredSelectionCount} ${this.itemKindName}s:`;
        }
    }

    hide() {
        if (this.shown && this.allowCancel) {
            this.onCancel?.();
            this.shown = false;
        }
    }

    selectCard(card) {
        if (this.selectedCards.includes(card)) {
            this.selectedCards.remove(card);
        } else {
            this.selectedCards.push(card);

            if (this.selectedCards.length > this.maxRequiredSelectionCount) {
                this.selectedCards.shift();
            }

            if (this.minRequiredSelectionCount === 1 && this.maxRequiredSelectionCount === 1) {
                this.complete();
            }
        }
    }

    complete() {
        this.onComplete(this.selectedCards);
        this.shown = false;
    }

    render({ view }) {
        if (!this.shown) {
            view.setPointerBehavior('none');
            return;
        }

        let buttons = [];

        if (this.allowCancel) {
            buttons.push(this.cancelButton);
        }

        if (this.minRequiredSelectionCount !== 1 || this.maxRequiredSelectionCount !== 1) {
            buttons = [this.okButton, this.cancelButton];
        }

        view
            .setPointerBehavior('opaque')
            .backgroundColor('black')
            .backgroundAlpha(0.7)

        view.layout()
            .topToBottom()
            .margin('2%')
            .push()
            .force(1)
            .text(this.text)
            .textColor('white')
            .textSize('70%')
            .push(this.grid)
            .force(10)
            .width('70%w')
            .push()
            .force(1)
            .centerToRight()
            .childAspectRatio(3)
            .innerMargin(20)
            .push(buttons)
    }

    onKeyDown({ code }) {
        if (!this.shown) {
            return false;
        }
        
        if (code === 'Escape') {
            this.hide();
        }

        return true;
    }
}

class CardSelectionOverlaySlot {
    constructor(overlay, card) {
        this.overlay = overlay;
        this.card = card;
    }

    render(evt) {
        let isSelected = this.overlay.selectedCards.includes(this.card);
        let isDisabled = !this.overlay.isValidOption(this.card);

        let { topRightBadgeRect } = Tooltip.render(evt.view, this.card.getTooltip(evt), null);

        evt.view
            .setDisabled(isDisabled)
            .hoverCursor('pointer');
        
        if (isSelected) {
            evt.view
                .borderScale(2)
                .borderColor('red')
        } else {
            evt.view.hoverBorderScale(2);
        }

        if (isDisabled) {
            evt.view.renderChild(({ view }) => {
                view
                    .backgroundColor('black')
                    .backgroundAlpha(0.3)
            });

            if (topRightBadgeRect) {
               evt.view.renderChild(({ view }) => {
                   view
                       .backgroundColor('black')
                       .backgroundAlpha(0.3)
                       .borderRadius('50%')
                       .zIndex(1)
               }, topRightBadgeRect); 
            }
        }

        let subText = this.overlay.getSubText(this.card);
        let color = isDisabled ? 'red' : 'white';

        if (subText) {
            let rect = evt.view.rect.getBottomNeighbor(35, 5);

            evt.view.renderChild(({ view }) => {
                view
                    .text(subText)
                    .textColor(color)
                    .textSize('70%')
                    .backgroundColor('black')
                    .backgroundAlpha(0.3)
                    .borderRadius(10)
                    .textPadding(10)
                    .shrinkToFitText(true)
                    .verticalAnchor('top')
            }, rect);
        }
    }

    // onPostRender({ view }) {
    //     let isDisabled = !this.overlay.isValidOption(this.card);

    //     if (isDisabled) {
    //         view
    //             .setDisabled(true)
    //             .traverse(view => view.overlayColor('rgba(0,0,0,0.3)'));
    //     }
    // }

    onClick() {
        this.overlay.selectCard(this.card);

        return true;
    }
}

class OkButton {
    constructor(overlay) {
        this.overlay = overlay;
    }

    render({ view }) {
        let selectedCount = this.overlay.selectedCards.length;
        let isDisabled = selectedCount < this.overlay.minRequiredSelectionCount;

        view
            .text('Ok')
            .backgroundColor('white')
            .borderColor('black')
            .borderRadius(5)
            .borderRadius('50%')
            .borderWidth(2)
            .textSize('50%')
            .hoverCursor('pointer')
            .disabledOverlayColor('black')
            .disabledOverlayAlpha(0.3)
            .hoverBorderScale(2)
            .setDisabled(isDisabled)
    }

    onClick() {
        this.overlay.complete();
    }
}

class CancelButton {
    constructor(overlay) {
        this.overlay = overlay;
    }

    render({ view }) {
        view
            .text('Cancel')
            .backgroundColor('white')
            .borderColor('black')
            .borderRadius('50%')
            .textSize('50%')
            .borderWidth(2)
            .hoverCursor('pointer')
            .hoverBorderScale(2)
    }

    onClick() {
        this.overlay.hide();
    }
}
globalThis.ALL_FUNCTIONS.push(CardSelectionOverlay);
globalThis.ALL_FUNCTIONS.push(CardSelectionOverlaySlot);
globalThis.ALL_FUNCTIONS.push(OkButton);
globalThis.ALL_FUNCTIONS.push(CancelButton);