import { Entity } from 'vortex';
import { Card } from '../entities/card';
import { Effect } from '../entities/effect';
import { PlayerStateChange } from '../events/player-state-change';

/**
* @callback TriggerSpellEffectCallback
* @param {Card} this
* @param {PlayerStateChange} output
* @param {Entity[]} targets
*/

/**
* @callback EffectOnStateChangeCallback
* @param {Effect} this
* @param {PlayerStateChange} change
* @param {PlayerStateChange} output
*/

/**
 * @typedef {object} MakeSpellCardOptions
 * @property {string} [name]
 * @property {string} [description]
 * @property {string} [color]
 * @property {number} [goldCost]
 * @property {number} [sacrificeCost]
 * @property {TriggerSpellEffectCallback} [triggerEffect]
 * @property {EffectOnStateChangeCallback} [effectOnStateChange]
 */

/**
 * 
 * @param {MakeSpellCardOptions} options 
 * @returns 
 */
export function makeSpellCard(options) {
    let {
        name,
        description = null,
        color = null,
        goldCost = 0,
        sacrificeCost = 0,
        triggerEffect = (output, targets) => {},
        effectOnStateChange = null,
    } = options;

    let EffectClass = null;

    if (effectOnStateChange) {
        EffectClass = class extends Effect {
            name = name
            description = description
            color = color

            onStateChange(change, output) {
                effectOnStateChange.call(this, change, output);
            }
        }
    }

    let SpellCardClass = class extends Card {
        name = name
        description = description
        color = color
        goldCost = goldCost
        sacrificeCost = sacrificeCost
        createdEffect = EffectClass ? new EffectClass() : null

        triggerEffect(output, targets) {
            triggerEffect.call(this, output, targets);
        }
    };

    return SpellCardClass;
}