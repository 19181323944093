import { CARD_ASPECT_RATIO, HISTORY_PANEL_TURN_NUMBER_WIDTH } from '../constants/graphics-constants';

export class HistoryTurn {
    constructor(number) {
        this.number = number;
        this.selfActions = [];
        this.opponentActions = [];
    }

    actions(isOpponent) {
        if (isOpponent) {
            return this.opponentActions;
        } else {
            return this.selfActions;
        }
    }

    render({ view }) {
        view.layout()
            .leftToRight()
            .push()
            .width(HISTORY_PANEL_TURN_NUMBER_WIDTH)
            .text(this.number.toString())
            .textSize('40%')
            .push()
            .centerToRight()
            .childAspectRatio(CARD_ASPECT_RATIO)
            .innerMargin(15)
            .push(this.selfActions)
            .back()
            .push()
            .centerToRight()
            .childAspectRatio(CARD_ASPECT_RATIO)
            .innerMargin(15)
            .push(this.opponentActions)
    }
}
globalThis.ALL_FUNCTIONS.push(HistoryTurn);