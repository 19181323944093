import { DisplaySize } from './display-size';
import { Rect } from './rect';

export const LEFT_TO_RIGHT = 'left-to-right';
export const RIGHT_TO_LEFT = 'right-to-left';
export const TOP_TO_BOTTOM = 'top-to-bottom';
export const BOTTOM_TO_TOP = 'bottom-to-top';

export class LayoutItem {
    constructor(parent, object = null) {
        this.parent = parent;
        this.object = object;
        this.properties = {};
        this.rect = null;
        this.width = null;
        this.height = null;
        this.aspectRatio = null;
        this.force = 1;
        this.innerMargin = 0;
        this.outerMargin = 0;
        this.direction = null;
        this.alignment = 0.5;
        this.subAlignment = 0.5;
        this.childForce = 1;
        this.childAspectRatio = null;
        this.childWidth = null;
        this.childHeight = null;
        this.children = [];

        this._computedWidth = 0;
        this._computedHeight = 0;
    }

    setRect(rect, rootRect) {
        this.rect = rect.clone();

        if (!this.direction || !this.children.length) {
            return;
        }

        let outerMargin = DisplaySize.resolve(this.outerMargin, rootRect);
        let innerMargin = DisplaySize.resolve(this.innerMargin, rootRect);

        let parentRect = rect.strip(outerMargin);
        let isHorizontal = this.direction === LEFT_TO_RIGHT || this.direction === RIGHT_TO_LEFT;
        let isReverse = this.direction === RIGHT_TO_LEFT || this.direction === BOTTOM_TO_TOP;
        let flexSize = isHorizontal ? parentRect.width : parentRect.height;
        let fixedSize = isHorizontal ? parentRect.height : parentRect.width;
        let availableSize = flexSize - (this.children.length - 1) * innerMargin;
        let consumedSize = 0;
        let totalForce = 0;

        for (let child of this.children) {
            let childWidth = DisplaySize.resolve(child.width, rootRect);
            let childHeight = DisplaySize.resolve(child.height, rootRect);

            if (isHorizontal && !childHeight && (!childWidth || !child.aspectRatio)) {
                childHeight = parentRect.height;
            } else if (!isHorizontal && !childWidth && (!childHeight || !child.aspectRatio)) {
                childWidth = parentRect.width;
            }

            if (child.aspectRatio) {
                if (!childHeight && childWidth) {
                    childHeight = childWidth / child.aspectRatio;
                } else if (!childWidth && childHeight) {
                    childWidth = childHeight * child.aspectRatio;
                }
            }

            if (isHorizontal && childWidth) {
                consumedSize += childWidth;
            } else if (!isHorizontal && childHeight) {
                consumedSize += childHeight;
            } else {
                totalForce += child.force;
            }

            child._computedWidth = childWidth;
            child._computedHeight = childHeight;
        }

        let availableSizeForForce = availableSize - consumedSize;
        let x = parentRect.x1();
        let y = parentRect.y1();
        let progressMultiplier = 1;
        let reverseMultiplier = 0;
        let alignmentMultiplier = Math.clamp(this.alignment, 0, 1);
        let subAlignmentMultiplier = Math.clamp(this.subAlignment, 0, 1);

        if (isReverse) {
            progressMultiplier = -1;
            reverseMultiplier = 1;

            if (isHorizontal) {
                x = parentRect.x2();
            } else {
                y = parentRect.y2();
            }
        }

        if (totalForce == 0) {
            let offset = availableSizeForForce * alignmentMultiplier * progressMultiplier;

            if (isHorizontal) {
                x += offset;
            } else {
                y += offset;
            }
        }

        for (let child of this.children) {
            let childWidth = child._computedWidth;
            let childHeight = child._computedHeight;
            let childX = x;
            let childY = y;

            if (isHorizontal && !childWidth) {
                childWidth = availableSizeForForce * child.force / totalForce;
            } else if (!isHorizontal && !childHeight) {
                childHeight = availableSizeForForce * child.force / totalForce;
            }

            if (isHorizontal) {
                childX -= (childWidth * reverseMultiplier);
                childY += (parentRect.height - childHeight) * subAlignmentMultiplier;
                x += (childWidth + innerMargin) * progressMultiplier;
            } else {
                childY -= (childHeight * reverseMultiplier);
                childX += (parentRect.width - childWidth) * subAlignmentMultiplier;
                y += (childHeight + innerMargin) * progressMultiplier;
            }

            let childRect = Rect.fromTopLeft(childX, childY, childWidth, childHeight);

            child.setRect(childRect, rootRect);
        }
    }

    collect(accumulator) {
        accumulator.push([this.object, this.rect, this.properties]);

        for (let child of this.children) {
            child.collect(accumulator);
        }
    }

    _computeBoundingBox(boundingBox) {
        if (this.children.isEmpty()) {
            let x1 = Math.min(boundingBox.x1(), this.rect.x1());
            let y1 = Math.min(boundingBox.y1(), this.rect.y1());
            let x2 = Math.min(boundingBox.x2(), this.rect.x2());
            let y2 = Math.min(boundingBox.y2(), this.rect.y2());

            boundingBox.set(Rect.fromCorners(x1, y1, x2, y2));
        }
    }
}
globalThis.ALL_FUNCTIONS.push(LayoutItem);