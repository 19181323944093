export const AUTO_MATCHMAKING = false;
export const TIME_SPEED_UP_RATIO = 1;

export const PANEL_INDEX = 0;
export const CHARACTER_INDEX = 1;
export const MINION_INDEX = 2;
export const EFFECT_INDEX = 3;
export const CARD_INDEX = 4;
export const HITBOX_INDEX = 5;
export const OVERLAY_INDEX = 6;

export const STARTING_INCOME = 3;
export const STARTING_CHARACTER_HEALTH = 20;
export const CARD_COUNT_PER_TURN = 4;
export const DECK_PREVIEW_CARD_COUNT = 3;
export const GOLD_COUNT_ON_RECYCLE = 0;
export const GOLD_COUNT_ON_STASH = 1;
export const GOLD_COUNT_ON_STASH_RECYCLED = 1;
export const GOLD_COUNT_REQUIRED_FOR_CARD = 4;
export const EMBLEM_COST = 8;