import { Color, Entity } from 'vortex';
import { CardTooltip } from '../card-tooltip';
import { MINION_INDEX } from '../constants/game-constants';
import { getColorFromString } from '../utils';

export class Minion extends Entity {
    name = "<minion>"
    description = null
    power = 1
    goldCost = 1
    poisonous = false
    indestructible = false
    playerDamageModifier = 1
    minionDamageModifier = 1
    receivedDamageModifier = 1
    immuneToWeakerMinions = false
    bypassCombat = false
    fixedPowerDivider = null
    color = null
    upgraded = false

    _generateEmptyFields() {
        if (!this.color) {
            this.color = getColorFromString(this.name)
        }

        if (!this.description) {
            this.description = "|-|";
        }
    }

    getNeighbors(includeSelf = false) {
        if (this.destroyed) {
            return [];
        }

        let minions = this.owner.battlefield.minions();
        let index = minions.indexOf(this);
        let result = [];

        if (index > 0) {
            result.push(minions[index - 1]);
        }

        if (includeSelf) {
            result.push(this);
        }

        if (index < minions.length - 1) {
            result.push(minions[index + 1])
        }

        return result;
    }

    getNeighbor(relativeIndex) {
        let minions = this.owner.battlefield.minions();
        let index = minions.indexOf(this);

        return minions[index + relativeIndex] ?? null;
    }

    makeCopy() {
        // @ts-ignore
        let clone = new this.constructor();

        clone.power = this.power;
        clone.upgraded = this.upgraded;
        clone.name = this.name;
        clone.description = this.description;

        return clone;
    }

    getOrderIndex() {
        return MINION_INDEX;
    }

    render({ view }) {
        this._generateEmptyFields();

        let icon = this.power.toString();

        if (this.power <= 0) {
            icon = '💀';
        }

        let color = this.color;

        if (this.name == "Token") {
            color = Color.white();
        }

        view
            .backgroundColor(color)
            .text(icon)
            .textSize('50%')
            .borderColor('black')
            .borderWidth(2)

        let bottomTexts = [];

        if (this.poisonous) {
            bottomTexts.push('☢');
        }

        if (this.indestructible) {
            bottomTexts.push('⛨');
        }

        if (view.rect && this.getContainer() && bottomTexts.length > 0) {
            let text = bottomTexts.join(" ");

            view.layout()
                .bottomToTop()
                .outerMargin('4%')
                .push()
                .height('20%')
                .text(text)
                .textHorizontalAlign('right')
                // .text_bold(true)
        }
    }

    getTooltip() {
        return new CardTooltip({
            title: this.name,
            image: this.makePlainCopy(),
            description: this.description
        });
    }

    canInteractWithStateChange(change) {
        return (!this.destroyed || change.sacrificedMinions.includes(this) || change.killedMinions.includes(this));
    }

    onStateChange(change, output) {

    }

    upgrade() {

    }
}
globalThis.ALL_FUNCTIONS.push(Minion);