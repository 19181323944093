import { Panel } from './panel';

export class EffectList extends Panel {
    horizontalAlign = 0
    itemAspectRatio = 1

    effects() {
        return this.layoutInfo.items;
    }

    render({ view }) {
        // view.backgroundColor('white');
    }
}
globalThis.ALL_FUNCTIONS.push(EffectList);