import { Card } from '../../entities/card';

export class RecruitCard extends Card {
    name = 'Recruit'
    description = 'Select a character and add it to your backline.\n\nDouble the cost of *Recruit* permanently.'
    isCommon = true
    goldCost = 2
    minTargetCount = 1
    maxTargetCount = 1

    getValidTargets() {
        return this.owner.availableCharacters;
    }

    getGoldCost() {
        let cost = this.goldCost;

        for (let i = 0; i < this.owner.recruitedCharacterCount; ++i) {
            cost *= 2;
        }

        return cost;
    }

    triggerEffect(output, targets) {
        output.recruitCharacter(targets[0]);
    }
}
globalThis.ALL_FUNCTIONS.push(RecruitCard);