import { ProcessEndOfCombat } from './process-end-of-combat';

export class AfterCombat {
    constructor(game) {
        this.game = game;
    }

    onEnd() {
        let additionalCombatCount = 0;

        for (let player of this.game.getPlayers()) {
            additionalCombatCount += player.additionalCombatCountThisTurn;
        }

        for (let i = 0; i < additionalCombatCount; ++i) {
            this.game.triggerCombat();
        }
    }

    onPostTrigger() {
        return new ProcessEndOfCombat(this.game);
    }
}
globalThis.ALL_FUNCTIONS.push(AfterCombat);