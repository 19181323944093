import { DisplaySize } from '../index';
import { Rect } from '../utils/rect';
import { DEFAULT_TOOLTIP_BADGE_SIZE, DEFAULT_TOOLTIP_BADGE_TEXT_SIZE, DEFAULT_TOOLTIP_BOTTOM_LIST_SCALE, DEFAULT_TOOLTIP_DESCRIPTION_TEXT_SIZE, DEFAULT_TOOLTIP_HEIGHT, DEFAULT_TOOLTIP_MARGIN, DEFAULT_TOOLTIP_TITLE_TEXT_SIZE, DEFAULT_TOOLTIP_WIDTH, WINDOW_HEIGHT, WINDOW_WIDTH } from './constants';

export class Tooltip {
    static render(view, tooltip, target) {
        if (!tooltip) {
            return;
        }

        if (typeof tooltip === 'string' || Array.isArray(tooltip)) {
            tooltip = { description: tooltip };
        }

        let description = tooltip.description ?? '';

        if (Array.isArray(description)) {
            description = description.map(line => `🞄 ${line}`).join("\n");
        }

        let tooltipWidth = tooltip.width ?? DEFAULT_TOOLTIP_WIDTH;
        let tooltipHeight = tooltip.height ?? DEFAULT_TOOLTIP_HEIGHT;
        let tooltipMargin = tooltip.margin ?? DEFAULT_TOOLTIP_MARGIN ;
        let descriptionTextSize = tooltip.descriptionTextSize ?? DEFAULT_TOOLTIP_DESCRIPTION_TEXT_SIZE;
        let titleTextSize = tooltip.titleTextSize ?? DEFAULT_TOOLTIP_TITLE_TEXT_SIZE;
        let topRightBadgeText = tooltip.topRightBadge;
        let topRightBadgeTextSize = tooltip.topRightBadgeTextSize ?? DEFAULT_TOOLTIP_BADGE_TEXT_SIZE;
        let topRightBadgeSize = tooltip.topRightBadgeSize ?? DEFAULT_TOOLTIP_BADGE_SIZE;
        let bottomList = tooltip.bottomList ?? [];
        let bottomListScale = tooltip.bottomListScale ?? DEFAULT_TOOLTIP_BOTTOM_LIST_SCALE;
        let result = {};

        if (target) {
            let targetRect = target.rect;
            let totalTooltipWidth = tooltipWidth + tooltipMargin;
            let displayOnLeft = targetRect.x2() + totalTooltipWidth > WINDOW_WIDTH;
            let horizontalAnchor = 'left';
            let x = targetRect.x2() + tooltipMargin;
            let y = targetRect.y;

            if (displayOnLeft) {
                horizontalAnchor = 'right';
                x = targetRect.x1() - tooltipMargin;
            }

            if (tooltip.image) {
                if (targetRect.y + tooltipHeight / 2 + tooltipMargin >= WINDOW_HEIGHT) {
                    y = targetRect.y2() - tooltipHeight / 2;
                } else if (targetRect.y - tooltipHeight / 2 - tooltipMargin < 0) {
                    y = targetRect.y1() + tooltipHeight / 2;
                }

                if (displayOnLeft) {
                    x = targetRect.x1() - tooltipMargin - tooltipWidth / 2;
                } else {
                    x = targetRect.x2() + tooltipMargin + tooltipWidth / 2;
                }

                horizontalAnchor = 'center';
            }

            view
                .setRect(new Rect(x, y, tooltipWidth, tooltipHeight))
                .horizontalAnchor(horizontalAnchor)
        }

        topRightBadgeSize = DisplaySize.resolve(topRightBadgeSize, view.rect);

        view
            .backgroundColor('white')
            .borderWidth('1r')
            .borderColor('black')
            .borderRadius('3%w')

        if (!tooltip.title && !tooltip.image) {
            view
                .text(description)
                .textSize(descriptionTextSize)
                .shrinkToFitText(true)
                .borderRadius(5)
                .textPadding(5)
                .textAllowMultiline(true)
        } else if (!tooltip.image) {
            let text = `|@${titleTextSize / descriptionTextSize}{${tooltip.title}}\n\n${description}`;

            view
                .text(text)
                .textSize(descriptionTextSize)
                .shrinkToFitText(true)
                .textAllowMultiline(true)
        } else {
            if (tooltip.fixedPosition) {
                view.setRect(tooltip.fixedPosition);
            }

            let horizontalAlign = 'left';

            if (description.startsWith("|") && description.endsWith("|")) {
                // omg the hack
                horizontalAlign = 'center';
            }

            view.layout()
                .topToBottom()
                .margin('3%w')
                .borderWidth('2r')
                .push()
                .height(titleTextSize)
                .text(tooltip.title)
                .textFit(true)
                // .push(tooltip.image)
                .push(evt => {
                    tooltip.image.render(evt);
                    evt.view
                        .borderColor('black')
                        .borderWidth('1r')
                })
                .aspectRatio(tooltip.imageAspectRatio)
                .borderRadius(5)
                .push()
                .force(1)
                .text(description)
                .textFit(true)
                .textSize(descriptionTextSize)
                .textHorizontalAlign(horizontalAlign)
                .textAllowMultiline(true)
                .textPadding('1.5%w')
                .borderColor('black')
                .borderWidth('1r')
                .borderRadius(5)
            
            if (topRightBadgeText) {
                let badgeRect = view.rect.getTopRightBadge(topRightBadgeSize).translate(-topRightBadgeSize * 0.15, topRightBadgeSize * 0.15);

                view.renderChild(({ view }) => {
                    view
                        .text(topRightBadgeText)
                        .textSize(topRightBadgeTextSize)
                        // .textBold(true)
                        .textOffsetY(2)
                        .borderRadius('50%')
                        .borderWidth('1r')
                        .borderColor('black')
                        .backgroundColor('white')
                        .zIndex(1)
                }, badgeRect);

                result.topRightBadgeRect = badgeRect;
            }
        }

        let rect = view.rect.getBottomNeighbor(view.rect.height, tooltipMargin).scaleTowardsTopLeft(bottomListScale);
        rect = rect.translate(-(rect.width - tooltipMargin) * (bottomList.length - 1) / 2, 0)

        if (rect.x1() < 0) {
            rect = rect.translate(-rect.x1() + tooltipMargin, 0);
        }

        for (let object of bottomList) {
            view.renderChild((evt) => {
                Tooltip.render(evt.view, object.getTooltip?.(evt), null);
            }, rect);

            rect = rect.getRightNeighbor(rect.width, 10);
        }

        return result;
    }

    render({ view }) {
        view.setPointerBehavior('none');
    }

    onPostRender({ view, client }) {
        let target = client.hoveredViews().find(view => view.data?.getTooltip);
        let tooltip = target?.data?.getTooltip?.({ view: target, client });

        if (!tooltip || client.draggedViews().length > 0) {
            return;
        }

        Tooltip.render(view, tooltip, target);
    }
}
globalThis.ALL_FUNCTIONS.push(Tooltip);