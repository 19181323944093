import { makeMinionCard } from '../../cards/make-minion';

export const TrainerCard = makeMinionCard({
    name: 'Trainer',
    goldCost: 3,
    power: 9,
    description: `After this is summoned, give the two minions on its left +2 power for each gold they cost.`,

    onStateChange(change, output) {
        if (change.summonedMinions.includes(this)) {
            let minions = [
                this.getNeighbor(-1),
                this.getNeighbor(-2),
            ].filter(x => x);

            output.setSource(this);

            for (let minion of minions) {
                output.increasePower(minion, minion.goldCost * 2);
            }
        }
    }
})