import { Card } from '../../entities/card';

export class ReversalCard extends Card {
    name = 'Reversal'
    description = `Reverse the order of your minions.`
    goldCost = 1

    triggerEffect(output, targets) {
        output
            .setMinionsOrder(this.owner.getMinions().reverse())
    }
}
globalThis.ALL_FUNCTIONS.push(ReversalCard);