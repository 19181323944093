import { Character } from '../../entities/character';
import { GruntCard } from './grunt';

export class BotCharacter extends Character {
    static id = 'bot'
    static hidden = true

    name = "Bot"
    color = '#D2CBC9'
    cards = [ new GruntCard(), new GruntCard(), new GruntCard(), new GruntCard(), new GruntCard(), new GruntCard() ]
    // cards = [ new GruntCard(), new GruntCard(), new GruntCard() ]
    // cards = [ new GruntCard() ]
}
globalThis.ALL_FUNCTIONS.push(BotCharacter);