import { Grid } from '../widgets/grid';
import { GearSelectionIcon } from './gear-selection-icon';

export class GearSelectionPanel {
    constructor({ client, gear }) {
        this.grids = gear.categories.map(category => new Grid({
            items: category.items.map(item => new GearSelectionIcon(client, item)).filter(icon => !icon.isHidden()),
            title: `${category.name} @sub{(pick one)}`,
            backgroundColor: category.backgroundColor,
            itemCountPerRow: category.itemCountPerRow,
            itemCountPerColumn: category.itemCountPerColumn,
            itemAspectRatio: 1,
            outerMargin: '4%',
            innerMargin: '12%',
            borderWidth: 2,
            borderRadius: 10,
            borderColor: 'black',
            verticalAlign: 0
        }));
    }

    render({ view }) {
        view.layout()
            .centerToRight()
            .outerMargin(20)
            .innerMargin(100)
            .childAspectRatio(1)
            .push(this.grids)
    }
}
globalThis.ALL_FUNCTIONS.push(GearSelectionPanel);