import { ws } from '../modules';
import { WEBSOCKET_UPGRADE_PATHNAME } from '../common/constants';
import { Connection } from './connection';
import { deserializeBuffer, serializeObject } from '../common/serialization';

export class WebSocketServer {
    constructor(httpServer) {
        this._httpServer = httpServer;
        this._webSocketServer = null;
        this._events = [];
    }

    async init() {
        this._webSocketServer = new ws.WebSocketServer({ noServer: true });
        this._httpServer.configureRequestUpgrade(WEBSOCKET_UPGRADE_PATHNAME, this._webSocketServer);

        this._webSocketServer.on('connection', (webSocket) => {
            let connection = new Connection(webSocket);

            this._events.push({ action: 'connect', connection });
            
            webSocket.addEventListener('close', (evt) => {
                connection._webSocket = null;
                this._events.push({ action: 'disconnect', connection });
            });

            webSocket.addEventListener('message', (event) => {
                let { requestId, requestType, data } = deserializeBuffer(event.data);

                this._events.push({ action: 'request', connection, requestId, requestType, data });
            });
        });
    }

    sendMessage(connection, type, data) {
        let webSocket = connection?._webSocket;
        let buffer = serializeObject({ type, data });

        if (webSocket) {
            webSocket.send(buffer);
        }
    }

    answerRequest(connection, requestId, data) {
        let webSocket = connection._webSocket;
        let buffer = serializeObject({ requestId, data });

        if (webSocket) {
            webSocket.send(buffer);
        }
    }

    pollEvents() {
        return this._events.splice(0, this._events.length);
    }
}
globalThis.ALL_FUNCTIONS.push(WebSocketServer);