import { Graphics } from './graphics';
import { ViewLayout } from './view-layout';
import { Color } from '../utils/color';
import { DisplaySize } from '../utils/display-size';

const POINTER_NONE = 'none';
const POINTER_VISIBLE = 'visible';
const POINTER_OPAQUE = 'opaque';

export class View {
    constructor(client, data, rect) {
        this._client = client;
        this._data = data;
        this._rect = rect;
        this._parent = null;
        this._layouts = [];
        this._graphics = new Graphics();
        this._hoveredGraphics = {};
        this._focusedGraphics = {};
        this._disabledGraphics = {};
        this._objectsToRender = [];
        this._children = [];
        this._disabled = false;
        this._hidden = false;
        this._pointerBehavior = POINTER_VISIBLE;
    }

    static is(value) {
        return value && value instanceof View;
    }

    get data() {
        return this._data;
    }

    get rect() {
        return this._rect;
    }

    getDataId() {
        if (!this._data) {
            return null;
        // } else if (this._data.id) {
            // return this._data.id;
        } else {
            return this._client.getObjectId(this._data);
        }
    }

    layout() {
        let layout = new ViewLayout(this._data);
        this._layouts.push(layout);
        return layout;
    }

    setRect(rect) {
        this._rect = rect?.clone();
        return this;
    }

    setData(data) {
        this._data = data;
        return this;
    }

    addChild(view) {
        view._parent = this;
        this._children.push(view);
    }

    children() {
        return this._children;
    }

    renderChild(object, rect = this.rect, graphics = {}) {
        this._objectsToRender.push([object, rect, graphics])
    }

    getZIndex() {
        return this._graphics.zIndex;
    }

    focus() {
        this._client.focus(this);
        return this;
    }

    isFocused() {
        return this._client.focusedView() === this;
    }

    isHovered() {
        return this._client.hoveredViews().includes(this);
    }

    isPressed() {
        return this._client.pressedViews().includes(this);
    }

    isDragged() {
        return this._client.draggedViews().includes(this);
    }

    setDisabled(value) {
        this._disabled = value;
        return this;
    }

    isDisabled() {
        return this._disabled;
    }

    setHidden(value) {
        this._hidden = value;
        return this;
    }

    isHidden() {
        return this._hidden;
    }

    setPointerBehavior(pointerBehavior) {
        this._pointerBehavior = pointerBehavior;
        return this;
    }

    isPointerVisible() {
        return !this._hidden && this._pointerBehavior !== POINTER_NONE;
    }

    isPointerOpaque() {
        return this._pointerBehavior === POINTER_OPAQUE;
    }

    addToFocusChain() {
        this._client.addToFocusChain(this)
        return this;
    }

    scale(ratio) {
        this._rect = this._rect.scale(ratio);
        return this;
    }

    stripToMatchAspectRatio(aspectRatio) {
        this._rect = this._rect.stripToMatchAspectRatio(aspectRatio);
        return this;
    }

    moveToAndScale(x, y, scale) {
        let dx = x - this._rect.x;
        let dy = y - this._rect.y;

        this._traverse(view => {
            view._rect = view._rect
                .translate(dx, dy)
                .scaleTowards(x, y, scale)
        });

        return this;
    }

    setZIndex(zIndex) {
        this._traverse(view => view.zIndex(zIndex));

        return this;
    }

    _traverse(callback) {
        callback(this);

        for (let child of this._children) {
            child._traverse(callback);
        }
    }

    traverse(callback) {
        this._traverse(callback)
    }

    // GENERATION START

    shape(shape) {
        this._graphics.shape = shape;
        return this;
    }
    offsetX(offsetX) {
        this._graphics.offsetX = DisplaySize.from(offsetX);
        return this;
    }
    offsetY(offsetY) {
        this._graphics.offsetY = DisplaySize.from(offsetY);
        return this;
    }
    zIndex(zIndex) {
        this._graphics.zIndex = zIndex;
        return this;
    }
    horizontalAnchor(horizontalAnchor) {
        this._graphics.horizontalAnchor = horizontalAnchor;
        return this;
    }
    verticalAnchor(verticalAnchor) {
        this._graphics.verticalAnchor = verticalAnchor;
        return this;
    }
    borderRadius(borderRadius) {
        this._graphics.borderRadius = DisplaySize.from(borderRadius);
        return this;
    }
    borderWidth(borderWidth) {
        this._graphics.borderWidth = DisplaySize.from(borderWidth);
        return this;
    }
    borderScale(borderScale) {
        this._graphics.borderScale = borderScale;
        return this;
    }
    borderDashLength(borderDashLength) {
        this._graphics.borderDashLength = DisplaySize.from(borderDashLength);
        return this;
    }
    borderGapLength(borderGapLength) {
        this._graphics.borderGapLength = DisplaySize.from(borderGapLength);
        return this;
    }
    borderColor(borderColor) {
        this._graphics.borderColor = Color.from(borderColor);
        return this;
    }
    borderAlpha(borderAlpha) {
        this._graphics.borderAlpha = borderAlpha;
        return this;
    }
    backgroundColor(backgroundColor) {
        this._graphics.backgroundColor = Color.from(backgroundColor);
        return this;
    }
    backgroundAlpha(backgroundAlpha) {
        this._graphics.backgroundAlpha = backgroundAlpha;
        return this;
    }
    overlayColor(overlayColor) {
        this._graphics.overlayColor = Color.from(overlayColor);
        return this;
    }
    overlayAlpha(overlayAlpha) {
        this._graphics.overlayAlpha = overlayAlpha;
        return this;
    }
    imageUrl(imageUrl) {
        this._graphics.imageUrl = imageUrl;
        return this;
    }
    imageScale(imageScale) {
        this._graphics.imageScale = imageScale;
        return this;
    }
    imageLayout(imageSpriteCountPerRow, imageSpriteCountPerColumn) {
        this._graphics.imageSpriteCountPerRow = imageSpriteCountPerRow;
        this._graphics.imageSpriteCountPerColumn = imageSpriteCountPerColumn;
        return this;
    }
    imageSpriteIndex(imageSpriteIndex) {
        this._graphics.imageSpriteIndex = imageSpriteIndex;
        return this;
    }
    animationStartTime(animationStartTime) {
        this._graphics.animationStartTime = animationStartTime;
        return this;
    }
    animationCurrentTime(animationCurrentTime) {
        this._graphics.animationCurrentTime = animationCurrentTime;
        return this;
    }
    animationDuration(animationDuration) {
        this._graphics.animationDuration = animationDuration;
        return this;
    }
    text(text) {
        this._graphics.text = text;
        return this;
    }
    textFont(textFont) {
        this._graphics.textFont = textFont;
        return this;
    }
    textSize(textSize) {
        this._graphics.textSize = DisplaySize.from(textSize);
        return this;
    }
    textColor(textColor) {
        this._graphics.textColor = Color.from(textColor);
        return this;
    }
    textAlpha(textAlpha) {
        this._graphics.textAlpha = textAlpha;
        return this;
    }
    textPadding(textPadding) {
        this._graphics.textPadding = DisplaySize.from(textPadding);
        return this;
    }
    textHorizontalAlign(textHorizontalAlign) {
        this._graphics.textHorizontalAlign = textHorizontalAlign;
        return this;
    }
    textVerticalAlign(textVerticalAlign) {
        this._graphics.textVerticalAlign = textVerticalAlign;
        return this;
    }
    textBold(textBold) {
        this._graphics.textBold = textBold;
        return this;
    }
    textItalic(textItalic) {
        this._graphics.textItalic = textItalic;
        return this;
    }
    textOffsetX(textOffsetX) {
        this._graphics.textOffsetX = textOffsetX;
        return this;
    }
    textOffsetY(textOffsetY) {
        this._graphics.textOffsetY = textOffsetY;
        return this;
    }
    textCursorIndex(textCursorIndex) {
        this._graphics.textCursorIndex = textCursorIndex;
        return this;
    }
    textFit(textFit) {
        this._graphics.textFit = textFit;
        return this;
    }
    textAllowMultiline(textAllowMultiline) {
        this._graphics.textAllowMultiline = textAllowMultiline;
        return this;
    }
    textFixVerticalCenter(textFixVerticalCenter) {
        this._graphics.textFixVerticalCenter = textFixVerticalCenter;
        return this;
    }
    shrinkToFitText(shrinkToFitText) {
        this._graphics.shrinkToFitText = shrinkToFitText;
        return this;
    }
    cursor(cursor) {
        this._graphics.cursor = cursor;
        return this;
    }

    hoverShape(shape) {
        this._hoveredGraphics.shape = shape;
        return this;
    }
    hoverOffsetX(offsetX) {
        this._hoveredGraphics.offsetX = DisplaySize.from(offsetX);
        return this;
    }
    hoverOffsetY(offsetY) {
        this._hoveredGraphics.offsetY = DisplaySize.from(offsetY);
        return this;
    }
    hoverZIndex(zIndex) {
        this._hoveredGraphics.zIndex = zIndex;
        return this;
    }
    hoverHorizontalAnchor(horizontalAnchor) {
        this._hoveredGraphics.horizontalAnchor = horizontalAnchor;
        return this;
    }
    hoverVerticalAnchor(verticalAnchor) {
        this._hoveredGraphics.verticalAnchor = verticalAnchor;
        return this;
    }
    hoverBorderRadius(borderRadius) {
        this._hoveredGraphics.borderRadius = DisplaySize.from(borderRadius);
        return this;
    }
    hoverBorderWidth(borderWidth) {
        this._hoveredGraphics.borderWidth = DisplaySize.from(borderWidth);
        return this;
    }
    hoverBorderScale(borderScale) {
        this._hoveredGraphics.borderScale = borderScale;
        return this;
    }
    hoverBorderDashLength(borderDashLength) {
        this._hoveredGraphics.borderDashLength = DisplaySize.from(borderDashLength);
        return this;
    }
    hoverBorderGapLength(borderGapLength) {
        this._hoveredGraphics.borderGapLength = DisplaySize.from(borderGapLength);
        return this;
    }
    hoverBorderColor(borderColor) {
        this._hoveredGraphics.borderColor = Color.from(borderColor);
        return this;
    }
    hoverBorderAlpha(borderAlpha) {
        this._hoveredGraphics.borderAlpha = borderAlpha;
        return this;
    }
    hoverBackgroundColor(backgroundColor) {
        this._hoveredGraphics.backgroundColor = Color.from(backgroundColor);
        return this;
    }
    hoverBackgroundAlpha(backgroundAlpha) {
        this._hoveredGraphics.backgroundAlpha = backgroundAlpha;
        return this;
    }
    hoverOverlayColor(overlayColor) {
        this._hoveredGraphics.overlayColor = Color.from(overlayColor);
        return this;
    }
    hoverOverlayAlpha(overlayAlpha) {
        this._hoveredGraphics.overlayAlpha = overlayAlpha;
        return this;
    }
    hoverImageUrl(imageUrl) {
        this._hoveredGraphics.imageUrl = imageUrl;
        return this;
    }
    hoverImageScale(imageScale) {
        this._hoveredGraphics.imageScale = imageScale;
        return this;
    }
    hoverImageLayout(imageSpriteCountPerRow, imageSpriteCountPerColumn) {
        this._hoveredGraphics.imageSpriteCountPerRow = imageSpriteCountPerRow;
        this._hoveredGraphics.imageSpriteCountPerColumn = imageSpriteCountPerColumn;
        return this;
    }
    hoverImageSpriteIndex(imageSpriteIndex) {
        this._hoveredGraphics.imageSpriteIndex = imageSpriteIndex;
        return this;
    }
    hoverAnimationStartTime(animationStartTime) {
        this._hoveredGraphics.animationStartTime = animationStartTime;
        return this;
    }
    hoverAnimationCurrentTime(animationCurrentTime) {
        this._hoveredGraphics.animationCurrentTime = animationCurrentTime;
        return this;
    }
    hoverAnimationDuration(animationDuration) {
        this._hoveredGraphics.animationDuration = animationDuration;
        return this;
    }
    hoverText(text) {
        this._hoveredGraphics.text = text;
        return this;
    }
    hoverTextFont(textFont) {
        this._hoveredGraphics.textFont = textFont;
        return this;
    }
    hoverTextSize(textSize) {
        this._hoveredGraphics.textSize = DisplaySize.from(textSize);
        return this;
    }
    hoverTextColor(textColor) {
        this._hoveredGraphics.textColor = Color.from(textColor);
        return this;
    }
    hoverTextAlpha(textAlpha) {
        this._hoveredGraphics.textAlpha = textAlpha;
        return this;
    }
    hoverTextPadding(textPadding) {
        this._hoveredGraphics.textPadding = DisplaySize.from(textPadding);
        return this;
    }
    hoverTextHorizontalAlign(textHorizontalAlign) {
        this._hoveredGraphics.textHorizontalAlign = textHorizontalAlign;
        return this;
    }
    hoverTextVerticalAlign(textVerticalAlign) {
        this._hoveredGraphics.textVerticalAlign = textVerticalAlign;
        return this;
    }
    hoverTextBold(textBold) {
        this._hoveredGraphics.textBold = textBold;
        return this;
    }
    hoverTextItalic(textItalic) {
        this._hoveredGraphics.textItalic = textItalic;
        return this;
    }
    hoverTextOffsetX(textOffsetX) {
        this._hoveredGraphics.textOffsetX = textOffsetX;
        return this;
    }
    hoverTextOffsetY(textOffsetY) {
        this._hoveredGraphics.textOffsetY = textOffsetY;
        return this;
    }
    hoverTextCursorIndex(textCursorIndex) {
        this._hoveredGraphics.textCursorIndex = textCursorIndex;
        return this;
    }
    hoverTextFit(textFit) {
        this._hoveredGraphics.textFit = textFit;
        return this;
    }
    hoverTextAllowMultiline(textAllowMultiline) {
        this._hoveredGraphics.textAllowMultiline = textAllowMultiline;
        return this;
    }
    hoverTextFixVerticalCenter(textFixVerticalCenter) {
        this._hoveredGraphics.textFixVerticalCenter = textFixVerticalCenter;
        return this;
    }
    hoverShrinkToFitText(shrinkToFitText) {
        this._hoveredGraphics.shrinkToFitText = shrinkToFitText;
        return this;
    }
    hoverCursor(cursor) {
        this._hoveredGraphics.cursor = cursor;
        return this;
    }

    focusShape(shape) {
        this._focusedGraphics.shape = shape;
        return this;
    }
    focusOffsetX(offsetX) {
        this._focusedGraphics.offsetX = DisplaySize.from(offsetX);
        return this;
    }
    focusOffsetY(offsetY) {
        this._focusedGraphics.offsetY = DisplaySize.from(offsetY);
        return this;
    }
    focusZIndex(zIndex) {
        this._focusedGraphics.zIndex = zIndex;
        return this;
    }
    focusHorizontalAnchor(horizontalAnchor) {
        this._focusedGraphics.horizontalAnchor = horizontalAnchor;
        return this;
    }
    focusVerticalAnchor(verticalAnchor) {
        this._focusedGraphics.verticalAnchor = verticalAnchor;
        return this;
    }
    focusBorderRadius(borderRadius) {
        this._focusedGraphics.borderRadius = DisplaySize.from(borderRadius);
        return this;
    }
    focusBorderWidth(borderWidth) {
        this._focusedGraphics.borderWidth = DisplaySize.from(borderWidth);
        return this;
    }
    focusBorderScale(borderScale) {
        this._focusedGraphics.borderScale = borderScale;
        return this;
    }
    focusBorderDashLength(borderDashLength) {
        this._focusedGraphics.borderDashLength = DisplaySize.from(borderDashLength);
        return this;
    }
    focusBorderGapLength(borderGapLength) {
        this._focusedGraphics.borderGapLength = DisplaySize.from(borderGapLength);
        return this;
    }
    focusBorderColor(borderColor) {
        this._focusedGraphics.borderColor = Color.from(borderColor);
        return this;
    }
    focusBorderAlpha(borderAlpha) {
        this._focusedGraphics.borderAlpha = borderAlpha;
        return this;
    }
    focusBackgroundColor(backgroundColor) {
        this._focusedGraphics.backgroundColor = Color.from(backgroundColor);
        return this;
    }
    focusBackgroundAlpha(backgroundAlpha) {
        this._focusedGraphics.backgroundAlpha = backgroundAlpha;
        return this;
    }
    focusOverlayColor(overlayColor) {
        this._focusedGraphics.overlayColor = Color.from(overlayColor);
        return this;
    }
    focusOverlayAlpha(overlayAlpha) {
        this._focusedGraphics.overlayAlpha = overlayAlpha;
        return this;
    }
    focusImageUrl(imageUrl) {
        this._focusedGraphics.imageUrl = imageUrl;
        return this;
    }
    focusImageScale(imageScale) {
        this._focusedGraphics.imageScale = imageScale;
        return this;
    }
    focusImageLayout(imageSpriteCountPerRow, imageSpriteCountPerColumn) {
        this._focusedGraphics.imageSpriteCountPerRow = imageSpriteCountPerRow;
        this._focusedGraphics.imageSpriteCountPerColumn = imageSpriteCountPerColumn;
        return this;
    }
    focusImageSpriteIndex(imageSpriteIndex) {
        this._focusedGraphics.imageSpriteIndex = imageSpriteIndex;
        return this;
    }
    focusAnimationStartTime(animationStartTime) {
        this._focusedGraphics.animationStartTime = animationStartTime;
        return this;
    }
    focusAnimationCurrentTime(animationCurrentTime) {
        this._focusedGraphics.animationCurrentTime = animationCurrentTime;
        return this;
    }
    focusAnimationDuration(animationDuration) {
        this._focusedGraphics.animationDuration = animationDuration;
        return this;
    }
    focusText(text) {
        this._focusedGraphics.text = text;
        return this;
    }
    focusTextFont(textFont) {
        this._focusedGraphics.textFont = textFont;
        return this;
    }
    focusTextSize(textSize) {
        this._focusedGraphics.textSize = DisplaySize.from(textSize);
        return this;
    }
    focusTextColor(textColor) {
        this._focusedGraphics.textColor = Color.from(textColor);
        return this;
    }
    focusTextAlpha(textAlpha) {
        this._focusedGraphics.textAlpha = textAlpha;
        return this;
    }
    focusTextPadding(textPadding) {
        this._focusedGraphics.textPadding = DisplaySize.from(textPadding);
        return this;
    }
    focusTextHorizontalAlign(textHorizontalAlign) {
        this._focusedGraphics.textHorizontalAlign = textHorizontalAlign;
        return this;
    }
    focusTextVerticalAlign(textVerticalAlign) {
        this._focusedGraphics.textVerticalAlign = textVerticalAlign;
        return this;
    }
    focusTextBold(textBold) {
        this._focusedGraphics.textBold = textBold;
        return this;
    }
    focusTextItalic(textItalic) {
        this._focusedGraphics.textItalic = textItalic;
        return this;
    }
    focusTextOffsetX(textOffsetX) {
        this._focusedGraphics.textOffsetX = textOffsetX;
        return this;
    }
    focusTextOffsetY(textOffsetY) {
        this._focusedGraphics.textOffsetY = textOffsetY;
        return this;
    }
    focusTextCursorIndex(textCursorIndex) {
        this._focusedGraphics.textCursorIndex = textCursorIndex;
        return this;
    }
    focusTextFit(textFit) {
        this._focusedGraphics.textFit = textFit;
        return this;
    }
    focusTextAllowMultiline(textAllowMultiline) {
        this._focusedGraphics.textAllowMultiline = textAllowMultiline;
        return this;
    }
    focusTextFixVerticalCenter(textFixVerticalCenter) {
        this._focusedGraphics.textFixVerticalCenter = textFixVerticalCenter;
        return this;
    }
    focusShrinkToFitText(shrinkToFitText) {
        this._focusedGraphics.shrinkToFitText = shrinkToFitText;
        return this;
    }
    focusCursor(cursor) {
        this._focusedGraphics.cursor = cursor;
        return this;
    }

    disabledShape(shape) {
        this._disabledGraphics.shape = shape;
        return this;
    }
    disabledOffsetX(offsetX) {
        this._disabledGraphics.offsetX = DisplaySize.from(offsetX);
        return this;
    }
    disabledOffsetY(offsetY) {
        this._disabledGraphics.offsetY = DisplaySize.from(offsetY);
        return this;
    }
    disabledZIndex(zIndex) {
        this._disabledGraphics.zIndex = zIndex;
        return this;
    }
    disabledHorizontalAnchor(horizontalAnchor) {
        this._disabledGraphics.horizontalAnchor = horizontalAnchor;
        return this;
    }
    disabledVerticalAnchor(verticalAnchor) {
        this._disabledGraphics.verticalAnchor = verticalAnchor;
        return this;
    }
    disabledBorderRadius(borderRadius) {
        this._disabledGraphics.borderRadius = DisplaySize.from(borderRadius);
        return this;
    }
    disabledBorderWidth(borderWidth) {
        this._disabledGraphics.borderWidth = DisplaySize.from(borderWidth);
        return this;
    }
    disabledBorderScale(borderScale) {
        this._disabledGraphics.borderScale = borderScale;
        return this;
    }
    disabledBorderDashLength(borderDashLength) {
        this._disabledGraphics.borderDashLength = DisplaySize.from(borderDashLength);
        return this;
    }
    disabledBorderGapLength(borderGapLength) {
        this._disabledGraphics.borderGapLength = DisplaySize.from(borderGapLength);
        return this;
    }
    disabledBorderColor(borderColor) {
        this._disabledGraphics.borderColor = Color.from(borderColor);
        return this;
    }
    disabledBorderAlpha(borderAlpha) {
        this._disabledGraphics.borderAlpha = borderAlpha;
        return this;
    }
    disabledBackgroundColor(backgroundColor) {
        this._disabledGraphics.backgroundColor = Color.from(backgroundColor);
        return this;
    }
    disabledBackgroundAlpha(backgroundAlpha) {
        this._disabledGraphics.backgroundAlpha = backgroundAlpha;
        return this;
    }
    disabledOverlayColor(overlayColor) {
        this._disabledGraphics.overlayColor = Color.from(overlayColor);
        return this;
    }
    disabledOverlayAlpha(overlayAlpha) {
        this._disabledGraphics.overlayAlpha = overlayAlpha;
        return this;
    }
    disabledImageUrl(imageUrl) {
        this._disabledGraphics.imageUrl = imageUrl;
        return this;
    }
    disabledImageScale(imageScale) {
        this._disabledGraphics.imageScale = imageScale;
        return this;
    }
    disabledImageLayout(imageSpriteCountPerRow, imageSpriteCountPerColumn) {
        this._disabledGraphics.imageSpriteCountPerRow = imageSpriteCountPerRow;
        this._disabledGraphics.imageSpriteCountPerColumn = imageSpriteCountPerColumn;
        return this;
    }
    disabledImageSpriteIndex(imageSpriteIndex) {
        this._disabledGraphics.imageSpriteIndex = imageSpriteIndex;
        return this;
    }
    disabledAnimationStartTime(animationStartTime) {
        this._disabledGraphics.animationStartTime = animationStartTime;
        return this;
    }
    disabledAnimationCurrentTime(animationCurrentTime) {
        this._disabledGraphics.animationCurrentTime = animationCurrentTime;
        return this;
    }
    disabledAnimationDuration(animationDuration) {
        this._disabledGraphics.animationDuration = animationDuration;
        return this;
    }
    disabledText(text) {
        this._disabledGraphics.text = text;
        return this;
    }
    disabledTextFont(textFont) {
        this._disabledGraphics.textFont = textFont;
        return this;
    }
    disabledTextSize(textSize) {
        this._disabledGraphics.textSize = DisplaySize.from(textSize);
        return this;
    }
    disabledTextColor(textColor) {
        this._disabledGraphics.textColor = Color.from(textColor);
        return this;
    }
    disabledTextAlpha(textAlpha) {
        this._disabledGraphics.textAlpha = textAlpha;
        return this;
    }
    disabledTextPadding(textPadding) {
        this._disabledGraphics.textPadding = DisplaySize.from(textPadding);
        return this;
    }
    disabledTextHorizontalAlign(textHorizontalAlign) {
        this._disabledGraphics.textHorizontalAlign = textHorizontalAlign;
        return this;
    }
    disabledTextVerticalAlign(textVerticalAlign) {
        this._disabledGraphics.textVerticalAlign = textVerticalAlign;
        return this;
    }
    disabledTextBold(textBold) {
        this._disabledGraphics.textBold = textBold;
        return this;
    }
    disabledTextItalic(textItalic) {
        this._disabledGraphics.textItalic = textItalic;
        return this;
    }
    disabledTextOffsetX(textOffsetX) {
        this._disabledGraphics.textOffsetX = textOffsetX;
        return this;
    }
    disabledTextOffsetY(textOffsetY) {
        this._disabledGraphics.textOffsetY = textOffsetY;
        return this;
    }
    disabledTextCursorIndex(textCursorIndex) {
        this._disabledGraphics.textCursorIndex = textCursorIndex;
        return this;
    }
    disabledTextFit(textFit) {
        this._disabledGraphics.textFit = textFit;
        return this;
    }
    disabledTextAllowMultiline(textAllowMultiline) {
        this._disabledGraphics.textAllowMultiline = textAllowMultiline;
        return this;
    }
    disabledTextFixVerticalCenter(textFixVerticalCenter) {
        this._disabledGraphics.textFixVerticalCenter = textFixVerticalCenter;
        return this;
    }
    disabledShrinkToFitText(shrinkToFitText) {
        this._disabledGraphics.shrinkToFitText = shrinkToFitText;
        return this;
    }
    disabledCursor(cursor) {
        this._disabledGraphics.cursor = cursor;
        return this;
    }

    // GENERATION STOP
}
globalThis.ALL_FUNCTIONS.push(View);