import { User } from '../common/user';
import { WINDOW_HEIGHT, WINDOW_WIDTH } from '../framework/constants';
import { formatPlayerData } from '../framework/game/player-data';
import { toArray } from '../utils/array';
import { Clock } from '../utils/clock';
import { instanciate } from '../utils/object';
import { Rect } from '../utils/rect';
import { Rng } from '../utils/rng';
import { Vector } from '../utils/vector';
import { KeyboardManager } from './keyboard-manager';
import { LocalStorage } from './local-storage';
import { Renderer } from './renderer';
import { View } from './view';
import { ViewStack } from './view-stack';
import { WebSocketManager } from './websocket-manager';
import { WindowManager } from './window-manager';

export class Client {
    constructor({ clientRoot, clientLocalData, constants, gear, game, autoMatchmaking }) {
        this._windowManager = new WindowManager();
        this._renderer = new Renderer(this._windowManager);
        this._keyboardManager = new KeyboardManager();
        this._webSocketManager = new WebSocketManager();
        this._clock = new Clock();
        this._localStorage = new LocalStorage();

        this._allViews = [];
        this._objectToView = new Map();
        this._focusChain = [];
        this._hoveredViewStack = new ViewStack();
        this._focusedViewStack = new ViewStack();
        this._pressedViewStack = new ViewStack();
        this._draggedViewStack = new ViewStack();
        this._enabledViewStack = new ViewStack();

        this._cursorPosition = new Vector();
        this._pressPosition = null;
        this._dragging = false;
        this._freezeConditions = [];
        this._elapsed = 0;
    
        this._objectToId = new WeakMap();
        this._nextObjectId = 1;
        this._autoMatchmaking = autoMatchmaking;

        this._root = null;
        this._localData = clientLocalData;
        this._constants = constants;
        this._gear = gear;
        this._gameClass = game;
        this._user = new User();
        this._game = null;

        this.setRoot(clientRoot);
    }

    get root() {
        return this._root;
    }

    get localData() {
        return this._localData;
    }

    get constants() {
        return this._constants;
    }

    get user() {
        return this._user;
    }

    get gear() {
        return this._gear;
    }

    get game() {
        return this._game;
    }

    async start() {
        // @ts-ignore
        window.client = this;

        await this._windowManager.init();
        await this._keyboardManager.init();
        await this._webSocketManager.init();

        let update = () => {
            this._update();

            window.requestAnimationFrame(update);
        };

        update();
    }

    getObjectId(object) {
        let id = this._objectToId.get(object);

        if (!id) {
            id = this._nextObjectId++;
            this._objectToId.set(object, id);
        }

        return id;
    }

    setRoot(root) {
        let parameters = { client: this, constants: this.constants, gear: this._gear, autoMatchmaking: this._autoMatchmaking };

        this._root = instanciate(root, parameters)
    }

    cursor() {
        return this._cursorPosition.clone();
    }

    sendRequest(type, data) {
        return this._webSocketManager.sendMessage(type, data);
    }

    sendGameInput(type, data) {
        return this._webSocketManager.sendMessage('gameInput', { type, data });
    }

    _update() {
        this._elapsed = this._clock.tick();

        this._createViews();

        if (this._isFrozen()) {
            this._windowManager.pollEvents();
            this._processNetworkEvents();
            return;
        }

        this._drawFrame();
        this._processWindowEvents();
        this._processNetworkEvents();
    }

    _updateGame() {
        
    }

    _isFrozen() {
        this._freezeConditions = this._freezeConditions.filter(callback => !callback(this));

        return this._freezeConditions.length > 0;
    }

    // RENDERING

    _createViews() {
        this._resetViews();
        this._renderRoot();
        this._inferInteractiveViews();
        this._postRender();
        this._inferInteractiveViews();
    }

    _resetViews() {
        this._allViews.clear();
        this._objectToView.clear();
        this._focusChain.clear();
        this._hoveredViewStack.clear();
        this._focusedViewStack.softClear();
        this._pressedViewStack.softClear();
        this._draggedViewStack.softClear();
        this._enabledViewStack.clear();
    }

    _renderRoot() {
        this._renderView(this._root, Rect.fromSize(WINDOW_WIDTH, WINDOW_HEIGHT));
    }

    _getRenderPayload(view) {
        return { view, client: this, constants: this._constants, elapsed: this._elapsed };
    }

    _renderView(object, rect, graphics, parent) {
        let view = new View(this, object, rect);

        this._allViews.push(view);
        parent?.addChild(view);

        if (graphics) {
            Object.assign(view._graphics, graphics);
        }

        if (object) {
            let payload = this._getRenderPayload(view);

            this._objectToView.set(object, view);

            if (typeof object === 'function') {
                object(payload);
            } else {
                object.update?.(payload);
                object.render?.(payload);
            }
        }

        this._renderViewChildren(view);
    }

    _renderViewChildren(view) {
        for (let layout of view._layouts) {
            let graphics = layout.getRootProperties();

            Object.assign(view._graphics, graphics);

            for (let [childObject, childRect, childGraphics] of layout.compute(view.rect)) {
                this._renderView(childObject, childRect, childGraphics, view);
            }
        }

        for (let [childObject, childRect, childGraphics] of view._objectsToRender) {
            this._renderView(childObject, childRect, childGraphics, view);
        }

        view._layouts = [];
        view._objectsToRender = [];
    }

    _inferInteractiveViews() {
        this._detectHoveredViews();
        this._focusedViewStack.fillFromIds(this._allViews);
        this._pressedViewStack.fillFromIds(this._allViews);
        this._draggedViewStack.fillFromIds(this._allViews);
        this._enabledViewStack.setFromArray(this._allViews.filter(view => !view.isDisabled()).reverse());
    }

    _detectHoveredViews() {
        let { x, y } = this._cursorPosition;
        let stack = [];

        for (let view of this._allViews) {
            let hovered = view.isPointerVisible() && !view.isDisabled() && view.rect?.contains(x, y);

            if (hovered) {
                stack.push(view);
            }
        }

        stack
            .reverse()
            .sort((view1, view2) => (view1.getZIndex() - view2.getZIndex()));

        let firstOpaqueIndex = stack.findIndex(view => view.isPointerOpaque());

        if (firstOpaqueIndex !== -1) {
            stack = stack.slice(0, firstOpaqueIndex + 1);
        }

        this._hoveredViewStack.setFromArray(stack);
    }

    _postRender() {
        let views = this._allViews.slice().reverse();
        
        for (let view of views) {
            let object = view.data;
            let enabled = !view.isDisabled();

            if (object && enabled) {
                let intercept = object.onPostRender?.({ view, constants: this.constants, client: this });

                this._renderViewChildren(view);

                if (intercept) {
                    return;
                }
            }
        }
    }

    _drawFrame() {
        this._renderer.clear();

        for (let view of this._allViews) {
            if (!view._rect || view.isHidden()) {
                continue;
            }

            if (view.isDisabled()) {
                Object.assign(view._graphics, view._disabledGraphics);
            }

            if (view.isFocused()) {
                Object.assign(view._graphics, view._focusedGraphics);
            }

            if (view.isHovered()) {
                Object.assign(view._graphics, view._hoveredGraphics);
            }

            this._renderer.drawGraphics(view._graphics, view._rect);
        }
    }

    _processWindowEvents() {
        let client = this;
        let windowEvents = this._windowManager.pollEvents();
        let virtualToRealRatio = this._windowManager.getVirtualToRealRatio();

        for (let { type, payload } of windowEvents) {
            if (type === 'mouse') {
                payload.x /= virtualToRealRatio;
                payload.y /= virtualToRealRatio;

                let { action, button, x, y } = payload;

                this._cursorPosition.set(x, y);
                this._detectHoveredViews();

                if (action === 'down') {
                    this._pressPosition = this._cursorPosition.clone();
                    this._pressedViewStack.setFromStack(this._hoveredViewStack);
                    let evt = this._pressedViewStack.emit('onMouseDown', { client, view: null, x, y, button, willBeFocused: null });

                    this.focus(evt.willBeFocused);
                } else if (action === 'move') {
                    if (this._pressPosition) {
                        if (this._dragging) {
                            let { x: dx, y: dy } = this._cursorPosition.sub(this._pressPosition);

                            this._draggedViewStack.emit('onDragProgress', { client, view: null, x, y, button, dx, dy });
                        } else {
                            this._dragging = true;
                            this._draggedViewStack.setFromStack(this._hoveredViewStack);
                            this._draggedViewStack.emit('onDragStart', { client, view: null, x, y, button });
                        }
                    }
                } else if (action === 'up') {
                    if (this._pressPosition && this._dragging) {
                        this._draggedViewStack.emit('onDragEnd', { client, view: null, x, y, button });
                    }

                    let clickedViews = this._pressedViewStack.list().filter(view => view._rect.contains(x, y));
                    let clickedViewStack = new ViewStack().setFromArray(clickedViews);

                    clickedViewStack.emit('onClick', { client, view: null, x, y, button });
                    this._hoveredViewStack.emit('onMouseUp', { client, view: null, x, y, button });

                    this._dragging = false;
                    this._pressPosition = null;
                    this._pressedViewStack.clear();
                    this._draggedViewStack.clear();
                }
            } else if (type === 'keyboard') {
                let { action, code, text, ctrlKey, shiftKey, altKey, repeat } = payload;

                if (action === 'down') {
                    this._enabledViewStack.emit('onKeyDown', { client, view: null, code, text, ctrlKey, shiftKey, altKey, repeat });
                } else if (action === 'up') {
                    this._enabledViewStack.emit('onKeyUp', { client, view: null, code, text, ctrlKey, shiftKey, altKey, repeat });
                }
            } else if (type === 'wheel') {
                payload.x /= virtualToRealRatio;
                payload.y /= virtualToRealRatio;

                let { x, y, deltaX, deltaY, deltaZ, deltaMode } = payload;

                this._cursorPosition.set(x, y);
                this._detectHoveredViews();
                this._hoveredViewStack.emit('onScroll', { client, x, y, deltaX, deltaY, deltaZ, deltaMode });
            }
        }
    }

    _processNetworkEvents() {
        let networkEvents = this._webSocketManager.pollEvents();

        for (let { action, type, data } of networkEvents) {
            if (action === 'connect') {
                
            } else if (action === 'disconnect') {
                
            } else if (action === 'message') {
                let eventName = `$${type}`;

                this[eventName]?.(data);
                this._enabledViewStack.emit(eventName, data);
            }
        }
    }

    $showMessage(message) {

    }

    $updateState(data) {
        Object.assign(this._user, data);
    }

    $setGameState(state) {
        if (!state) {
            this._game = null;
        } else {
            let { players, inputs } = state;
            let data = formatPlayerData(players, this._gear);

            // console.log(inputs)

            this._game = new this._gameClass(data);
            this._game.init?.({ client: this });

            for (let input of inputs) {
                this._game.onPlayerInput?.(input);
            }

            this._game.update({ elapsed: Infinity });
        }
    }

    $gameInput(input) {
        let inputList = toArray(input);

        for (let input of inputList) {
            this.game.onPlayerInput(input);
        }
    }

    getView(object) {
        return this._objectToView.get(object);
    }

    disableAllExcept(objects) {
        let result = [];

        for (let view of this._allViews) {
            view.setDisabled(true);
        }

        for (let object of objects) {
            let view = this.getView(object);

            if (view) {
                result.push(view);
                view.setDisabled(false);
            }
        }

        return result;
    }

    views() {
        return this._allViews;
    }

    hoveredViews() {
        return this._hoveredViewStack.list();
    }

    focusedView() {
        return this._focusedViewStack.first();
    }

    pressedViews() {
        return this._pressedViewStack.list();
    }

    draggedViews() {
        return this._draggedViewStack.list();
    }

    focus(view) {
        if (view && !(view instanceof View)) {
            view = this._objectToView.get(view);
        }
        
        let currentFocusedView = this._focusedViewStack.first();

        this._focusedViewStack.setFromView(view);

        if (view && view !== currentFocusedView) {
            this._focusedViewStack.emit('onFocus', { client: this, view });
        }
    }

    clearFocus() {
        this.focus(null);
    }

    addToFocusChain(view) {
        if (view) {
            this._focusChain.push(view);
        }
    }

    removeFromFocusChain(view) {
        if (view) {
            this._focusChain.remove(view);
        }
    }

    clearFocusChain() {
        this.setFocusChain([]);
    }

    setFocusChain(views) {
        this.focus(null);
        this._focusChain = views;
    }

    getFocusChain() {
        return this._focusChain.slice();
    }

    _focusNextOrPrev(d) {
        let focusedView = this._focusedViewStack.first();
        let currentIndex = this._focusChain.indexOf(focusedView);
        let startIndex = currentIndex;
        let chainLength = this._focusChain.length;

        if (currentIndex === -1) {
            currentIndex = d * -1;
        }
        
        for (let i = 0; i < chainLength; ++i) {
            let index = (startIndex + chainLength + d + i * d) % chainLength;
            let view = this._focusChain[index];

            if (!view.isDisabled()) {
                this.focus(view);
                return;
            }
        }
    }

    focusNext() {
        this._focusNextOrPrev(1);
    }

    focusPrev() {
        this._focusNextOrPrev(-1);
    }

    setWindowTitle(title) {
        window.document.title = title;
    }

    freezeUntil(condition, promiseCallback) {
        if (condition instanceof Promise) {
            let fulfilled = false;
            let response = undefined;
            let callback = promiseCallback || (() => true);

            condition.then(data => {
                fulfilled = true;
                response = data;
            });
            this._freezeConditions.push(client => fulfilled && callback(response, client));
        } else {
            this._freezeConditions.push(condition);
        }
    }

    getCurrentTime() {
        return this._clock.getCurrentTime();
    }

    prompt(message) {
        return window.prompt(message);
    }

    getKeyValue(key) {
        return this._keyboardManager.getKeyValue(key);
    }

    getUrlLocation() {
        return window.location;
    }

    // LOCAL STORAGE API
    setLocalStorageKeyPrefix(prefix) {
        this._localStorage.setKeyPrefix(prefix);
    }

    getLocalStorageItem(key) {
        return this._localStorage.getItem(key);
    }

    setLocalStorageItem(key, data) {
        this._localStorage.setItem(key, data);
    }

    removeLocalStorageItem(key) {
        this._localStorage.removeItem(key);
    }

    clearLocalStorage() {
        this._localStorage.clear();
    }
}
globalThis.ALL_FUNCTIONS.push(Client);