import { Entity } from 'vortex';
import { CardTooltip } from '../card-tooltip';
import { getSacrificeKeywordDescription } from '../cards/make-minion';
import { BOARD_RECT } from '../constants/graphics-constants';
import { BattlefieldHitbox } from '../hitboxes/battlefield-hitbox';
import { RecyclingCenterHitbox } from '../hitboxes/recycling-center-hitbox';
import { StashHitbox } from '../hitboxes/stash-hitbox';
import { SummonLocationIndicator } from '../panels/summon-location-indicator';
import { getColorFromString, isInstanceOf } from '../utils';

export class Card extends Entity {
    character = null
    hidden = true
    recycled = false
    createdEffect = null
    summonedMinion = null
    summonedCount = 1
    sacrificeCost = 0
    minTargetCount = 0
    maxTargetCount = 0
    goldCost = 0
    isEmblem = false
    isUpgrade = false
    isCommon = false
    name = null
    description = null
    color = null
    icon = new CardIcon(this)
    hasAlreadyBeenPlayed = false
    forceDisplay = false
    tags = []

    _generateEmptyFields() {
        if (!this.name) {
            this.name = this._getGeneratedName();
        }

        if (!this.description) {
            this.description = this._getGeneratedDescription();
        }

        if (!this.color) {
            this.color = this._getGeneratedColor();
        }
    }

    _getGeneratedName() {
        if (this.summonedMinion) {
            if (this.summonedCount !== 1) {
                return `${this.summonedMinion.name} @0.8{(x${this.summonedCount})}`;
            } else {
                return this.summonedMinion.name;
            }
        } else if (this.createdEffect) {
            return this.createdEffect.name;
        } else {
            return "<card>";
        }
    }

    _getGeneratedDescription() {
        if (this.summonedMinion) {
            return this.summonedMinion.description;
        } else if (this.createdEffect) {
            return this.createdEffect.description;
        } else {
            return "|-|";
        }
    }

    _getGeneratedColor() {
        return this.createdEffect?.color || getColorFromString(this.createdEffect?.name || this.name)
    }

    generateName() {
        this.name = this._getGeneratedName();
    }

    generateDescription() {
        this.description = this._getGeneratedDescription();
    }

    hasTag(tag) {
        return this.tags.includes(tag);
    }

    getMirrorPoint() {
        if (this.owner.isOpponent) {
            return { y: BOARD_RECT.y };
        } else {
            return null;
        }
    }

    getOrderIndex() {
        return 1;
    }

    getGoldCost() {
        return this.goldCost;
    }

    render({ view, client }) {
        this._generateEmptyFields();

        if (this.hasAlreadyBeenPlayed || (this.owner?.isOpponent && this.getContainer()?.hideIfOpponent && !this.forceDisplay)) {
            return;
        }

        // console.log(view.rect)

        view
            // .disabled_overlay_color(DISABLED_OVERLAY_COLOR)
            .backgroundColor('white')
            .borderRadius('5%w')

        if (this.isHidden() && !client.localData.showHidden) {
            view
                .borderColor('black')
                .borderWidth('1%w')
                .text("?")
                .textSize('60%')
                .textBold(true)

            return;
        }

        view
            .borderColor('black')
            .borderWidth('1%w');

         view.layout()
            .topToBottom()
            .margin('6%w')
            .push()
            .text(this.name)
            .textSize('80%')
            .textFit(true)
            .push(this.icon)
            .aspectRatio(1)
            .borderColor('black')
            .borderWidth('1%w')

        // if (this.recycled) {
        //     layout
        //         .bottomRightToLeft()
        //         .outerMargin('1%w')
        //         .push()
        //         .width('15%w')
        //         .aspectRatio(1)
        //         .text('♻')
        // }

        let color = 'goldenrod';
        let goldCost = this.getGoldCost()

        if (this.owner.gold < goldCost && !this.owner.isOpponent) {
            color = 'red';
        }

        let w = view.rect.width;
        let r = 0.04;
        let costRect = view.rect.getTopRightBadge(w * 0.2).translate(-w * r, w * r);

        view.renderChild(({ view }) => {
            view
                .borderRadius('50%')
                .backgroundColor('white')
                .borderColor('black')
                .borderWidth('5%')
                .text(goldCost.toString())
                .textSize('90%')
                .textColor(color)
                .textBold(true)
                // .textOffsetY(1)
        }, costRect);
    }

    isHidden() {
        return this.owner && this.owner.isOpponent && this.hidden;
    }

    canBeInteractedWith() {
        return !this.owner.isOpponent && this.owner.gameStep === 'player-actions' && this.getContainer() === this.owner.activeCharacter.hand;
    }

    getValidTargets() {
        return [];
    }

    getShownTargets() {
        return this.getValidTargets().map(target => ({
            item: target
        }))
    }

    getTargetSubText(entity) {
        return null;
    }

    canTargetEntity(entity) {
        return true;
    }

    onPostRender({ view, client }) {
        if (!this.canBeInteractedWith()) {
            return;
        }

        view
            .hoverCursor('pointer')
            .hoverBorderScale(2)

        if (!view.isDragged()) {
            return;
        }

        let { x: cursorX, y: cursorY } = client.cursor();

        view.moveToAndScale(cursorX, cursorY, 0.6);

        let validDragTargets = [];

        if (this.canBePlayed()) {
            validDragTargets.push(this.owner.battlefieldHitbox);
        }

        if (this.owner.recyclingCenter.cards().length > 0) {
            validDragTargets.push(this.owner.recyclingCenterHitbox);
        }

        view
            .setZIndex(1)
            .cursor('grabbing')
            .setPointerBehavior('none')

        let views = client.disableAllExcept(validDragTargets);

        for (let view of views) {
            view
                .borderWidth(2.5)
                .borderColor('limegreen')
                .hoverOverlayColor('green')
                .hoverOverlayAlpha(0.2)
                .setPointerBehavior('opaque')
        }

        if (this.summonedMinion && this.canBePlayed()) {
            if (client.getView(this.owner.battlefieldHitbox).rect.containsPoint(client.cursor())) {
                for (let i = 0; i < this.summonedCount; ++i) {
                    view.renderChild(new SummonLocationIndicator(this.owner, i, this.owner.leftSummonThisTurn));
                }
            }
        }
    }

    onDragEnd({ client }) {
        if (!this.canBeInteractedWith()) {
            return;
        }

        let target = client.hoveredViews().first()?.data;
        let cardId = this.id;

        if (isInstanceOf(target, BattlefieldHitbox)) {
            this.hasAlreadyBeenPlayed = true;

            if (this.maxTargetCount === 0) {
                client.sendGameInput('playCard', { cardId });
            } else {
                this.owner.cardSelectionOverlay.show({
                    cards: this.getValidTargets(),
                    columnSize: 1,
                    minRowSize: 3,
                    minRequiredSelectionCount: this.minTargetCount,
                    maxRequiredSelectionCount: this.maxTargetCount,
                    isValidOption: (item) => this.canTargetEntity(item),
                    getSubText: (item) => this.getTargetSubText(item),
                    onComplete: (cards) => client.sendGameInput('playCard', { cardId, targetIds: cards.map(card => card.id) }),
                    onCancel: () => this.hasAlreadyBeenPlayed = false
                });
            }
        } else if (isInstanceOf(target, RecyclingCenterHitbox)) {
            this.hasAlreadyBeenPlayed = true;
            this.owner.cardSelectionOverlay.show({
                cards: this.owner.recyclingCenter.cards(),
                rowSize: 3,
                columnSize: 2,
                onComplete: (cards) => client.sendGameInput('recycleCard', { cardId, targetIds: cards.map(card => card.id) }),
                onCancel: () => this.hasAlreadyBeenPlayed = false
            });
        }
    }

    getTooltip({ view, client }) {
        this._generateEmptyFields();

        if (view.isDragged() || (this.isHidden() && !client.localData.showHidden)) {
            return;
        }

        let description = this.description;
        let badge = this.getGoldCost().toString();

        if (this.sacrificeCost > 0) {
            description = `${getSacrificeKeywordDescription(this.sacrificeCost)}\n\n${description}`;
        }

        // if (this.isEmblem) {
        //     description = `${getEmblemKeywordDescription()}\n\n${description}`;
        // }

        return new CardTooltip({
            title: this.name,
            image: this.icon,
            description,
            topRightBadge: badge
        });
    }

    canBePlayed() {
        return this.owner.battlefield.minions().length >= this.sacrificeCost && this.owner.gold >= this.getGoldCost();
    }

    areTargetsValid(targets) {
        let validTargets = this.getValidTargets();

        return targets.length >= this.minTargetCount && targets.length <= this.maxTargetCount && targets.every(target => validTargets.includes(target));
    }

    triggerDefaultEffect(output, targets) {
        output = output.takeGold(this.getGoldCost()).then();

        if (this.sacrificeCost > 0) {
            for (let i = 0; i < this.sacrificeCost; ++i) {
                let minion = this.owner.battlefield.minions()[i];

                output.sacrifice(minion);
            }

            // output = output.then();
        }

        if (this.summonedMinion) {
            for (let i = 0; i < this.summonedCount; ++i) {
                let minion = this.summonedMinion.makeCopy();

                output.summon(minion);
            }

        }

        if (this.createdEffect) {
            let effect = this.createdEffect.makeCopy();

            output.createEffect(effect);
        }

        this.triggerEffect(output, targets);
    }

    triggerEffect(output, targets) {

    }

    onStateChange(change, output) {
        if (change.playedCard === this) {
            this.triggerDefaultEffect(output, change.playedCardTargets);
        }
    }

    canInteractWithStateChange(change) {
        return change.playedCard === this;
    }
}

export class CardIcon {
    constructor(card) {
        this.card = card;
    }

    render({ view }) {
        if (this.card.summonedMinion) {
            this.card.summonedMinion.render({ view });
        } else {
            let icon = this.card.createdEffect?.icon || '★';

            view
                .backgroundColor(this.card.color)
                .text(icon)
                .textSize('70%')
        }

        view.borderWidth('1%w');
    }
}
globalThis.ALL_FUNCTIONS.push(Card);
globalThis.ALL_FUNCTIONS.push(CardIcon);