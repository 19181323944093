import { GearSelectionPanel } from './gear-selection-panel';
import { LobbyFooter } from './lobby-footer';
import { LobbyHeader } from './lobby-header';

export class LobbyScreen {
    constructor(parameters) {
        this.gearSelectionPanel = new GearSelectionPanel(parameters);
        this.footer = new LobbyFooter(parameters);

        this._autoMatchmaking = parameters.autoMatchmaking;
        this._matchmakingSent = false;
    }

    update({ client }) {
        if (this._autoMatchmaking && !this._matchmakingSent && client.user.isLoggedIn()) {
            this._matchmakingSent = true;
            this.footer._sendMatchmakingRequest({ client }, 'DumbBot');

            client.freezeUntil(() => !!client.game);
        }
    }

    render({ view, constants }) {
        view.layout()
            .backgroundColor(constants.lobbyBackgroundColor)
            .topToBottom()
            .push(new LobbyHeader())
            .height(constants.lobbyHeaderHeight)
            .push(this.gearSelectionPanel)
            .force(1)
            .push(this.footer)
            .height(constants.lobbyFooterHeight)
    }
}
globalThis.ALL_FUNCTIONS.push(LobbyScreen);