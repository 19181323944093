import { KING_COLOR } from '../../constants/graphics-constants';
import { Character } from '../../entities/character';
import { Minion } from '../../entities/minion';
import { makeArray } from '../../utils';
import { BattlecryCard } from './battlecry';
import { GeneralCard } from './general';
import { InspiredFighterCard } from './inspired-fighter';
import { KingEmblemCard } from './king-emblem';
import { KingUpgrade1Card } from './king-upgrade-1';
import { KingUpgrade2Card } from './king-upgrade-2';
import { KingUpgrade3Card } from './king-upgrade-3';
import { SoldiersCard } from './soldiers';
import { TrainerCard } from './trainer';

class Soldier extends Minion {
    name = "Soldier"
    power = 5
}

export class KingCharacter extends Character {
    static id = 'king'
    
    name = "King"
    description = `When this character dies, summon six 5-power minions.`
    color = KING_COLOR
    cards = [ new SoldiersCard(), new BattlecryCard(), new InspiredFighterCard(), new GeneralCard(), new TrainerCard() ]
    availableUpgradeCards = [ new KingUpgrade1Card(), new KingUpgrade2Card(), new KingUpgrade3Card() ]
    availableEmblemCards = [ new KingEmblemCard() ]

    onDeath() {
        this.owner.emitStateChange()
            .setSource(this)
            .summon(makeArray(6, () => new Soldier()))
    }
}
globalThis.ALL_FUNCTIONS.push(Soldier);
globalThis.ALL_FUNCTIONS.push(KingCharacter);