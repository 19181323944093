import { toArray } from '../../utils/array';

class Entry {
    constructor(event) {
        if (typeof event === 'function') {
            event = { onEnd: event };
        }

        this.event = event;
        this.t = 0;
        this.preTriggerDone = false;
        this.triggerDone = false;
        this.postTriggerDone = false;
        this.started = false;
    }
}

export class EventChain {
    constructor() {
        this._chain = [];
        this._currentIndex = -1;
        this._insertIndex = 0;
    }

    _reset() {
        this._chain = [];
        this._currentIndex = -1;
        this._insertIndex = 0;
    }

    add(event) {
        let entry = new Entry(event);

        this._chain.splice(this._insertIndex, 0, entry);
        this._insertIndex += 1;

        if (this._currentIndex === -1) {
            this._currentIndex = 0;
        }

        return event;
    }

    append(event) {
        this._chain.push(new Entry(event));

        if (this._currentIndex === -1) {
            this._currentIndex = 0;
            this._insertIndex = 1;
        }

        return event;
    }

    wait(duration) {
        let event = {
            _duration: duration,
            _elapsed: 0,
            onProgress(elapsed) {
                this._elapsed += elapsed;

                return this._elapsed - duration;
            }
        };

        return this.add(event);
    }

    isEmpty() {
        return this._chain.length === 0;
    }

    next(elapsed) {
        this._currentIndex += 1;

        return this.trigger(elapsed);
    }

    trigger(elapsed) {
        let entry = this._chain[this._currentIndex];

        if (!entry) {
            this._reset();

            return elapsed;
        }

        let event = entry.event;

        if (!entry.preTriggerDone) {
            entry.preTriggerDone = true;
            this._insertIndex = this._currentIndex;

            let preTriggerEvents = toArray(event.onPreTrigger?.());

            for (let event of preTriggerEvents) {
                this.add(event);
            }

            return this.trigger(elapsed);
        }

        if (event.canceled) {
            return this.next(elapsed);
        }

        if (!entry.triggerDone) {
            this._insertIndex = this._currentIndex + 1;

            if (!entry.started) {
                entry.started = true;
                event.onStart?.();
            }

            elapsed = event.onProgress?.(elapsed) ?? elapsed;

            if (elapsed < 0) {
                return -1;
            }

            entry.triggerDone = true;

            this._insertIndex = this._currentIndex;

            let childEvents = toArray(event.onEnd?.());

            for (let childEvent of childEvents) {
                this.add(childEvent);
            }

            return this.trigger(elapsed);
        }

        this._insertIndex = this._currentIndex + 1;

        let postTriggerEvents = toArray(event.onPostTrigger?.());

        for (let childEvent of postTriggerEvents) {
            this.add(childEvent);
        }
        
        return this.next(elapsed);
    }
}
globalThis.ALL_FUNCTIONS.push(Entry);
globalThis.ALL_FUNCTIONS.push(EventChain);