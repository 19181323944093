import { makeUpgradeCard } from '../../cards/make-upgrade-card';
import { KING_COLOR } from '../../constants/graphics-constants';
import { Minion } from '../../entities/minion';

class Soldier extends Minion {
    name = "Soldier"
    power = 2
}

export const KingUpgrade1Card = makeUpgradeCard({
    name: 'King upgrade 1',
    description: 'All your minion cards summon an additional 2-power soldier.',
    color: KING_COLOR,

    applyUpgrade(character) {
        for (let card of character.hand.cards()) {
            if (card.summonedMinion) {
                card.triggerEffect = function(output) {
                    output.summon(new Soldier());
                };
            }
        }
    }
})
globalThis.ALL_FUNCTIONS.push(Soldier);