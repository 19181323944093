import { Button } from './button';

const MESSAGE_KIND_TO_COLORS = {
    info: ['#00529B', '#BDE5F8'],
    success: ['#4F8A10', '#DFF2BF'],
    warning: ['#9F6000', '#FEEFB3'],
    error: ['#D8000C', '#FFD2D2'],
    other: ['black', 'white']
};

export class MessageBox {
    constructor({ constants }, { kind, content }) {
        this.kind = kind;
        this.content = content;
        this.acknowledged = false;
        this.okButton = new Button({
            text: 'Ok',
            style: constants.buttonStyle,
            onClick: () => this.acknowledged = true
        });
    }

    render({ view }) {
        let [textColor, backgroundColor] = MESSAGE_KIND_TO_COLORS[this.kind] || MESSAGE_KIND_TO_COLORS.other;

        view
            .scale(0.35)
            .setPointerBehavior('opaque')
            .layout()
            .backgroundColor(backgroundColor)
            .borderRadius(10)
            .borderColor(textColor)
            .borderWidth(2)
            .topToBottom()
            .push()
            .text(this.content)
            .textColor(textColor)
            .textSize('10%')
            .force(4)
            .push()
            .force(1)
            .centerToRight()
            .outerMargin('5%')
            .push(this.okButton)
            .aspectRatio(3)
    }

    onPostRender({ client }) {
        client.focus(this.okButton);

        return true;
    }
}
globalThis.ALL_FUNCTIONS.push(MessageBox);